import React, { FC } from 'react';
import { get } from 'lodash';
import { Card, CardContent, CardHead, Alert } from '../../../modules/ui-kit-react/lib';
import { makeButton } from 'components/DeleteButton';
import {
  ProjectGroup,
  GetProjectGroupDocument,
  UpdateProjectGroupDocument
} from 'generated/graphql';
import AddProjectGroupToGroupButton from '../AddProjectGroupToGroupButton';
import style from '../Form/style.module.css';

const RemoveGroupButton: any = makeButton(UpdateProjectGroupDocument);

interface ComponentProps {
  projectGroup: ProjectGroup;
}

const Component: FC<ComponentProps> = ({ projectGroup }) => {
  const groups = get(projectGroup, 'projectGroups') || [];

  return (
    <Card>
      <CardHead>Project Groups in This Group</CardHead>
      <CardContent>
        {groups.length === 0 && (
          <Alert
            type="warning"
            className={style.noProjects}
            message={
              <>
                There are no project groups in this group.
                <AddProjectGroupToGroupButton
                  small
                  raised
                  flat={false}
                  projectGroup={projectGroup}
                />
              </>
            }
          />
        )}

        <ul className={style.projects}>
          {groups.map(group => (
            <li key={group.id}>
              <div>{group.name}</div>
              <div>
                <RemoveGroupButton
                  buttonIcon="remove"
                  variables={{
                    id: group.id,
                    input: {
                      projectGroupId: null
                    }
                  }}
                  refetchQueries={[
                    {
                      query: GetProjectGroupDocument,
                      variables: { id: projectGroup.id }
                    }
                  ]}
                />
              </div>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default Component;
