import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { Card, CardHead } from '../../modules/ui-kit-react/lib';
import Window, { Pane } from './Window';
import DeployTool from './DeployTool';
import Error from './Error';
import LatestDeployment from './LatestDeployment';
import Actions from './Actions';
import Tooltip from '../Tooltip';
import ProviderForm from './ProviderForm';
import CompareTool from './CompareTool';

import style from './style.module.css';
import MoveButton from './MoveButton';
import Protected from '../Protected';

class EnvBox extends Component {
  static propTypes = {
    env: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string
    }).isRequired,
    isDragging: PropTypes.bool.isRequired,
    project: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    subscribeToMore: PropTypes.func.isRequired
  };

  state = {
    status: null,
    error: '',
    showProviderForm: false
  };

  static getDerivedStateFromProps(props) {
    const status = get(props, 'env.lastDeployment.status');

    return { status };
  }

  setError = error => this.setState({ error });

  hideProviderForm = () => this.setState({ showProviderForm: false });

  render() {
    const { env, isDragging, project, subscribeToMore } = this.props;
    const { status, error, showProviderForm } = this.state;

    return (
      <Card className={style.container}>
        <CardHead
          className={classnames(style.header, {
            [style.passing]: status === 'COMPLETED',
            [style.deploying]: ['PENDING', 'STARTED', 'CANCELING'].includes(
              status
            ),
            [style.canceling]: status === 'CANCELING',
            [style.canceled]: status === 'CANCELED',
            [style.failed]: ['FAILED', 'ERRORED'].includes(status)
          })}
        >
          <Protected hasRole="USER">
            <MoveButton isDragging={isDragging} />
          </Protected>
          <div className={style.name} data-cy="environment-summary-title">
            {env.name}
          </div>
          <Actions
            env={env}
            project={project}
            providerClick={() => this.setState({ showProviderForm: true })}
          />
        </CardHead>

        <Window>
          <Pane id="deploy-tool">
            <DeployTool
              project={project}
              environment={env}
              setError={this.setError}
            />
          </Pane>

          <Pane id="provider-config" active={showProviderForm}>
            <ProviderForm
              onCancel={this.hideProviderForm}
              environmentId={env.id}
              onSubmit={this.hideProviderForm}
            />
          </Pane>

          <Pane id="latest-deployment" active={!error}>
            <LatestDeployment
              project={project}
              environment={env}
              onStatusChange={newStatus => this.setState({ status: newStatus })}
              subscribeToMore={subscribeToMore}
              setError={this.setError}
            />
          </Pane>

          <Pane id="compare-tool">
            <CompareTool
              project={project}
              environment={env}
              setError={this.setError}
            />
          </Pane>

          <Pane id="error" active={!!error}>
            <Error
              message={error}
              clearError={() => this.setState({ error: '' })}
            />
          </Pane>
        </Window>
        <Tooltip disable={isDragging} />
      </Card>
    );
  }
}

export default withRouter(EnvBox);
