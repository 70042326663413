import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formField } from '../../modules/react-forms';
import UIKitTextInput from '../../modules/ui-kit-react/lib/components/Input';
import style from './style.module.css';

class TextInput extends Component {
  static propTypes = {
    setValue: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    validationErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    error: PropTypes.string.isRequired,
    isPristine: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    standAlone: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    theme: PropTypes.object
  };

  static defaultProps = {
    onChange: undefined,
    disabled: false,
    theme: {}
  };

  render() {
    const {
      setValue,
      isValid,
      validationErrors,
      error,
      isPristine,
      onChange,
      standAlone,
      disabled,
      theme: customTheme,
      ...rest
    } = this.props;
    let combinedErrors = [];

    if ((!isPristine && !isValid) || error) {
      combinedErrors = [error, ...validationErrors];
    }

    const theme = {
      ...customTheme,
      inputElement: disabled ? style.inputDisabled : customTheme?.disabled
    };

    return (
      <UIKitTextInput
        onChange={value => {
          setValue(value);
          if (onChange) onChange(value);
        }}
        error={!combinedErrors.length ? false : combinedErrors.join(' ')}
        disabled={disabled}
        theme={theme}
        {...rest}
      />
    );
  }
}

export default formField(TextInput);
