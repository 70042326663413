import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import { Button } from '../../modules/ui-kit-react/lib';
import { MutationLoading } from '../LoadingPage';
import Protected from '../Protected';
import {
  ARCHIVE_PROJECT,
  DELETE_PROJECT
} from '../../containers/Projects/queries';

class ProjectActions extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string
    }).isRequired,
    project: PropTypes.shape({
      environments: PropTypes.arrayOf(PropTypes.object),
      archivedEnvironments: PropTypes.arrayOf(PropTypes.object),
      id: PropTypes.string,
      name: PropTypes.string
    }).isRequired
  };

  shouldArchive = () => {
    const { project } = this.props;
    return project.environments.length || project.archivedEnvironments.length;
  };

  renderRemoveButton = remove => {
    const { project } = this.props;
    const value = this.shouldArchive() ? 'Archive' : 'Delete';
    const caption = this.shouldArchive() ? 'Archive' : 'Delete';
    const icon = this.shouldArchive() ? 'archive' : 'delete';

    return (
      <Button
        onClick={e => {
          e.preventDefault();

          swal({
            icon: 'error',
            title: `Are you sure you want to ${value.toLowerCase()} ${
              project.name
            }?`,
            dangerMode: true,
            buttons: true
          }).then(confirmed => {
            if (confirmed) {
              remove();
            }
          });
        }}
        flat
        appearance="danger"
        icon={icon}
      >
        {caption}
      </Button>
    );
  };

  render() {
    const { project, history, match } = this.props;
    const { id } = project;

    const query = this.shouldArchive() ? ARCHIVE_PROJECT : DELETE_PROJECT;

    return (
      <React.Fragment>
        <Protected hasRole="ADMIN">
          <Button
            flat
            appearance="primary"
            onClick={() => history.push(`${match.url}/environments/new`)}
            icon="add"
          >
            Create Environment
          </Button>
        </Protected>
        <Protected hasRole="USER">
          <Button
            flat
            appearance="primary"
            onClick={() => history.push(`./${id}/edit`)}
            icon="edit"
          >
            Edit
          </Button>
        </Protected>
        <Protected hasRole="ADMIN">
          <MutationLoading
            mutation={query}
            variables={{ id }}
            onCompleted={() => history.push('../projects')}
          >
            {remove => this.renderRemoveButton(remove)}
          </MutationLoading>
        </Protected>
      </React.Fragment>
    );
  }
}

export default withRouter(ProjectActions);
