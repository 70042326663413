import React, { FC } from 'react';
import { Alert } from '../../modules/ui-kit-react/lib';
import { capitalize } from 'lodash';

const TYPE_DOCS = {
  s3: 's3-configurations',
  lambda: 'basic-lambda-configurations'
} as const;

export type ProviderTypeShortname = keyof typeof TYPE_DOCS;

const docPageForType = (type: ProviderTypeShortname) =>
  `https://shield-docs.rvohealth.dev/docs/example-configs/${TYPE_DOCS[type]}.html`;

interface Props {
  type: ProviderTypeShortname;
}

const Component: FC<Props> = ({ type }: Props) => (
  <Alert
    message={
      <>
        <b>IMPORTANT PERMISSION NOTICE</b>
        <p>
          When adding a new {capitalize(type)} configuration, the Shield role
          needs additional permissions. Please follow the instructions
          provided&nbsp;
          <a
            href={docPageForType(type)}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
      </>
    }
    type="warning"
  />
);

export default Component;
