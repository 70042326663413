import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UPDATE_PROJECT } from 'containers/Projects/queries';
import { MutationLoading } from 'components/LoadingPage';
import TextInput from 'components/Form/TextInput';
import { Row, Col } from '../../modules/ui-kit-react/lib';
import { Form } from '../../modules/react-forms/lib';
import Button from '../../modules/ui-kit-react/lib/components/Button';
import style from './style.module.css';

class ProjectBadgeForm extends Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    project: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      repository: PropTypes.string,
      badges: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          imgSrc: PropTypes.string.isRequired
        }).isRequired
      )
    }).isRequired
  };

  render() {
    const { onSuccess, onCancel, project } = this.props;

    return (
      <MutationLoading
        mutation={UPDATE_PROJECT}
        onCompleted={() => onSuccess()}
      >
        {updateProject => (
          <Form
            onValidSubmit={badge =>
              updateProject({
                variables: {
                  id: project.id,
                  project: {
                    name: project.name,
                    description: project.description,
                    repository: project.repository,
                    badges: [
                      ...project.badges.map(({ __typename, ...rest }) => rest),
                      badge
                    ]
                  }
                }
              })
            }
          >
            <Row className={style.form}>
              <Col xs={3}>
                <TextInput
                  name="url"
                  type="text"
                  placeholder="Badge Url"
                  className={style.input}
                />
              </Col>
              <Col xs={3}>
                <TextInput
                  name="imgSrc"
                  type="text"
                  placeholder="Badge image source"
                  className={style.input}
                />
              </Col>
              <Col xs={6}>
                <div>
                  <Button raised small appearance="primary" type="submit">
                    Create
                  </Button>
                  <Button flat small appearance="danger" onClick={onCancel}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </MutationLoading>
    );
  }
}

export default ProjectBadgeForm;
