import React, { useState } from 'react';
import { Alert } from '../../../modules/ui-kit-react/lib';
import { useParams, Link } from 'react-router-dom';

import TextInput from 'components/Form/TextInput';
import MultiSelectInput from 'components/Form/MultiSelectInput';
import SelectInput from 'components/Form/SelectInput';
import { humanize } from 'utils/enums';
import extractFieldError from 'utils/gqlErrors';

import {
  useGetEcsClustersQuery,
  EcsCluster,
  EcsConfiguration
} from 'generated/graphql';

import ServiceSelect from './ServiceSelect';

interface GqlError {
  graphQLErrors: string[];
};

interface Props {
  gqlError?: GqlError;
  ecsConfiguration?: EcsConfiguration;
};

interface Params {
  tenant: string;
}

interface Option {
  label: string;
  value: string;
}

function fieldError(gqlError: GqlError | undefined, field: string): string {
  return extractFieldError(gqlError, field);
}

function getClusterOptions(clusters: EcsCluster[]): Option[] {
  const values = clusters.map(cluster => ({
    label: `${cluster.awsProfile?.name || ''} - ${cluster.name} (${humanize(
      cluster.region
    )})`,
    value: cluster.id
  }));

  values.sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();

    if (labelA < labelB) return -1;
    if (labelA > labelB) return 1;
    return 0;
  });

  return values;
}

function NoClusters() {
  const { tenant } = useParams<Params>();

  return (
    <Alert
      type="danger"
      message={(
        <span>
          We were unable to find any ECS clusters. In order to create an ECS
          deployment configuration, you will need to
          <Link to={`/${tenant}/admin/aws-profiles`}>
            sync the clusters from an AWS profile.
          </Link>
          <br />
          <br />
          You can read more about this process
          <a
            href="https://shield-docs.rvohealth.dev/docs/admin/profiles.html#syncing-ecs-clusters"
            target="_blank"
            rel="noreferrer noopener"
          >
            here
          </a>
          .
        </span>
      )}
    />
  );
}

export default function EcsFields(props: Props) {
  const { ecsConfiguration, gqlError } = props;
  const { loading, error, data } = useGetEcsClustersQuery();
  const [clusterId, setClusterId] = useState(ecsConfiguration?.cluster?.id);

  if (loading) return null;
  if (error) return <Alert message={error.message} type="danger" />;

  const ecsClusters = data?.ecsClusters || [];
  const noEcsClusters = !ecsClusters.length;

  return (
    <>
      {noEcsClusters && <NoClusters />}

      <TextInput
        name="providerConfiguration[__typename]"
        type="hidden"
        defaultValue="ecs"
        disabled={noEcsClusters}
      />

      <TextInput
        name="providerConfiguration[image]"
        label="image"
        defaultValue={ecsConfiguration?.image}
        error={fieldError(gqlError, 'image')}
        disabled={noEcsClusters}
        required
      />

      <SelectInput
        name="providerConfiguration[timeout]"
        label="Timeout (min)"
        defaultValue={ecsConfiguration?.timeout || 600}
        error={fieldError(gqlError, 'timeout')}
        source={[5, 10, 15, 30, 45, 60].map(minutes => ({
          value: minutes * 60,
          label: minutes.toString()
        }))}
        required
      />

      <MultiSelectInput
        name="providerConfiguration[ecsClusterId]"
        label="ECS Cluster"
        defaultValue={ecsConfiguration?.cluster?.id}
        options={getClusterOptions(ecsClusters)}
        isMulti={false}
        onValueChange={(value: string) => setClusterId(value || '')}
        required
      />

      <ServiceSelect
        clusterId={clusterId}
        clusterName={ecsClusters.find(({ id }) => id === clusterId)?.name || ''}
        service={ecsConfiguration?.service}
      />
    </>
  );
}
