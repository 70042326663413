import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import withAuth from "components/Auth";
import PropTypes from "prop-types";
import Gravatar from "react-gravatar";
import Chip from "../../modules/ui-kit-react/lib/components/Chip";
import {
  Menu,
  MenuItem,
  MenuDivider,
} from "../../modules/ui-kit-react/lib/components/Menu";
// import color from 'utils/color';
// import classnames from 'classnames';

import style from "./style.module.css";
import VersionTag from "../VersionTag";
import getUserRole from "../../utils/getUserRole";

class UserDropdown extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      tenant: PropTypes.string,
      token: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
      replace: PropTypes.func,
    }).isRequired,
    user: PropTypes.shape({
      displayName: PropTypes.string,
      roles: PropTypes.array,
    }).isRequired,
    logout: PropTypes.func.isRequired,
  };

  state = {
    menuActive: false,
  };

  getUserRole() {
    const {
      user: { roles },
    } = this.props;

    return getUserRole(roles);
  }

  render() {
    const { menuActive } = this.state;
    const { user, logout, history } = this.props;

    return (
      <div className={style.wrap}>
        <div
          className={style.profile}
          onClick={() => this.setState({ menuActive: !menuActive })}
          data-cy="user-drop-down"
        >
          {user.email ? (
            <div className={style.picture}>
              <Gravatar email={user.email} />
            </div>
          ) : null}
          <div className={style.username}>
            <span>{user.displayName}</span>
            <br />
            {user.currentTenant ? (
              <span className={style.currentTenant}>{user.currentTenant}</span>
            ) : null}
          </div>
          {user.currentTenant ? (
            <Chip className={style.userRole}>{this.getUserRole()}</Chip>
          ) : null}
          <i className="material-icons">expand_more</i>
        </div>
        <Menu
          active={menuActive}
          position="topRight"
          className={style.menu}
          onHide={() => this.setState({ menuActive: false })}
        >
          <MenuItem
            caption={`${user.currentTenant ? "Switch" : "Select"} Tenant`}
            className={style.menuItem}
            onClick={() => history.push("/select-tenant")}
          />
          <MenuItem
            className={style.menuItem}
            caption="Logout"
            onClick={logout}
          />
          <MenuDivider />
          <MenuItem disabled className={style.menuItem}>
            <VersionTag />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withRouter(withAuth(UserDropdown));
