"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _validator = _interopRequireDefault(require("validator"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var validatorFuncs = {};
Object.keys(_validator.default).forEach(function (value) {
  if (typeof _validator.default[value] === 'function') {
    validatorFuncs[value] = function () {
      for (var _len = arguments.length, opts = new Array(_len), _key = 0; _key < _len; _key++) {
        opts[_key] = arguments[_key];
      }

      return function (string) {
        return _validator.default[value].apply(_validator.default, [string].concat(opts));
      };
    };
  }
});
var _default = validatorFuncs;
exports.default = _default;