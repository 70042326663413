import React, { Component } from 'react';
import { get, findIndex, debounce, startCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import style from 'components/EnvBox/style.module.css';
import GitRef from 'components/GitRef';
import {
  DEPLOYMENT_UPDATE_SUBSCRIPTION,
  NEW_DEPLOYMENT_SUBSCRIPTION
} from 'containers/Environments/queries';
import notify from 'utils/notification';
import ArtifactName from 'components/ArtifactName';
import { Alert, Button, Col, Row } from '../../modules/ui-kit-react/lib';
import Protected from '../Protected';

const findEnvIndex = (environments, id) =>
  findIndex(environments, env => env.id === id);

const debouncedNotify = debounce(notify);

const newDeployment = environmentId => ({
  document: NEW_DEPLOYMENT_SUBSCRIPTION,
  variables: { environmentId },
  updateQuery: (prev, { subscriptionData }) => {
    if (!subscriptionData.data) return prev;

    const deploymentFromSub = subscriptionData.data.newDeployments;

    debouncedNotify(
      `Deploying ${prev.project.name}`,
      `${deploymentFromSub.environment.name} - ${deploymentFromSub.artifactName}`
    );

    const envIndex = findEnvIndex(prev.project.environments, environmentId);

    const newEnvs = prev.project.environments;

    newEnvs[envIndex].lastDeployment = deploymentFromSub;

    return {
      project: {
        ...prev.project,
        environments: newEnvs
      }
    };
  }
});

const deploymentUpdate = (environmentId, id) => ({
  document: DEPLOYMENT_UPDATE_SUBSCRIPTION,
  variables: { id },
  updateQuery: (prev, { subscriptionData }) => {
    if (!subscriptionData.data) return prev;

    const deploymentFromSub = subscriptionData.data.updateDeployment;

    let icon;

    switch (deploymentFromSub.status) {
      case 'COMPLETED':
        icon = '\u2705';
        break;
      case 'STARTED':
      case 'PENDING':
        icon = '\ud83d\ude80';
        break;
      case 'ERRORED':
      case 'FAILED':
        icon = '\u274C';
        break;
      case 'CANCELING':
      case 'CANCELED':
        icon = '\ud83d\udeab';
        break;
      default:
        icon = '';
    }

    debouncedNotify(
      `${icon} Deploying ${prev.project.name}`,
      `${deploymentFromSub.environment.name} - ${deploymentFromSub.status}`
    );

    const envIndex = findEnvIndex(prev.project.environments, environmentId);

    const newEnvs = prev.project.environments;

    newEnvs[envIndex].lastDeployment = {
      ...prev.project.environments[envIndex].lastDeployment,
      ...deploymentFromSub
    };

    return {
      project: {
        ...prev.project,
        environments: newEnvs
      }
    };
  }
});

export default class EnvLatestDeploymentDetails extends Component {
  static propTypes = {
    onStatusChange: PropTypes.func,
    project: PropTypes.shape({
      repository: PropTypes.string
    }).isRequired,
    environment: PropTypes.shape({
      id: PropTypes.string.isRequired,
      lastDeployment: PropTypes.shape({
        id: PropTypes.string,
        artifactName: PropTypes.string,
        autoPromoted: PropTypes.bool,
        deployer: PropTypes.shape({
          displayName: PropTypes.string
        }),
        ref: PropTypes.string,
        insertedAt: PropTypes.string
      })
    }).isRequired,
    changePane: PropTypes.func.isRequired,
    subscribeToMore: PropTypes.func.isRequired
  };

  static defaultProps = {
    onStatusChange: () => {
      // do nothing
    }
  };

  /**
   * Keeps deployment status synced up
   */
  componentDidMount = () => {
    const { onStatusChange } = this.props;
    const status = get(
      this.props,
      'data.environment.lastDeployment.status',
      null
    );

    onStatusChange(status);
  };

  noDeployments = () => (
    <React.Fragment>
      {this.renderProviderConfigMissing()}

      <div className={classnames(style.content, style.noDeployments)}>
        <div>No Deployment History</div>
      </div>
    </React.Fragment>
  );

  renderProviderConfigMissing = () => {
    const { changePane } = this.props;
    const configExists = get(this.props, 'environment.providerConfiguration');

    if (configExists) {
      return null;
    }

    return (
      <Alert
        className={style.providerMissing}
        message={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <React.Fragment>
            Deployment configuration is missing.
            <Protected hasRole="ADMIN">
              <Button
                appearance="primary"
                small
                onClick={() => changePane('provider-config')}
              >
                Setup
              </Button>
            </Protected>
          </React.Fragment>
        }
        type="warning"
      />
    );
  };

  render() {
    const { environment, project, subscribeToMore, changePane } = this.props;
    const { id: environmentId, lastDeployment } = environment;

    subscribeToMore(newDeployment(environmentId));
    if (!lastDeployment) return this.noDeployments(environmentId);

    subscribeToMore(deploymentUpdate(environmentId, lastDeployment.id));

    const status = startCase(lastDeployment.status.toLowerCase());

    return (
      <React.Fragment>
        <Protected hasRole="ADMIN">
          {this.renderProviderConfigMissing()}
        </Protected>

        <Row className={style.envDetails}>
          <Col sm={6}>
            <label>Ref</label>
            <GitRef repoRef={lastDeployment.ref} repo={project.repository} />
          </Col>
          <Col sm={6}>
            <label>Time</label>
            <time
              dateTime={lastDeployment.insertedAt}
              title={moment(lastDeployment.insertedAt).format(
                'MMMM Do YYYY, h:mm a'
              )}
            >
              {moment(lastDeployment.insertedAt).fromNow()}
            </time>
          </Col>
          <Col sm={6}>
            <label>Deployed By</label>
            <div>{lastDeployment.deployer.displayName}</div>
          </Col>
          <Col sm={6}>
            <label>Artifact Name</label>

            <div className={style.artifactNameWrapper}>
              <ArtifactName artifactName={lastDeployment.artifactName} />

              <button type="button" onClick={() => changePane('compare-tool')}>
                Compare
                <i className="material-icons">compare_arrows</i>
              </button>
            </div>
          </Col>
          <Col sm={6}>
            <label>Auto Promoted</label>
            {lastDeployment.autoPromoted ? 'Yes' : 'No'}
          </Col>
          <Col sm={6}>
            <label>Status</label>
            {status}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
