import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup as RadioGroupKit } from '../../modules/ui-kit-react/lib/components/Radio';
import { formField } from '../../modules/react-forms';

class RadioGroup extends Component {
  static propTypes = {
    setValue: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
  };

  render() {
    const { setValue, children, ...rest } = this.props;

    return (
      <RadioGroupKit onChange={setValue} {...rest}>
        {children}
      </RadioGroupKit>
    );
  }
}

export default formField(RadioGroup);

export { RadioButton } from '../../modules/ui-kit-react/lib/components/Radio';
