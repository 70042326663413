import React, { Component } from 'react';
import Container from 'components/Container';
import ProjectForm from 'components/ProjectForm';
import Title from 'components/Title';
import { MutationLoading, QueryLoading } from 'components/LoadingPage';
import { GET_PROJECT, UPDATE_PROJECT } from 'containers/Projects/queries';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Alert from '../../../modules/ui-kit-react/lib/components/Alert';
import {
  Breadcrumbs,
  Crumb,
  Card,
  CardHead,
  CardContent,
  Button
} from '../../../modules/ui-kit-react/lib';
import { Row, Col } from '../../../modules/ui-kit-react/lib/components/Grid';
import style from './style.module.css';
import References from '../../../components/References';
import { NotFoundError, GraphQLError } from '../../../errors';
import ArchivedEnvironments from '../../../components/ArchivedEnvironments';

class ProjectsEdit extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    }).isRequired
  };

  renderMutation = project => (updateProject, { loading, error }) => (
    <React.Fragment>
      {error ? <Alert type="danger" message={error.message} /> : null}
      <ProjectForm
        gqlError={error}
        edit
        project={project}
        loading={loading}
        onSubmit={form =>
          updateProject({
            variables: {
              id: project.id,
              project: {
                name: form.name,
                description: form.description,
                repository: form.repository
              }
            }
          })
        }
      />
    </React.Fragment>
  );

  render() {
    const { match, history } = this.props;

    return (
      <QueryLoading query={GET_PROJECT} variables={{ id: match.params.id }}>
        {({ error, data }) => {
          if (error) throw new GraphQLError(error);

          const { project } = data;

          if (!project) throw new NotFoundError();

          return (
            <Container>
              <Title title={`Edit Project "${project.name}"`} />

              <CardHead className={style.header}>
                <Breadcrumbs>
                  <Crumb>
                    <Link to="../">Projects</Link>
                  </Crumb>

                  <Crumb>
                    <Link to={`../${project.id}`}>{project.name}</Link>
                  </Crumb>

                  <Crumb active>Edit</Crumb>
                </Breadcrumbs>
                <div>
                  <Button
                    appearance="primary"
                    flat
                    icon="arrow_back"
                    onClick={() => history.push('./')}
                  >
                    Back to Project
                  </Button>
                </div>
              </CardHead>

              <CardContent>
                <Row>
                  <Col md={6} xs={12}>
                    <Card>
                      <CardHead>Project Details</CardHead>

                      <CardContent>
                        <MutationLoading
                          mutation={UPDATE_PROJECT}
                          refetchQueries={[
                            {
                              query: GET_PROJECT,
                              variables: { id: project.id }
                            }
                          ]}
                          onCompleted={() => history.push(`../${project.id}`)}
                        >
                          {this.renderMutation(project)}
                        </MutationLoading>
                      </CardContent>
                    </Card>
                  </Col>

                  <Col md={6} xs={12}>
                    <Card>
                      <CardHead>Project References</CardHead>

                      <CardContent>
                        <References project={project} />
                      </CardContent>
                    </Card>

                    <Card>
                      <CardHead>Archived Environments</CardHead>

                      <CardContent>
                        <ArchivedEnvironments projectId={project.id} />
                      </CardContent>
                    </Card>
                  </Col>
                </Row>
              </CardContent>
            </Container>
          );
        }}
      </QueryLoading>
    );
  }
}

export default ProjectsEdit;
