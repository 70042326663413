import React, { FC, useState } from 'react';
import { Button } from '../../../modules/ui-kit-react/lib';
import { ButtonProps } from '../../../modules/ui-kit-react/lib/components/Button';
import { ProjectGroup } from 'generated/graphql';
import Modal from '../AddProjectGroupToGroup';

interface ComponentProps {
  projectGroup: Pick<ProjectGroup, 'id' | 'name'>;
}

const Component: FC<ComponentProps & Partial<ButtonProps>> = ({
  projectGroup,
  ...buttonProps
}) => {
  const [showModal, setshowModal] = useState(false);

  return (
    <>
      <Button
        flat
        appearance="primary"
        icon="add"
        onClick={() => setshowModal(true)}
        {...buttonProps}
      >
        Add Project Group
      </Button>

      <Modal
        active={showModal}
        projectGroup={projectGroup}
        onClose={() => setshowModal(false)}
      />
    </>
  );
};

export default Component;
