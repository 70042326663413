"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bulletsWithshowMore = exports.bullets = void 0;

var _react = _interopRequireDefault(require("react"));

var _uniqueId = _interopRequireDefault(require("lodash/uniqueId"));

var _classnames = _interopRequireDefault(require("classnames"));

var _chevronUp = _interopRequireDefault(require("./assets/chevronUp.svg"));

var _chevronDown = _interopRequireDefault(require("./assets/chevronDown.svg"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var showList = {
  display: 'list-item'
};
var hide = {
  display: 'none'
};
var show = {
  display: 'block'
};

var showTerms = function showTerms(hasTerms, isExpanded, theme) {
  return hasTerms ? _react["default"].createElement("small", {
    className: theme.bulletsShowTerms,
    style: isExpanded ? hide : show
  }, "Terms and Restrictions Apply") : null;
};

var showLink = function showLink(isExpanded, toggleExpand, hasTerms, theme) {
  return _react["default"].createElement("div", {
    className: theme.bulletsShowButtonContainer
  }, _react["default"].createElement("span", {
    className: theme.bulletsShowButton,
    onClick: function onClick() {
      toggleExpand(isExpanded);
    }
  }, isExpanded ? 'Show Less ' : 'Show More ', "\xA0", _react["default"].createElement("img", {
    src: isExpanded ? _chevronUp["default"] : _chevronDown["default"]
  })), showTerms(hasTerms, isExpanded, theme));
};

var bullets = function bullets() {
  var bullets = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var theme = arguments.length > 1 ? arguments[1] : undefined;
  return _react["default"].createElement("div", {
    className: theme.bullets
  }, _react["default"].createElement("ul", null, bullets.map(function (bullet, index) {
    return _react["default"].createElement("li", {
      key: (0, _uniqueId["default"])('bullet'),
      dangerouslySetInnerHTML: {
        __html: bullet
      }
    });
  })));
};

exports.bullets = bullets;

var bulletsWithshowMore = function bulletsWithshowMore() {
  var bullets = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var isExpanded = arguments.length > 1 ? arguments[1] : undefined;
  var toggleExpand = arguments.length > 2 ? arguments[2] : undefined;
  var hasTerms = arguments.length > 3 ? arguments[3] : undefined;
  var theme = arguments.length > 4 ? arguments[4] : undefined;
  return _react["default"].createElement("div", {
    className: (0, _classnames["default"])(theme.bullets, theme.bulletsNoToggle)
  }, _react["default"].createElement("ul", null, bullets.map(function (bullet, index) {
    return _react["default"].createElement("li", {
      key: (0, _uniqueId["default"])('bullet'),
      style: index < 2 || isExpanded ? showList : hide,
      dangerouslySetInnerHTML: {
        __html: bullet
      }
    });
  })), bullets.length > 2 ? showLink(isExpanded, toggleExpand, hasTerms, theme) : '');
};

exports.bulletsWithshowMore = bulletsWithshowMore;