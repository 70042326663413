import React, { Component } from 'react';
import Button from '../../modules/ui-kit-react/lib/components/Button';
import { Form, Validators } from '../../modules/react-forms/lib';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import TextInput from 'components/Form/TextInput';
import extractFieldError from 'utils/gqlErrors';
import style from './style.module.css';

class ReferenceForm extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    reference: PropTypes.shape({
      name: PropTypes.string
    }),
    edit: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    gqlError: PropTypes.shape({
      graphQLErrors: PropTypes.array
    })
  };

  static defaultProps = {
    reference: {},
    edit: false,
    gqlError: null
  };

  getError = field => {
    const { gqlError } = this.props;
    return extractFieldError(gqlError, field);
  };

  render() {
    const { onSubmit, onCancel, reference, edit } = this.props;
    return (
      <Form onValidSubmit={onSubmit}>
        <TextInput
          name="name"
          type="text"
          placeholder="Name"
          className={style.input}
          defaultValue={get(reference, 'name')}
          error={this.getError('name')}
        />
        <TextInput
          name="url"
          type="text"
          placeholder="URL"
          className={style.input}
          defaultValue={get(reference, 'url')}
          validation={{
            'Must provide protocol: http or https': Validators.isURL({
              require_protocol: true,
              protocols: ['http', 'https']
            })
          }}
          error={this.getError('url')}
        />
        <div className={style.actions}>
          <Button raised small appearance="primary" type="submit">
            {edit ? 'Update' : 'Create'}
          </Button>
          <Button flat small appearance="danger" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    );
  }
}

export default ReferenceForm;
