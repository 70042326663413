"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applyTheme = exports.ThemeProvider = void 0;

var _react = _interopRequireWildcard(require("react"));

var _theme = _interopRequireDefault(require("./themes/creditcards_internal/theme"));

var _lodash = require("lodash");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var ThemeContext = (0, _react.createContext)(_theme["default"]);

var ThemeProvider = function ThemeProvider(_ref) {
  var theme = _ref.theme,
      children = _ref.children;
  return _react["default"].createElement(ThemeContext.Provider, {
    value: theme
  }, children);
};

exports.ThemeProvider = ThemeProvider;

// Oh yes, this is happening
var mergeTheme = function mergeTheme(theme, overrides) {
  if (!overrides) {
    return theme;
  }

  var mergedTheme = (0, _lodash.cloneDeep)(theme);
  Object.entries(overrides).forEach(function (_ref2) {
    var _ref3 = _slicedToArray(_ref2, 2),
        k = _ref3[0],
        v = _ref3[1];

    if (!mergedTheme[k] || mergedTheme[k].includes(v)) {
      return;
    }

    mergedTheme[k] = "".concat(mergedTheme[k], " ").concat(v);
  });
  return mergedTheme;
};

var applyTheme = function applyTheme(scope, Component) {
  var name = Component.displayName || Component.name || 'Component';

  var WrappedComponent = function WrappedComponent(props) {
    return _react["default"].createElement(ThemeContext.Consumer, null, function (theme) {
      var mergedTheme = mergeTheme(theme[scope], props.theme);
      return _react["default"].createElement(Component, _extends({}, props, {
        theme: mergedTheme
      }));
    });
  };

  WrappedComponent.displayName = "applyTheme(".concat(name, ")");
  return WrappedComponent;
};

exports.applyTheme = applyTheme;