import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: string;
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: string;
};

/** Represents a single audit log entry */
export type AuditLog = {
  __typename?: 'AuditLog';
  action?: Maybe<Scalars['String']>;
  changes: Array<Scalars['String']>;
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  snapshot?: Maybe<Scalars['String']>;
  user: User;
};

/** Represents an AWS Profile */
export type AwsProfile = {
  __typename?: 'AwsProfile';
  ecsClusters?: Maybe<Array<EcsCluster>>;
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  roleArn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Supported AWS Regions */
export enum AwsRegion {
  ApSoutheast_2 = 'AP_SOUTHEAST_2',
  CaCentral_1 = 'CA_CENTRAL_1',
  EuCentral_1 = 'EU_CENTRAL_1',
  EuWest_1 = 'EU_WEST_1',
  EuWest_2 = 'EU_WEST_2',
  SaEast_1 = 'SA_EAST_1',
  UsEast_1 = 'US_EAST_1',
  UsEast_2 = 'US_EAST_2',
  UsWest_1 = 'US_WEST_1',
  UsWest_2 = 'US_WEST_2'
}

/** Represents a project badge */
export type Badge = {
  __typename?: 'Badge';
  imgSrc?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Represents a configuration for Basic Lambda deployment */
export type BasicLambdaConfiguration = {
  __typename?: 'BasicLambdaConfiguration';
  artifactBucket?: Maybe<Scalars['String']>;
  awsProfile?: Maybe<AwsProfile>;
  functionName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  insertedAt?: Maybe<Scalars['DateTime']>;
  region?: Maybe<AwsRegion>;
  type?: Maybe<LambdaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Represents a configuration for CodeBuild deployment */
export type CodebuildConfiguration = {
  __typename?: 'CodebuildConfiguration';
  awsProfile?: Maybe<AwsProfile>;
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  projectName?: Maybe<Scalars['String']>;
  region?: Maybe<AwsRegion>;
  specifySourceVersion?: Maybe<Scalars['Boolean']>;
  timeout?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Attributes for creating an AWS Profile */
export type CreateAwsProfileInput = {
  name: Scalars['String'];
  roleArn: Scalars['String'];
};

/** Attributes for creating a project badge */
export type CreateBadgeInput = {
  imgSrc?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Attributes for creating a Basic Lambda Configuration */
export type CreateBasicLambdaConfigurationInput = {
  artifactBucket?: Maybe<Scalars['String']>;
  awsProfileId: Scalars['ID'];
  functionName: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  region: AwsRegion;
  type: LambdaType;
};

/** Attributes for creating a CodeBuild configuration */
export type CreateCodebuildConfigurationInput = {
  awsProfileId: Scalars['ID'];
  projectName: Scalars['String'];
  region: AwsRegion;
  specifySourceVersion?: Maybe<Scalars['Boolean']>;
  timeout?: Maybe<Scalars['Int']>;
};

/** Attributes for creating a deployment */
export type CreateDeploymentInput = {
  artifactName?: Maybe<Scalars['String']>;
  environmentId: Scalars['ID'];
  ref: Scalars['String'];
  tag?: Maybe<Scalars['String']>;
};

/** Attributes for creating an ECS Cluster */
export type CreateEcsClusterInput = {
  awsProfileId: Scalars['ID'];
  name: Scalars['String'];
  region: AwsRegion;
};

/** Attributes for creating an ECS Configuration */
export type CreateEcsConfigurationInput = {
  ecsClusterId: Scalars['ID'];
  image: Scalars['String'];
  service: Scalars['String'];
  timeout: Scalars['Int'];
};

/** Attributes for creating an ECS Scheduled Task Configuration */
export type CreateEcsScheduledTaskConfigurationInput = {
  awsProfileId: Scalars['ID'];
  image: Scalars['String'];
  region: AwsRegion;
  taskFamily: Scalars['String'];
};

/** Attributes for creating an environment */
export type CreateEnvironmentInput = {
  autoPromote?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  privileged?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['ID'];
  tagPattern?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


/** Attributes for creating a project group */
export type CreateProjectGroupInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  projectGroupId?: Maybe<Scalars['ID']>;
};

/** Attributes for creating a project */
export type CreateProjectInput = {
  badges?: Maybe<Array<CreateBadgeInput>>;
  description: Scalars['String'];
  name: Scalars['String'];
  repository: Scalars['String'];
};

/** Attributes for creating a reference */
export type CreateReferenceInput = {
  name: Scalars['String'];
  projectId: Scalars['ID'];
  url: Scalars['String'];
};

/** Attributes for creating a release note */
export type CreateReleaseNoteInput = {
  markdownContent: Scalars['String'];
  title: Scalars['String'];
};

/** Attributes for creating an S3 Configuration */
export type CreateS3ConfigurationInput = {
  artifactBucket: Scalars['String'];
  awsProfileId: Scalars['ID'];
  deleteOldFiles?: Maybe<Scalars['Boolean']>;
  deployBucket: Scalars['String'];
  distributionId?: Maybe<Scalars['String']>;
  region: AwsRegion;
};

/** Attributes for adding a service user to a given tenant */
export type CreateServiceUserInput = {
  clientId: Scalars['String'];
  roles?: Maybe<Array<UserRoleValue>>;
};

/** Attributes for creating a slack alert */
export type CreateSlackAlertInput = {
  channel: Scalars['String'];
  environmentId: Scalars['ID'];
  on: Array<DeploymentStatus>;
};

/** Attributes for creating a slack reminder */
export type CreateSlackReminderInput = {
  channel: Scalars['String'];
  environmentId: Scalars['ID'];
};

/** Payload returned from the createSlackReminder mutation */
export type CreateSlackReminderPayload = {
  __typename?: 'CreateSlackReminderPayload';
  slackReminder: SlackReminder;
};

/** Attributes for creating a user role */
export type CreateUserRoleInput = {
  roles?: Maybe<Array<UserRoleValue>>;
  userId: Scalars['ID'];
};

/** Payload returned from the createUserRole mutation */
export type CreateUserRolePayload = {
  __typename?: 'CreateUserRolePayload';
  userRole: UserRole;
};

/** Attributes for creating a web hook notification */
export type CreateWebHookInput = {
  awsProfileId?: Maybe<Scalars['ID']>;
  environmentId: Scalars['ID'];
  on: Array<DeploymentStatus>;
  url: Scalars['String'];
};

/** Payload returned from the createWebHook mutation */
export type CreateWebHookPayload = {
  __typename?: 'CreateWebHookPayload';
  signingKey: Scalars['String'];
  webHook: WebHook;
};



/** Payload returned from the deleteSlackReminder mutation */
export type DeleteSlackReminderPayload = {
  __typename?: 'DeleteSlackReminderPayload';
  slackReminder: SlackReminder;
};

/** Payload returned from the deleteUserRole mutation */
export type DeleteUserRolePayload = {
  __typename?: 'DeleteUserRolePayload';
  userRole: UserRole;
};

/** Payload returned from the deleteWebHook mutation */
export type DeleteWebHookPayload = {
  __typename?: 'DeleteWebHookPayload';
  webHook: WebHook;
};

/** Represents an environment deployment */
export type Deployment = {
  __typename?: 'Deployment';
  artifactName: Scalars['String'];
  autoPromoted: Scalars['Boolean'];
  deployer?: Maybe<User>;
  environment?: Maybe<Environment>;
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  logEntries?: Maybe<Array<DeploymentLogEntry>>;
  ref?: Maybe<Scalars['String']>;
  status?: Maybe<DeploymentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Represents a log entry in a deployment */
export type DeploymentLogEntry = {
  __typename?: 'DeploymentLogEntry';
  level: Scalars['String'];
  message: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

/** Possible deployment status types */
export enum DeploymentStatus {
  Canceled = 'CANCELED',
  Canceling = 'CANCELING',
  Completed = 'COMPLETED',
  Errored = 'ERRORED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Started = 'STARTED'
}

/** Represents an ECS cluster that can be used for ECS deployment */
export type EcsCluster = {
  __typename?: 'EcsCluster';
  awsProfile?: Maybe<AwsProfile>;
  configurations?: Maybe<Array<EcsConfiguration>>;
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<AwsRegion>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Represents a configuration for ECS deployment */
export type EcsConfiguration = {
  __typename?: 'EcsConfiguration';
  cluster?: Maybe<EcsCluster>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  insertedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<Scalars['String']>;
  serviceDetails?: Maybe<EcsServiceDetails>;
  taskDefinitionDetails?: Maybe<EcsTaskDefinitionDetails>;
  timeout: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Attributes for an AWS ECS Event log entry */
export type EcsEventLogEntry = {
  __typename?: 'EcsEventLogEntry';
  id?: Maybe<Scalars['ID']>;
  insertedAt?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
};

/** Represents a configuration for ECS Scheduled Task deployment */
export type EcsScheduledTaskConfiguration = {
  __typename?: 'EcsScheduledTaskConfiguration';
  awsProfile?: Maybe<AwsProfile>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  insertedAt?: Maybe<Scalars['DateTime']>;
  region?: Maybe<AwsRegion>;
  taskFamily?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Represents the backend ECS service for an ECS Configuration */
export type EcsServiceDetails = {
  __typename?: 'EcsServiceDetails';
  desired?: Maybe<Scalars['Int']>;
  events?: Maybe<Array<EcsEventLogEntry>>;
  running?: Maybe<Scalars['Int']>;
};

/** Represents the backend ECS task definition for an ECS Configuration */
export type EcsTaskDefinitionDetails = {
  __typename?: 'EcsTaskDefinitionDetails';
  environment?: Maybe<Array<EnvironmentVariable>>;
};

/** Represents a project environment */
export type Environment = {
  __typename?: 'Environment';
  archived?: Maybe<Scalars['Boolean']>;
  autoPromote?: Maybe<Scalars['Boolean']>;
  deployments?: Maybe<PaginatedDeployments>;
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  lastDeployment?: Maybe<Deployment>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  privileged?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Project>;
  providerConfiguration?: Maybe<ProviderConfiguration>;
  slackAlerts?: Maybe<Array<SlackAlert>>;
  slackReminders?: Maybe<Array<SlackReminder>>;
  slug?: Maybe<Scalars['String']>;
  tagPattern?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  webHooks?: Maybe<Array<WebHook>>;
};


/** Represents a project environment */
export type EnvironmentDeploymentsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  status?: Maybe<DeploymentStatus>;
};


/** Represents a project environment */
export type EnvironmentLastDeploymentArgs = {
  status?: Maybe<DeploymentStatus>;
};

/** Attributes for environment variables */
export type EnvironmentVariable = {
  __typename?: 'EnvironmentVariable';
  key: Scalars['String'];
  sensitive: Scalars['Boolean'];
  value?: Maybe<Scalars['String']>;
};

/** Possible lambda type values */
export enum LambdaType {
  Container = 'CONTAINER',
  Zip = 'ZIP'
}

/** Represents a paginated list of audit logs */
export type PaginatedAuditLogs = {
  __typename?: 'PaginatedAuditLogs';
  entries: Array<AuditLog>;
  pagination?: Maybe<PaginationData>;
};

/** Represents a paginated list of deployments */
export type PaginatedDeployments = {
  __typename?: 'PaginatedDeployments';
  entries?: Maybe<Array<Deployment>>;
  pagination?: Maybe<PaginationData>;
};

/** Represents a paginated response of project groups */
export type PaginatedProjectGroups = {
  __typename?: 'PaginatedProjectGroups';
  entries?: Maybe<Array<ProjectGroup>>;
  pagination?: Maybe<PaginationData>;
};

/** Represents a paginated response of projects */
export type PaginatedProjects = {
  __typename?: 'PaginatedProjects';
  entries?: Maybe<Array<Project>>;
  pagination?: Maybe<PaginationData>;
};

/** Represents pagination meta-data */
export type PaginationData = {
  __typename?: 'PaginationData';
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntries?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

/** Represents a project */
export type Project = {
  __typename?: 'Project';
  archived?: Maybe<Scalars['Boolean']>;
  badges?: Maybe<Array<Badge>>;
  description?: Maybe<Scalars['String']>;
  environments?: Maybe<Array<Environment>>;
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  projectGroups?: Maybe<Array<Maybe<ProjectGroup>>>;
  pulse?: Maybe<Array<PulseMetric>>;
  references?: Maybe<Array<Reference>>;
  repository?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Tag>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** Represents a project */
export type ProjectEnvironmentsArgs = {
  includeArchived?: Maybe<Scalars['Boolean']>;
  onlyArchived?: Maybe<Scalars['Boolean']>;
};


/** Represents a project */
export type ProjectTagsArgs = {
  environmentId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
};

/** Represents a project group */
export type ProjectGroup = {
  __typename?: 'ProjectGroup';
  description: Scalars['String'];
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  parentProjectGroup?: Maybe<ProjectGroup>;
  projectGroups?: Maybe<Array<ProjectGroup>>;
  projects?: Maybe<Array<Maybe<Project>>>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Provider configuration types */
export type ProviderConfiguration = BasicLambdaConfiguration | CodebuildConfiguration | EcsConfiguration | EcsScheduledTaskConfiguration | S3Configuration;

/** Represents a single metric in a pulse graph. */
export type PulseMetric = {
  __typename?: 'PulseMetric';
  date?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['Int']>;
};

/** Represents a project reference */
export type Reference = {
  __typename?: 'Reference';
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

/** Represents a release note */
export type ReleaseNote = {
  __typename?: 'ReleaseNote';
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  markdownContent: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Adds a project to a project group */
  addProjectToProjectGroup?: Maybe<ProjectGroup>;
  /** Archives an environment */
  archiveEnvironment?: Maybe<Environment>;
  /** Archives a project */
  archiveProject?: Maybe<Project>;
  /** Archives a user hiding them in all listing queries by default */
  archiveUser?: Maybe<User>;
  /** Associate a users slack account to user account */
  associateSlackAccount?: Maybe<User>;
  /** Cancel a deployment */
  cancelDeployment?: Maybe<Scalars['Boolean']>;
  /** Create an AWS Profile */
  createAwsProfile?: Maybe<AwsProfile>;
  /** Creates a Basic Lambda Configuration */
  createBasicLambdaConfiguration?: Maybe<BasicLambdaConfiguration>;
  /** Creates a CodeBuild Configuration */
  createCodebuildConfiguration?: Maybe<CodebuildConfiguration>;
  /** Create a new deployment for an environment */
  createDeployment?: Maybe<Deployment>;
  /** Create an ECS Cluster */
  createEcsCluster?: Maybe<EcsCluster>;
  /** Create an ECS Configuration */
  createEcsConfiguration?: Maybe<EcsConfiguration>;
  /** Creates an Ecs Scheduled Task Configuration */
  createEcsScheduledTaskConfiguration?: Maybe<EcsScheduledTaskConfiguration>;
  /** Create a new environment */
  createEnvironment?: Maybe<Environment>;
  /** Create a new token */
  createHookToken?: Maybe<Token>;
  /** Create a new project */
  createProject?: Maybe<Project>;
  /** Creates a project group */
  createProjectGroup?: Maybe<ProjectGroup>;
  /** Create a new reference */
  createReference?: Maybe<Reference>;
  /** Creates a release note */
  createReleaseNote?: Maybe<ReleaseNote>;
  /** Creates an S3 Configuration */
  createS3Configuration?: Maybe<S3Configuration>;
  /** Creates a service user */
  createServiceUser?: Maybe<CreateUserRolePayload>;
  /** Creates a slack alert */
  createSlackAlert?: Maybe<SlackAlert>;
  /** Create a slack reminder */
  createSlackReminder?: Maybe<CreateSlackReminderPayload>;
  /** Creates a user role */
  createUserRole?: Maybe<CreateUserRolePayload>;
  /** Creates a webhook */
  createWebHook?: Maybe<CreateWebHookPayload>;
  /** Deletes an ECS Profile */
  deleteAwsProfile?: Maybe<Scalars['Boolean']>;
  /** Deletes an ECS Cluster */
  deleteEcsCluster?: Maybe<Scalars['Boolean']>;
  /** Deletes an environment */
  deleteEnvironment?: Maybe<Scalars['Boolean']>;
  /** Deletes a token by id */
  deleteHookToken?: Maybe<Scalars['Boolean']>;
  /** Deletes a project */
  deleteProject?: Maybe<Scalars['Boolean']>;
  /** Deletes a project group */
  deleteProjectGroup?: Maybe<Scalars['Boolean']>;
  /** Deletes a ProviderConfiguration for Environment */
  deleteProviderConfiguration?: Maybe<Scalars['Boolean']>;
  /** Deletes a reference by id */
  deleteReference?: Maybe<Scalars['Boolean']>;
  /** Deletes a release note */
  deleteReleaseNote?: Maybe<Scalars['Boolean']>;
  /** Deletes a slack alert */
  deleteSlackAlert?: Maybe<Scalars['Boolean']>;
  /** Delete a slack reminder */
  deleteSlackReminder?: Maybe<DeleteSlackReminderPayload>;
  /** Deletes a user role */
  deleteUserRole?: Maybe<DeleteUserRolePayload>;
  /** Delete a webhook */
  deleteWebHook?: Maybe<DeleteWebHookPayload>;
  /** Import all ECS Clusters for a profile and region */
  importEcsClusters?: Maybe<Array<EcsCluster>>;
  /** Removes a project from a project group */
  removeProjectFromProjectGroup?: Maybe<ProjectGroup>;
  /** Sets the current tenant */
  setCurrentTenant?: Maybe<User>;
  /** Unarchives an environment */
  unarchiveEnvironment?: Maybe<Environment>;
  /** Unarchives a project */
  unarchiveProject?: Maybe<Project>;
  /** Updates an AWS Profile */
  updateAwsProfile?: Maybe<AwsProfile>;
  /** Updates a Basic Lambda Configuration */
  updateBasicLambdaConfiguration?: Maybe<BasicLambdaConfiguration>;
  /** Updates a CodeBuild Configuration */
  updateCodebuildConfiguration?: Maybe<CodebuildConfiguration>;
  /** Updates an ECS Cluster */
  updateEcsCluster?: Maybe<EcsCluster>;
  /** Updates an ECS Configuration */
  updateEcsConfiguration?: Maybe<EcsConfiguration>;
  /** Updates an Ecs Scheduled Task Configuration */
  updateEcsScheduledTaskConfiguration?: Maybe<EcsScheduledTaskConfiguration>;
  /** Updates an environment */
  updateEnvironment?: Maybe<Environment>;
  /** Updates an environment's position */
  updateEnvironmentPosition?: Maybe<Environment>;
  /** Updates a project */
  updateProject?: Maybe<Project>;
  /** Updates a project group */
  updateProjectGroup?: Maybe<ProjectGroup>;
  /** Updates a reference */
  updateReference?: Maybe<Reference>;
  /** Updates a release note */
  updateReleaseNote?: Maybe<ReleaseNote>;
  /** Updates an S3 Configuration */
  updateS3Configuration?: Maybe<S3Configuration>;
  /** Updates a slack alert */
  updateSlackAlert?: Maybe<SlackAlert>;
  /** Updates a tenant feature */
  updateTenantFeature?: Maybe<TenantFeature>;
  /**
   * Updates a user
   * @deprecated Please use the new UpdateUserRole instead.
   */
  updateUser?: Maybe<User>;
  /** Updates a user role */
  updateUserRole?: Maybe<UpdateUserRolePayload>;
  /** Update a webhook */
  updateWebHook?: Maybe<UpdateWebHookPayload>;
};


export type RootMutationTypeAddProjectToProjectGroupArgs = {
  projectGroupId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type RootMutationTypeArchiveEnvironmentArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeArchiveProjectArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeArchiveUserArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeAssociateSlackAccountArgs = {
  token: Scalars['String'];
};


export type RootMutationTypeCancelDeploymentArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeCreateAwsProfileArgs = {
  input: CreateAwsProfileInput;
};


export type RootMutationTypeCreateBasicLambdaConfigurationArgs = {
  environmentId: Scalars['ID'];
  input: CreateBasicLambdaConfigurationInput;
};


export type RootMutationTypeCreateCodebuildConfigurationArgs = {
  environmentId: Scalars['ID'];
  input: CreateCodebuildConfigurationInput;
};


export type RootMutationTypeCreateDeploymentArgs = {
  input: CreateDeploymentInput;
};


export type RootMutationTypeCreateEcsClusterArgs = {
  input: CreateEcsClusterInput;
};


export type RootMutationTypeCreateEcsConfigurationArgs = {
  environmentId: Scalars['ID'];
  input: CreateEcsConfigurationInput;
};


export type RootMutationTypeCreateEcsScheduledTaskConfigurationArgs = {
  environmentId: Scalars['ID'];
  input: CreateEcsScheduledTaskConfigurationInput;
};


export type RootMutationTypeCreateEnvironmentArgs = {
  input: CreateEnvironmentInput;
};

export type RootMutationTypeCreateProjectArgs = {
  input: CreateProjectInput;
};


export type RootMutationTypeCreateProjectGroupArgs = {
  input: CreateProjectGroupInput;
};


export type RootMutationTypeCreateReferenceArgs = {
  input: CreateReferenceInput;
};


export type RootMutationTypeCreateReleaseNoteArgs = {
  input: CreateReleaseNoteInput;
};


export type RootMutationTypeCreateS3ConfigurationArgs = {
  environmentId: Scalars['ID'];
  input: CreateS3ConfigurationInput;
};


export type RootMutationTypeCreateServiceUserArgs = {
  input: CreateServiceUserInput;
};


export type RootMutationTypeCreateSlackAlertArgs = {
  input: CreateSlackAlertInput;
};


export type RootMutationTypeCreateSlackReminderArgs = {
  input: CreateSlackReminderInput;
};


export type RootMutationTypeCreateUserRoleArgs = {
  input: CreateUserRoleInput;
};


export type RootMutationTypeCreateWebHookArgs = {
  input: CreateWebHookInput;
};


export type RootMutationTypeDeleteAwsProfileArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteEcsClusterArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteEnvironmentArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteHookTokenArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteProjectArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteProjectGroupArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteProviderConfigurationArgs = {
  environmentId: Scalars['ID'];
};


export type RootMutationTypeDeleteReferenceArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteReleaseNoteArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteSlackAlertArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteSlackReminderArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteUserRoleArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteWebHookArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeImportEcsClustersArgs = {
  profileId: Scalars['ID'];
  region?: Maybe<AwsRegion>;
};


export type RootMutationTypeRemoveProjectFromProjectGroupArgs = {
  projectGroupId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type RootMutationTypeSetCurrentTenantArgs = {
  tenant: Scalars['String'];
};


export type RootMutationTypeUnarchiveEnvironmentArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeUnarchiveProjectArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeUpdateAwsProfileArgs = {
  id: Scalars['ID'];
  input: UpdateAwsProfileInput;
};


export type RootMutationTypeUpdateBasicLambdaConfigurationArgs = {
  environmentId: Scalars['ID'];
  input: UpdateBasicLambdaConfigurationInput;
};


export type RootMutationTypeUpdateCodebuildConfigurationArgs = {
  environmentId: Scalars['ID'];
  input: UpdateCodebuildConfigurationInput;
};


export type RootMutationTypeUpdateEcsClusterArgs = {
  id: Scalars['ID'];
  input: UpdateEcsClusterInput;
};


export type RootMutationTypeUpdateEcsConfigurationArgs = {
  environmentId: Scalars['ID'];
  input: UpdateEcsConfigurationInput;
};


export type RootMutationTypeUpdateEcsScheduledTaskConfigurationArgs = {
  environmentId: Scalars['ID'];
  input: UpdateEcsScheduledTaskConfigurationInput;
};


export type RootMutationTypeUpdateEnvironmentArgs = {
  id: Scalars['ID'];
  input: UpdateEnvironmentInput;
};


export type RootMutationTypeUpdateEnvironmentPositionArgs = {
  id: Scalars['ID'];
  input: UpdateEnvironmentPositionInput;
};

export type RootMutationTypeUpdateProjectArgs = {
  id: Scalars['ID'];
  input: UpdateProjectInput;
};


export type RootMutationTypeUpdateProjectGroupArgs = {
  id: Scalars['ID'];
  input: UpdateProjectGroupInput;
};


export type RootMutationTypeUpdateReferenceArgs = {
  id: Scalars['ID'];
  input: UpdateReferenceInput;
};


export type RootMutationTypeUpdateReleaseNoteArgs = {
  id: Scalars['ID'];
  input: UpdateReleaseNoteInput;
};


export type RootMutationTypeUpdateS3ConfigurationArgs = {
  environmentId: Scalars['ID'];
  input: UpdateS3ConfigurationInput;
};


export type RootMutationTypeUpdateSlackAlertArgs = {
  id: Scalars['ID'];
  input: UpdateSlackAlertInput;
};


export type RootMutationTypeUpdateTenantFeatureArgs = {
  input: UpdateTenantFeatureInput;
  key: TenantFeatureKey;
};


export type RootMutationTypeUpdateUserArgs = {
  id: Scalars['ID'];
  input: UpdateUserInput;
};


export type RootMutationTypeUpdateUserRoleArgs = {
  id: Scalars['ID'];
  input: UpdateUserRoleInput;
};


export type RootMutationTypeUpdateWebHookArgs = {
  id: Scalars['ID'];
  input: UpdateWebHookInput;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** List audit logs */
  auditLogs?: Maybe<PaginatedAuditLogs>;
  /** Get a single AWS Profile */
  awsProfile?: Maybe<AwsProfile>;
  /** List all AWS Profiles */
  awsProfiles?: Maybe<Array<AwsProfile>>;
  /** List a single deployment by id */
  deployment?: Maybe<Deployment>;
  /** Get all ECS Clusters */
  ecsClusters?: Maybe<Array<EcsCluster>>;
  /** Lists all services in a given ECS cluster */
  ecsServices?: Maybe<Array<Scalars['String']>>;
  /** List a single environment by id */
  environment?: Maybe<Environment>;
  /** List all environments */
  environments?: Maybe<Array<Environment>>;
  /** List all tokens that you own */
  hookTokens?: Maybe<Array<Token>>;
  /** Get the current user */
  profile?: Maybe<User>;
  /** List a single project by id */
  project?: Maybe<Project>;
  /** List a single project group by id */
  projectGroup?: Maybe<ProjectGroup>;
  /** List all project groups with pagination */
  projectGroups?: Maybe<PaginatedProjectGroups>;
  /** List all projects without pagination */
  projects?: Maybe<Array<Project>>;
  /** Get a single release note */
  releaseNote?: Maybe<ReleaseNote>;
  /** List all release notes */
  releaseNotes?: Maybe<Array<ReleaseNote>>;
  /** Search all projects with pagination */
  searchProjects?: Maybe<PaginatedProjects>;
  serverInformation?: Maybe<ServerInformation>;
  /** List all tenant settings */
  tenantFeatures?: Maybe<Array<TenantFeature>>;
  /** Returns all Shield tenants */
  tenants: Array<Scalars['String']>;
  /** List a single user by id */
  user?: Maybe<User>;
  /** List a single user role */
  userRole?: Maybe<UserRole>;
  /** List all user roles */
  userRoles?: Maybe<Array<UserRole>>;
  /** List all users */
  users?: Maybe<Array<User>>;
};


export type RootQueryTypeAuditLogsArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type RootQueryTypeAwsProfileArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeDeploymentArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeEcsServicesArgs = {
  clusterId: Scalars['ID'];
};


export type RootQueryTypeEnvironmentArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeProjectArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeProjectGroupArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeProjectGroupsArgs = {
  onlyRoots?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  paginate?: Maybe<Scalars['Boolean']>;
};


export type RootQueryTypeProjectsArgs = {
  onlyArchived?: Maybe<Scalars['Boolean']>;
};


export type RootQueryTypeReleaseNoteArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeReleaseNotesArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type RootQueryTypeSearchProjectsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type RootQueryTypeUserArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeUserRoleArgs = {
  id: Scalars['ID'];
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  /** Subscribes to new deployment log entries */
  newDeploymentLogEntry?: Maybe<DeploymentLogEntry>;
  /** Subscribes to new deployments */
  newDeployments?: Maybe<Deployment>;
  /** Subscribes to update deployments */
  updateDeployment?: Maybe<Deployment>;
};


export type RootSubscriptionTypeNewDeploymentLogEntryArgs = {
  deploymentId?: Maybe<Scalars['ID']>;
};


export type RootSubscriptionTypeNewDeploymentsArgs = {
  environmentId?: Maybe<Scalars['ID']>;
};


export type RootSubscriptionTypeUpdateDeploymentArgs = {
  id?: Maybe<Scalars['ID']>;
};

/** Represents a configuration for S3 deployment */
export type S3Configuration = {
  __typename?: 'S3Configuration';
  artifactBucket?: Maybe<Scalars['String']>;
  awsProfile?: Maybe<AwsProfile>;
  deleteOldFiles?: Maybe<Scalars['Boolean']>;
  deployBucket?: Maybe<Scalars['String']>;
  distributionId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  region?: Maybe<AwsRegion>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Shield server information */
export type ServerInformation = {
  __typename?: 'ServerInformation';
  nodes: Array<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** Represents deployment notification configuration for a Slack channel */
export type SlackAlert = {
  __typename?: 'SlackAlert';
  channel?: Maybe<Scalars['String']>;
  environment?: Maybe<Environment>;
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  on?: Maybe<Array<DeploymentStatus>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Represents a slack reminder configuration for a Slack channel */
export type SlackReminder = {
  __typename?: 'SlackReminder';
  channel?: Maybe<Scalars['String']>;
  environment: Environment;
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Represents a VCS tag for the project */
export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String'];
  sha: Scalars['String'];
  url: Scalars['String'];
};

/** Represents a single configurable tenant feature */
export type TenantFeature = {
  __typename?: 'TenantFeature';
  insertedAt?: Maybe<Scalars['DateTime']>;
  key: TenantFeatureKey;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Boolean'];
};

export enum TenantFeatureKey {
  EcsDetails = 'ECS_DETAILS'
}

/** Represents a single token */
export type Token = {
  __typename?: 'Token';
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

/** Attributes for updating an AWS Profile */
export type UpdateAwsProfileInput = {
  name?: Maybe<Scalars['String']>;
  roleArn?: Maybe<Scalars['String']>;
};

/** Attributes for updating a Basic Lambda Configuration */
export type UpdateBasicLambdaConfigurationInput = {
  artifactBucket?: Maybe<Scalars['String']>;
  awsProfileId?: Maybe<Scalars['ID']>;
  functionName?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  region?: Maybe<AwsRegion>;
  type?: Maybe<LambdaType>;
};

/** Attributes for updating a CodeBuild configuration */
export type UpdateCodebuildConfigurationInput = {
  awsProfileId?: Maybe<Scalars['ID']>;
  projectName?: Maybe<Scalars['String']>;
  region?: Maybe<AwsRegion>;
  specifySourceVersion?: Maybe<Scalars['Boolean']>;
  timeout?: Maybe<Scalars['Int']>;
};

/** Attributes for updating an ECS Cluster */
export type UpdateEcsClusterInput = {
  name?: Maybe<Scalars['String']>;
  region?: Maybe<AwsRegion>;
};

/** Attributes for updating an ECS Configuration */
export type UpdateEcsConfigurationInput = {
  ecsClusterId?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  timeout?: Maybe<Scalars['Int']>;
};

/** Attributes for updating an ECS Scheduled Task Configuration */
export type UpdateEcsScheduledTaskConfigurationInput = {
  awsProfileId?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['String']>;
  region?: Maybe<AwsRegion>;
  taskFamily?: Maybe<Scalars['String']>;
};

/** Attributes for updating an environment */
export type UpdateEnvironmentInput = {
  autoPromote?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  privileged?: Maybe<Scalars['Boolean']>;
  tagPattern?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Attributes for updating an environment position */
export type UpdateEnvironmentPositionInput = {
  position: Scalars['Int'];
};

/** Attributes for updating a project group */
export type UpdateProjectGroupInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  projectGroupId?: Maybe<Scalars['ID']>;
};

/** Attributes for updating a project */
export type UpdateProjectInput = {
  badges?: Maybe<Array<CreateBadgeInput>>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  repository?: Maybe<Scalars['String']>;
};

/** Attributes for updating a reference */
export type UpdateReferenceInput = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Attributes for updating a release note */
export type UpdateReleaseNoteInput = {
  markdownContent?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Attributes for updating an ECS Configuration */
export type UpdateS3ConfigurationInput = {
  artifactBucket?: Maybe<Scalars['String']>;
  awsProfileId?: Maybe<Scalars['ID']>;
  deleteOldFiles?: Maybe<Scalars['Boolean']>;
  deployBucket?: Maybe<Scalars['String']>;
  distributionId?: Maybe<Scalars['String']>;
  region?: Maybe<AwsRegion>;
};

/** Attributes for updating a slack alert */
export type UpdateSlackAlertInput = {
  channel?: Maybe<Scalars['String']>;
  on?: Maybe<Array<DeploymentStatus>>;
};

/** Valid input object to updating a tenant feature */
export type UpdateTenantFeatureInput = {
  value: Scalars['Boolean'];
};

/** Attributes for updating a user */
export type UpdateUserInput = {
  roles?: Maybe<Array<UserRoleValue>>;
};

/** Attributes for updating a user role */
export type UpdateUserRoleInput = {
  roles?: Maybe<Array<UserRoleValue>>;
};

/** Payload returned from the updateUserRole mutation */
export type UpdateUserRolePayload = {
  __typename?: 'UpdateUserRolePayload';
  userRole: UserRole;
};

/** Attributes for updating a web hook notification */
export type UpdateWebHookInput = {
  awsProfileId?: Maybe<Scalars['ID']>;
  on?: Maybe<Array<DeploymentStatus>>;
  url?: Maybe<Scalars['String']>;
};

/** Payload returned from the updateWebHook mutation */
export type UpdateWebHookPayload = {
  __typename?: 'UpdateWebHookPayload';
  webHook: WebHook;
};

/** Represents a user of Shield */
export type User = {
  __typename?: 'User';
  currentTenant?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Legacy functionality */
  roles?: Maybe<Array<UserRoleValue>>;
  slackId?: Maybe<Scalars['String']>;
  superAdmin?: Maybe<Scalars['Boolean']>;
  type: UserType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

/** Represents a user role that has been granted for a tenant */
export type UserRole = {
  __typename?: 'UserRole';
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<Array<UserRoleValue>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
};

/** Possible user roles list values */
export enum UserRoleValue {
  Admin = 'ADMIN',
  Config = 'CONFIG',
  Deployer = 'DEPLOYER',
  User = 'USER'
}

/** Possible user type values */
export enum UserType {
  Normal = 'NORMAL',
  Service = 'SERVICE'
}

/** Represents a web hook deployment notification configuration */
export type WebHook = {
  __typename?: 'WebHook';
  awsProfile?: Maybe<AwsProfile>;
  environment?: Maybe<Environment>;
  id?: Maybe<Scalars['ID']>;
  insertedAt?: Maybe<Scalars['DateTime']>;
  on?: Maybe<Array<DeploymentStatus>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type GetAuditLogsQueryVariables = {
  page?: Maybe<Scalars['Int']>
};


export type GetAuditLogsQuery = (
  { __typename?: 'RootQueryType' }
  & { auditLogs: Maybe<(
    { __typename?: 'PaginatedAuditLogs' }
    & { pagination: Maybe<(
      { __typename?: 'PaginationData' }
      & Pick<PaginationData, 'pageNumber' | 'pageSize' | 'totalEntries' | 'totalPages'>
    )>, entries: Array<(
      { __typename?: 'AuditLog' }
      & Pick<AuditLog, 'id' | 'action' | 'changes' | 'snapshot' | 'insertedAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'type'>
      ) }
    )> }
  )> }
);

export type GetTenantsQueryVariables = {};


export type GetTenantsQuery = (
  { __typename?: 'RootQueryType' }
  & Pick<RootQueryType, 'tenants'>
);

export type GetServerInformationQueryVariables = {};


export type GetServerInformationQuery = (
  { __typename?: 'RootQueryType' }
  & { serverInformation: Maybe<(
    { __typename?: 'ServerInformation' }
    & Pick<ServerInformation, 'version'>
  )> }
);

export type GetUsersQueryVariables = {};


export type GetUsersQuery = (
  { __typename?: 'RootQueryType' }
  & { users: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'username' | 'slackId' | 'roles' | 'updatedAt'>
  )>> }
);

export type GetUserRolesQueryVariables = {};


export type GetUserRolesQuery = (
  { __typename?: 'RootQueryType' }
  & { userRoles: Maybe<Array<(
    { __typename?: 'UserRole' }
    & Pick<UserRole, 'id' | 'roles'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'username' | 'email' | 'slackId' | 'updatedAt' | 'type'>
    ) }
  )>> }
);

export type GetUserRoleQueryVariables = {
  id: Scalars['ID']
};


export type GetUserRoleQuery = (
  { __typename?: 'RootQueryType' }
  & { userRole: Maybe<(
    { __typename?: 'UserRole' }
    & Pick<UserRole, 'id' | 'roles'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'type'>
    ) }
  )> }
);

export type CreateUserRoleMutationVariables = {
  input: CreateUserRoleInput
};


export type CreateUserRoleMutation = (
  { __typename?: 'RootMutationType' }
  & { createUserRole: Maybe<(
    { __typename?: 'CreateUserRolePayload' }
    & { userRole: (
      { __typename?: 'UserRole' }
      & Pick<UserRole, 'id' | 'roles'>
    ) }
  )> }
);

export type UpdateUserRoleMutationVariables = {
  id: Scalars['ID'],
  input: UpdateUserRoleInput
};


export type UpdateUserRoleMutation = (
  { __typename?: 'RootMutationType' }
  & { updateUserRole: Maybe<(
    { __typename?: 'UpdateUserRolePayload' }
    & { userRole: (
      { __typename?: 'UserRole' }
      & Pick<UserRole, 'id' | 'roles'>
    ) }
  )> }
);

export type DeleteUserRoleMutationVariables = {
  id: Scalars['ID']
};


export type DeleteUserRoleMutation = (
  { __typename?: 'RootMutationType' }
  & { deleteUserRole: Maybe<(
    { __typename?: 'DeleteUserRolePayload' }
    & { userRole: (
      { __typename?: 'UserRole' }
      & Pick<UserRole, 'id' | 'roles'>
    ) }
  )> }
);

export type CreateServiceUserMutationVariables = {
  input: CreateServiceUserInput
};


export type CreateServiceUserMutation = (
  { __typename?: 'RootMutationType' }
  & { createServiceUser: Maybe<(
    { __typename?: 'CreateUserRolePayload' }
    & { userRole: (
      { __typename?: 'UserRole' }
      & Pick<UserRole, 'id' | 'roles'>
    ) }
  )> }
);

export type GetEcsClustersQueryVariables = {};


export type GetEcsClustersQuery = (
  { __typename?: 'RootQueryType' }
  & { ecsClusters: Maybe<Array<(
    { __typename?: 'EcsCluster' }
    & Pick<EcsCluster, 'id' | 'name' | 'region'>
    & { awsProfile: Maybe<(
      { __typename?: 'AwsProfile' }
      & Pick<AwsProfile, 'id' | 'name'>
    )> }
  )>> }
);

export type GetEcsServicesQueryVariables = {
  clusterId: Scalars['ID']
};


export type GetEcsServicesQuery = (
  { __typename?: 'RootQueryType' }
  & Pick<RootQueryType, 'ecsServices'>
);

export type GetArchivedProjectsQueryVariables = {};


export type GetArchivedProjectsQuery = (
  { __typename?: 'RootQueryType' }
  & { projects: Maybe<Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'archived' | 'updatedAt'>
  )>> }
);

export type UnarchiveProjectMutationVariables = {
  id: Scalars['ID']
};


export type UnarchiveProjectMutation = (
  { __typename?: 'RootMutationType' }
  & { unarchiveProject: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'archived'>
  )> }
);

export type GetTenantFeaturesQueryVariables = {};


export type GetTenantFeaturesQuery = (
  { __typename?: 'RootQueryType' }
  & { tenantFeatures: Maybe<Array<(
    { __typename?: 'TenantFeature' }
    & Pick<TenantFeature, 'key' | 'value'>
  )>> }
);

export type UpdateTenantFeatureMutationVariables = {
  key: TenantFeatureKey,
  feature: UpdateTenantFeatureInput
};


export type UpdateTenantFeatureMutation = (
  { __typename?: 'RootMutationType' }
  & { updateTenantFeature: Maybe<(
    { __typename?: 'TenantFeature' }
    & Pick<TenantFeature, 'key' | 'value'>
  )> }
);

export type CreateSlackReminderMutationVariables = {
  input: CreateSlackReminderInput
};


export type CreateSlackReminderMutation = (
  { __typename?: 'RootMutationType' }
  & { createSlackReminder: Maybe<(
    { __typename?: 'CreateSlackReminderPayload' }
    & { slackReminder: (
      { __typename?: 'SlackReminder' }
      & Pick<SlackReminder, 'id' | 'channel'>
    ) }
  )> }
);

export type DeleteSlackReminderMutationVariables = {
  id: Scalars['ID']
};


export type DeleteSlackReminderMutation = (
  { __typename?: 'RootMutationType' }
  & { deleteSlackReminder: Maybe<(
    { __typename?: 'DeleteSlackReminderPayload' }
    & { slackReminder: (
      { __typename?: 'SlackReminder' }
      & Pick<SlackReminder, 'id'>
    ) }
  )> }
);

export type GetProjectGroupsQueryVariables = {};


export type GetProjectGroupsQuery = (
  { __typename?: 'RootQueryType' }
  & { projectGroups: Maybe<(
    { __typename?: 'PaginatedProjectGroups' }
    & { entries: Maybe<Array<(
      { __typename?: 'ProjectGroup' }
      & Pick<ProjectGroup, 'id' | 'name'>
      & { parentProjectGroup: Maybe<(
        { __typename?: 'ProjectGroup' }
        & Pick<ProjectGroup, 'id'>
      )> }
    )>> }
  )> }
);

export type GetProjectGroupQueryVariables = {
  id: Scalars['ID']
};


export type GetProjectGroupQuery = (
  { __typename?: 'RootQueryType' }
  & { projectGroup: Maybe<(
    { __typename?: 'ProjectGroup' }
    & Pick<ProjectGroup, 'id' | 'name' | 'description'>
    & { projectGroups: Maybe<Array<(
      { __typename?: 'ProjectGroup' }
      & Pick<ProjectGroup, 'id' | 'name'>
      & { projects: Maybe<Array<Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id'>
      )>>> }
    )>>, parentProjectGroup: Maybe<(
      { __typename?: 'ProjectGroup' }
      & Pick<ProjectGroup, 'id' | 'name'>
    )>, projects: Maybe<Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'repository'>
      & { badges: Maybe<Array<(
        { __typename?: 'Badge' }
        & Pick<Badge, 'url' | 'imgSrc'>
      )>>, environments: Maybe<Array<(
        { __typename?: 'Environment' }
        & Pick<Environment, 'name' | 'id'>
      )>>, pulse: Maybe<Array<(
        { __typename?: 'PulseMetric' }
        & Pick<PulseMetric, 'value'>
      )>> }
    )>>> }
  )> }
);

export type UpdateProjectGroupMutationVariables = {
  id: Scalars['ID'],
  input: UpdateProjectGroupInput
};


export type UpdateProjectGroupMutation = (
  { __typename?: 'RootMutationType' }
  & { updateProjectGroup: Maybe<(
    { __typename?: 'ProjectGroup' }
    & Pick<ProjectGroup, 'id'>
  )> }
);

export type DeleteProjectGroupMutationVariables = {
  id: Scalars['ID']
};


export type DeleteProjectGroupMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'deleteProjectGroup'>
);

export type GetTagsQueryVariables = {
  id: Scalars['ID'],
  page?: Maybe<Scalars['Int']>,
  environmentId?: Maybe<Scalars['ID']>
};


export type GetTagsQuery = (
  { __typename?: 'RootQueryType' }
  & { project: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { tags: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'name' | 'sha' | 'url'>
    )>> }
  )> }
);

export type GetProjectGroupsListQueryVariables = {};


export type GetProjectGroupsListQuery = (
  { __typename?: 'RootQueryType' }
  & { projectGroups: Maybe<(
    { __typename?: 'PaginatedProjectGroups' }
    & { entries: Maybe<Array<(
      { __typename?: 'ProjectGroup' }
      & Pick<ProjectGroup, 'id' | 'name'>
    )>> }
  )> }
);

export type GetArchivedEnvironmentsQueryVariables = {
  projectId: Scalars['ID']
};


export type GetArchivedEnvironmentsQuery = (
  { __typename?: 'RootQueryType' }
  & { project: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { environments: Maybe<Array<(
      { __typename?: 'Environment' }
      & Pick<Environment, 'id' | 'name'>
    )>> }
  )> }
);

export type UnarchiveEnvironmentMutationVariables = {
  id: Scalars['ID']
};


export type UnarchiveEnvironmentMutation = (
  { __typename?: 'RootMutationType' }
  & { unarchiveEnvironment: Maybe<(
    { __typename?: 'Environment' }
    & Pick<Environment, 'id' | 'name' | 'archived'>
  )> }
);

export type UpdateProjectMutationVariables = {
  id: Scalars['ID'],
  project: UpdateProjectInput
};


export type UpdateProjectMutation = (
  { __typename?: 'RootMutationType' }
  & { updateProject: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'name' | 'id' | 'description'>
    & { badges: Maybe<Array<(
      { __typename?: 'Badge' }
      & Pick<Badge, 'url' | 'imgSrc'>
    )>> }
  )> }
);

export type GetReleaseNotesQueryVariables = {};


export type GetReleaseNotesQuery = (
  { __typename?: 'RootQueryType' }
  & { releaseNotes: Maybe<Array<(
    { __typename?: 'ReleaseNote' }
    & Pick<ReleaseNote, 'id' | 'title' | 'markdownContent' | 'insertedAt'>
  )>> }
);

export type GetLatestReleaseNoteQueryVariables = {};


export type GetLatestReleaseNoteQuery = (
  { __typename?: 'RootQueryType' }
  & { releaseNotes: Maybe<Array<(
    { __typename?: 'ReleaseNote' }
    & Pick<ReleaseNote, 'id' | 'insertedAt'>
  )>> }
);


export const GetAuditLogsDocument = gql`
    query GetAuditLogs($page: Int) {
  auditLogs(page: $page) {
    pagination {
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
    entries {
      id
      action
      changes
      snapshot
      insertedAt
      user {
        id
        displayName
        type
      }
    }
  }
}
    `;
export type GetAuditLogsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAuditLogsQuery, GetAuditLogsQueryVariables>, 'query'>;

    export const GetAuditLogsComponent = (props: GetAuditLogsComponentProps) => (
      <ApolloReactComponents.Query<GetAuditLogsQuery, GetAuditLogsQueryVariables> query={GetAuditLogsDocument} {...props} />
    );
    
export type GetAuditLogsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAuditLogsQuery, GetAuditLogsQueryVariables> & TChildProps;
export function withGetAuditLogs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAuditLogsQuery,
  GetAuditLogsQueryVariables,
  GetAuditLogsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAuditLogsQuery, GetAuditLogsQueryVariables, GetAuditLogsProps<TChildProps>>(GetAuditLogsDocument, {
      alias: 'getAuditLogs',
      ...operationOptions
    });
};

/**
 * __useGetAuditLogsQuery__
 *
 * To run a query within a React component, call `useGetAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditLogsQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetAuditLogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAuditLogsQuery, GetAuditLogsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAuditLogsQuery, GetAuditLogsQueryVariables>(GetAuditLogsDocument, baseOptions);
      }
export function useGetAuditLogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAuditLogsQuery, GetAuditLogsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAuditLogsQuery, GetAuditLogsQueryVariables>(GetAuditLogsDocument, baseOptions);
        }
export type GetAuditLogsQueryHookResult = ReturnType<typeof useGetAuditLogsQuery>;
export type GetAuditLogsLazyQueryHookResult = ReturnType<typeof useGetAuditLogsLazyQuery>;
export type GetAuditLogsQueryResult = ApolloReactCommon.QueryResult<GetAuditLogsQuery, GetAuditLogsQueryVariables>;
export const GetTenantsDocument = gql`
    query GetTenants {
  tenants
}
    `;
export type GetTenantsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTenantsQuery, GetTenantsQueryVariables>, 'query'>;

    export const GetTenantsComponent = (props: GetTenantsComponentProps) => (
      <ApolloReactComponents.Query<GetTenantsQuery, GetTenantsQueryVariables> query={GetTenantsDocument} {...props} />
    );
    
export type GetTenantsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTenantsQuery, GetTenantsQueryVariables> & TChildProps;
export function withGetTenants<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTenantsQuery,
  GetTenantsQueryVariables,
  GetTenantsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTenantsQuery, GetTenantsQueryVariables, GetTenantsProps<TChildProps>>(GetTenantsDocument, {
      alias: 'getTenants',
      ...operationOptions
    });
};

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, baseOptions);
      }
export function useGetTenantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, baseOptions);
        }
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<typeof useGetTenantsLazyQuery>;
export type GetTenantsQueryResult = ApolloReactCommon.QueryResult<GetTenantsQuery, GetTenantsQueryVariables>;
export const GetServerInformationDocument = gql`
    query GetServerInformation {
  serverInformation {
    version
  }
}
    `;
export type GetServerInformationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetServerInformationQuery, GetServerInformationQueryVariables>, 'query'>;

    export const GetServerInformationComponent = (props: GetServerInformationComponentProps) => (
      <ApolloReactComponents.Query<GetServerInformationQuery, GetServerInformationQueryVariables> query={GetServerInformationDocument} {...props} />
    );
    
export type GetServerInformationProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetServerInformationQuery, GetServerInformationQueryVariables> & TChildProps;
export function withGetServerInformation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetServerInformationQuery,
  GetServerInformationQueryVariables,
  GetServerInformationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetServerInformationQuery, GetServerInformationQueryVariables, GetServerInformationProps<TChildProps>>(GetServerInformationDocument, {
      alias: 'getServerInformation',
      ...operationOptions
    });
};

/**
 * __useGetServerInformationQuery__
 *
 * To run a query within a React component, call `useGetServerInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServerInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServerInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServerInformationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetServerInformationQuery, GetServerInformationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetServerInformationQuery, GetServerInformationQueryVariables>(GetServerInformationDocument, baseOptions);
      }
export function useGetServerInformationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetServerInformationQuery, GetServerInformationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetServerInformationQuery, GetServerInformationQueryVariables>(GetServerInformationDocument, baseOptions);
        }
export type GetServerInformationQueryHookResult = ReturnType<typeof useGetServerInformationQuery>;
export type GetServerInformationLazyQueryHookResult = ReturnType<typeof useGetServerInformationLazyQuery>;
export type GetServerInformationQueryResult = ApolloReactCommon.QueryResult<GetServerInformationQuery, GetServerInformationQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    id
    displayName
    username
    slackId
    roles
    updatedAt
  }
}
    `;
export type GetUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUsersQuery, GetUsersQueryVariables>, 'query'>;

    export const GetUsersComponent = (props: GetUsersComponentProps) => (
      <ApolloReactComponents.Query<GetUsersQuery, GetUsersQueryVariables> query={GetUsersDocument} {...props} />
    );
    
export type GetUsersProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUsersQuery, GetUsersQueryVariables> & TChildProps;
export function withGetUsers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUsersQuery,
  GetUsersQueryVariables,
  GetUsersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUsersQuery, GetUsersQueryVariables, GetUsersProps<TChildProps>>(GetUsersDocument, {
      alias: 'getUsers',
      ...operationOptions
    });
};

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = ApolloReactCommon.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserRolesDocument = gql`
    query GetUserRoles {
  userRoles {
    id
    roles
    user {
      id
      displayName
      username
      email
      slackId
      updatedAt
      type
    }
  }
}
    `;
export type GetUserRolesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserRolesQuery, GetUserRolesQueryVariables>, 'query'>;

    export const GetUserRolesComponent = (props: GetUserRolesComponentProps) => (
      <ApolloReactComponents.Query<GetUserRolesQuery, GetUserRolesQueryVariables> query={GetUserRolesDocument} {...props} />
    );
    
export type GetUserRolesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUserRolesQuery, GetUserRolesQueryVariables> & TChildProps;
export function withGetUserRoles<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserRolesQuery,
  GetUserRolesQueryVariables,
  GetUserRolesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserRolesQuery, GetUserRolesQueryVariables, GetUserRolesProps<TChildProps>>(GetUserRolesDocument, {
      alias: 'getUserRoles',
      ...operationOptions
    });
};

/**
 * __useGetUserRolesQuery__
 *
 * To run a query within a React component, call `useGetUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(GetUserRolesDocument, baseOptions);
      }
export function useGetUserRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(GetUserRolesDocument, baseOptions);
        }
export type GetUserRolesQueryHookResult = ReturnType<typeof useGetUserRolesQuery>;
export type GetUserRolesLazyQueryHookResult = ReturnType<typeof useGetUserRolesLazyQuery>;
export type GetUserRolesQueryResult = ApolloReactCommon.QueryResult<GetUserRolesQuery, GetUserRolesQueryVariables>;
export const GetUserRoleDocument = gql`
    query GetUserRole($id: ID!) {
  userRole(id: $id) {
    id
    roles
    user {
      id
      username
      type
    }
  }
}
    `;
export type GetUserRoleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserRoleQuery, GetUserRoleQueryVariables>, 'query'> & ({ variables: GetUserRoleQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserRoleComponent = (props: GetUserRoleComponentProps) => (
      <ApolloReactComponents.Query<GetUserRoleQuery, GetUserRoleQueryVariables> query={GetUserRoleDocument} {...props} />
    );
    
export type GetUserRoleProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUserRoleQuery, GetUserRoleQueryVariables> & TChildProps;
export function withGetUserRole<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserRoleQuery,
  GetUserRoleQueryVariables,
  GetUserRoleProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserRoleQuery, GetUserRoleQueryVariables, GetUserRoleProps<TChildProps>>(GetUserRoleDocument, {
      alias: 'getUserRole',
      ...operationOptions
    });
};

/**
 * __useGetUserRoleQuery__
 *
 * To run a query within a React component, call `useGetUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserRoleQuery, GetUserRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GetUserRoleDocument, baseOptions);
      }
export function useGetUserRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserRoleQuery, GetUserRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GetUserRoleDocument, baseOptions);
        }
export type GetUserRoleQueryHookResult = ReturnType<typeof useGetUserRoleQuery>;
export type GetUserRoleLazyQueryHookResult = ReturnType<typeof useGetUserRoleLazyQuery>;
export type GetUserRoleQueryResult = ApolloReactCommon.QueryResult<GetUserRoleQuery, GetUserRoleQueryVariables>;
export const CreateUserRoleDocument = gql`
    mutation CreateUserRole($input: CreateUserRoleInput!) {
  createUserRole(input: $input) {
    userRole {
      id
      roles
    }
  }
}
    `;
export type CreateUserRoleMutationFn = ApolloReactCommon.MutationFunction<CreateUserRoleMutation, CreateUserRoleMutationVariables>;
export type CreateUserRoleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserRoleMutation, CreateUserRoleMutationVariables>, 'mutation'>;

    export const CreateUserRoleComponent = (props: CreateUserRoleComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserRoleMutation, CreateUserRoleMutationVariables> mutation={CreateUserRoleDocument} {...props} />
    );
    
export type CreateUserRoleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateUserRoleMutation, CreateUserRoleMutationVariables> & TChildProps;
export function withCreateUserRole<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateUserRoleMutation,
  CreateUserRoleMutationVariables,
  CreateUserRoleProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateUserRoleMutation, CreateUserRoleMutationVariables, CreateUserRoleProps<TChildProps>>(CreateUserRoleDocument, {
      alias: 'createUserRole',
      ...operationOptions
    });
};

/**
 * __useCreateUserRoleMutation__
 *
 * To run a mutation, you first call `useCreateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserRoleMutation, { data, loading, error }] = useCreateUserRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserRoleMutation, CreateUserRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserRoleMutation, CreateUserRoleMutationVariables>(CreateUserRoleDocument, baseOptions);
      }
export type CreateUserRoleMutationHookResult = ReturnType<typeof useCreateUserRoleMutation>;
export type CreateUserRoleMutationResult = ApolloReactCommon.MutationResult<CreateUserRoleMutation>;
export type CreateUserRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserRoleMutation, CreateUserRoleMutationVariables>;
export const UpdateUserRoleDocument = gql`
    mutation UpdateUserRole($id: ID!, $input: UpdateUserRoleInput!) {
  updateUserRole(id: $id, input: $input) {
    userRole {
      id
      roles
    }
  }
}
    `;
export type UpdateUserRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export type UpdateUserRoleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>, 'mutation'>;

    export const UpdateUserRoleComponent = (props: UpdateUserRoleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables> mutation={UpdateUserRoleDocument} {...props} />
    );
    
export type UpdateUserRoleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateUserRoleMutation, UpdateUserRoleMutationVariables> & TChildProps;
export function withUpdateUserRole<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables,
  UpdateUserRoleProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserRoleMutation, UpdateUserRoleMutationVariables, UpdateUserRoleProps<TChildProps>>(UpdateUserRoleDocument, {
      alias: 'updateUserRole',
      ...operationOptions
    });
};

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument, baseOptions);
      }
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = ApolloReactCommon.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const DeleteUserRoleDocument = gql`
    mutation DeleteUserRole($id: ID!) {
  deleteUserRole(id: $id) {
    userRole {
      id
      roles
    }
  }
}
    `;
export type DeleteUserRoleMutationFn = ApolloReactCommon.MutationFunction<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>;
export type DeleteUserRoleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>, 'mutation'>;

    export const DeleteUserRoleComponent = (props: DeleteUserRoleComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteUserRoleMutation, DeleteUserRoleMutationVariables> mutation={DeleteUserRoleDocument} {...props} />
    );
    
export type DeleteUserRoleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteUserRoleMutation, DeleteUserRoleMutationVariables> & TChildProps;
export function withDeleteUserRole<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteUserRoleMutation,
  DeleteUserRoleMutationVariables,
  DeleteUserRoleProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteUserRoleMutation, DeleteUserRoleMutationVariables, DeleteUserRoleProps<TChildProps>>(DeleteUserRoleDocument, {
      alias: 'deleteUserRole',
      ...operationOptions
    });
};

/**
 * __useDeleteUserRoleMutation__
 *
 * To run a mutation, you first call `useDeleteUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserRoleMutation, { data, loading, error }] = useDeleteUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>(DeleteUserRoleDocument, baseOptions);
      }
export type DeleteUserRoleMutationHookResult = ReturnType<typeof useDeleteUserRoleMutation>;
export type DeleteUserRoleMutationResult = ApolloReactCommon.MutationResult<DeleteUserRoleMutation>;
export type DeleteUserRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>;
export const CreateServiceUserDocument = gql`
    mutation CreateServiceUser($input: CreateServiceUserInput!) {
  createServiceUser(input: $input) {
    userRole {
      id
      roles
    }
  }
}
    `;
export type CreateServiceUserMutationFn = ApolloReactCommon.MutationFunction<CreateServiceUserMutation, CreateServiceUserMutationVariables>;
export type CreateServiceUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateServiceUserMutation, CreateServiceUserMutationVariables>, 'mutation'>;

    export const CreateServiceUserComponent = (props: CreateServiceUserComponentProps) => (
      <ApolloReactComponents.Mutation<CreateServiceUserMutation, CreateServiceUserMutationVariables> mutation={CreateServiceUserDocument} {...props} />
    );
    
export type CreateServiceUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateServiceUserMutation, CreateServiceUserMutationVariables> & TChildProps;
export function withCreateServiceUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateServiceUserMutation,
  CreateServiceUserMutationVariables,
  CreateServiceUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateServiceUserMutation, CreateServiceUserMutationVariables, CreateServiceUserProps<TChildProps>>(CreateServiceUserDocument, {
      alias: 'createServiceUser',
      ...operationOptions
    });
};

/**
 * __useCreateServiceUserMutation__
 *
 * To run a mutation, you first call `useCreateServiceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceUserMutation, { data, loading, error }] = useCreateServiceUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateServiceUserMutation, CreateServiceUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateServiceUserMutation, CreateServiceUserMutationVariables>(CreateServiceUserDocument, baseOptions);
      }
export type CreateServiceUserMutationHookResult = ReturnType<typeof useCreateServiceUserMutation>;
export type CreateServiceUserMutationResult = ApolloReactCommon.MutationResult<CreateServiceUserMutation>;
export type CreateServiceUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateServiceUserMutation, CreateServiceUserMutationVariables>;
export const GetEcsClustersDocument = gql`
    query GetEcsClusters {
  ecsClusters {
    id
    name
    region
    awsProfile {
      id
      name
    }
  }
}
    `;
export type GetEcsClustersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEcsClustersQuery, GetEcsClustersQueryVariables>, 'query'>;

    export const GetEcsClustersComponent = (props: GetEcsClustersComponentProps) => (
      <ApolloReactComponents.Query<GetEcsClustersQuery, GetEcsClustersQueryVariables> query={GetEcsClustersDocument} {...props} />
    );
    
export type GetEcsClustersProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetEcsClustersQuery, GetEcsClustersQueryVariables> & TChildProps;
export function withGetEcsClusters<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetEcsClustersQuery,
  GetEcsClustersQueryVariables,
  GetEcsClustersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetEcsClustersQuery, GetEcsClustersQueryVariables, GetEcsClustersProps<TChildProps>>(GetEcsClustersDocument, {
      alias: 'getEcsClusters',
      ...operationOptions
    });
};

/**
 * __useGetEcsClustersQuery__
 *
 * To run a query within a React component, call `useGetEcsClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcsClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcsClustersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEcsClustersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEcsClustersQuery, GetEcsClustersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEcsClustersQuery, GetEcsClustersQueryVariables>(GetEcsClustersDocument, baseOptions);
      }
export function useGetEcsClustersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEcsClustersQuery, GetEcsClustersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEcsClustersQuery, GetEcsClustersQueryVariables>(GetEcsClustersDocument, baseOptions);
        }
export type GetEcsClustersQueryHookResult = ReturnType<typeof useGetEcsClustersQuery>;
export type GetEcsClustersLazyQueryHookResult = ReturnType<typeof useGetEcsClustersLazyQuery>;
export type GetEcsClustersQueryResult = ApolloReactCommon.QueryResult<GetEcsClustersQuery, GetEcsClustersQueryVariables>;
export const GetEcsServicesDocument = gql`
    query GetEcsServices($clusterId: ID!) {
  ecsServices(clusterId: $clusterId)
}
    `;
export type GetEcsServicesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEcsServicesQuery, GetEcsServicesQueryVariables>, 'query'> & ({ variables: GetEcsServicesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEcsServicesComponent = (props: GetEcsServicesComponentProps) => (
      <ApolloReactComponents.Query<GetEcsServicesQuery, GetEcsServicesQueryVariables> query={GetEcsServicesDocument} {...props} />
    );
    
export type GetEcsServicesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetEcsServicesQuery, GetEcsServicesQueryVariables> & TChildProps;
export function withGetEcsServices<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetEcsServicesQuery,
  GetEcsServicesQueryVariables,
  GetEcsServicesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetEcsServicesQuery, GetEcsServicesQueryVariables, GetEcsServicesProps<TChildProps>>(GetEcsServicesDocument, {
      alias: 'getEcsServices',
      ...operationOptions
    });
};

/**
 * __useGetEcsServicesQuery__
 *
 * To run a query within a React component, call `useGetEcsServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcsServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcsServicesQuery({
 *   variables: {
 *      clusterId: // value for 'clusterId'
 *   },
 * });
 */
export function useGetEcsServicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEcsServicesQuery, GetEcsServicesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEcsServicesQuery, GetEcsServicesQueryVariables>(GetEcsServicesDocument, baseOptions);
      }
export function useGetEcsServicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEcsServicesQuery, GetEcsServicesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEcsServicesQuery, GetEcsServicesQueryVariables>(GetEcsServicesDocument, baseOptions);
        }
export type GetEcsServicesQueryHookResult = ReturnType<typeof useGetEcsServicesQuery>;
export type GetEcsServicesLazyQueryHookResult = ReturnType<typeof useGetEcsServicesLazyQuery>;
export type GetEcsServicesQueryResult = ApolloReactCommon.QueryResult<GetEcsServicesQuery, GetEcsServicesQueryVariables>;
export const GetArchivedProjectsDocument = gql`
    query GetArchivedProjects {
  projects(onlyArchived: true) {
    id
    name
    archived
    updatedAt
  }
}
    `;
export type GetArchivedProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetArchivedProjectsQuery, GetArchivedProjectsQueryVariables>, 'query'>;

    export const GetArchivedProjectsComponent = (props: GetArchivedProjectsComponentProps) => (
      <ApolloReactComponents.Query<GetArchivedProjectsQuery, GetArchivedProjectsQueryVariables> query={GetArchivedProjectsDocument} {...props} />
    );
    
export type GetArchivedProjectsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetArchivedProjectsQuery, GetArchivedProjectsQueryVariables> & TChildProps;
export function withGetArchivedProjects<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetArchivedProjectsQuery,
  GetArchivedProjectsQueryVariables,
  GetArchivedProjectsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetArchivedProjectsQuery, GetArchivedProjectsQueryVariables, GetArchivedProjectsProps<TChildProps>>(GetArchivedProjectsDocument, {
      alias: 'getArchivedProjects',
      ...operationOptions
    });
};

/**
 * __useGetArchivedProjectsQuery__
 *
 * To run a query within a React component, call `useGetArchivedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArchivedProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArchivedProjectsQuery, GetArchivedProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArchivedProjectsQuery, GetArchivedProjectsQueryVariables>(GetArchivedProjectsDocument, baseOptions);
      }
export function useGetArchivedProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArchivedProjectsQuery, GetArchivedProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArchivedProjectsQuery, GetArchivedProjectsQueryVariables>(GetArchivedProjectsDocument, baseOptions);
        }
export type GetArchivedProjectsQueryHookResult = ReturnType<typeof useGetArchivedProjectsQuery>;
export type GetArchivedProjectsLazyQueryHookResult = ReturnType<typeof useGetArchivedProjectsLazyQuery>;
export type GetArchivedProjectsQueryResult = ApolloReactCommon.QueryResult<GetArchivedProjectsQuery, GetArchivedProjectsQueryVariables>;
export const UnarchiveProjectDocument = gql`
    mutation UnarchiveProject($id: ID!) {
  unarchiveProject(id: $id) {
    id
    name
    archived
  }
}
    `;
export type UnarchiveProjectMutationFn = ApolloReactCommon.MutationFunction<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>;
export type UnarchiveProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>, 'mutation'>;

    export const UnarchiveProjectComponent = (props: UnarchiveProjectComponentProps) => (
      <ApolloReactComponents.Mutation<UnarchiveProjectMutation, UnarchiveProjectMutationVariables> mutation={UnarchiveProjectDocument} {...props} />
    );
    
export type UnarchiveProjectProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UnarchiveProjectMutation, UnarchiveProjectMutationVariables> & TChildProps;
export function withUnarchiveProject<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnarchiveProjectMutation,
  UnarchiveProjectMutationVariables,
  UnarchiveProjectProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UnarchiveProjectMutation, UnarchiveProjectMutationVariables, UnarchiveProjectProps<TChildProps>>(UnarchiveProjectDocument, {
      alias: 'unarchiveProject',
      ...operationOptions
    });
};

/**
 * __useUnarchiveProjectMutation__
 *
 * To run a mutation, you first call `useUnarchiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveProjectMutation, { data, loading, error }] = useUnarchiveProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>(UnarchiveProjectDocument, baseOptions);
      }
export type UnarchiveProjectMutationHookResult = ReturnType<typeof useUnarchiveProjectMutation>;
export type UnarchiveProjectMutationResult = ApolloReactCommon.MutationResult<UnarchiveProjectMutation>;
export type UnarchiveProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>;
export const GetTenantFeaturesDocument = gql`
    query GetTenantFeatures {
  tenantFeatures {
    key
    value
  }
}
    `;
export type GetTenantFeaturesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTenantFeaturesQuery, GetTenantFeaturesQueryVariables>, 'query'>;

    export const GetTenantFeaturesComponent = (props: GetTenantFeaturesComponentProps) => (
      <ApolloReactComponents.Query<GetTenantFeaturesQuery, GetTenantFeaturesQueryVariables> query={GetTenantFeaturesDocument} {...props} />
    );
    
export type GetTenantFeaturesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTenantFeaturesQuery, GetTenantFeaturesQueryVariables> & TChildProps;
export function withGetTenantFeatures<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTenantFeaturesQuery,
  GetTenantFeaturesQueryVariables,
  GetTenantFeaturesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTenantFeaturesQuery, GetTenantFeaturesQueryVariables, GetTenantFeaturesProps<TChildProps>>(GetTenantFeaturesDocument, {
      alias: 'getTenantFeatures',
      ...operationOptions
    });
};

/**
 * __useGetTenantFeaturesQuery__
 *
 * To run a query within a React component, call `useGetTenantFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantFeaturesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTenantFeaturesQuery, GetTenantFeaturesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTenantFeaturesQuery, GetTenantFeaturesQueryVariables>(GetTenantFeaturesDocument, baseOptions);
      }
export function useGetTenantFeaturesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTenantFeaturesQuery, GetTenantFeaturesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTenantFeaturesQuery, GetTenantFeaturesQueryVariables>(GetTenantFeaturesDocument, baseOptions);
        }
export type GetTenantFeaturesQueryHookResult = ReturnType<typeof useGetTenantFeaturesQuery>;
export type GetTenantFeaturesLazyQueryHookResult = ReturnType<typeof useGetTenantFeaturesLazyQuery>;
export type GetTenantFeaturesQueryResult = ApolloReactCommon.QueryResult<GetTenantFeaturesQuery, GetTenantFeaturesQueryVariables>;
export const UpdateTenantFeatureDocument = gql`
    mutation UpdateTenantFeature($key: TenantFeatureKey!, $feature: UpdateTenantFeatureInput!) {
  updateTenantFeature(input: $feature, key: $key) {
    key
    value
  }
}
    `;
export type UpdateTenantFeatureMutationFn = ApolloReactCommon.MutationFunction<UpdateTenantFeatureMutation, UpdateTenantFeatureMutationVariables>;
export type UpdateTenantFeatureComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTenantFeatureMutation, UpdateTenantFeatureMutationVariables>, 'mutation'>;

    export const UpdateTenantFeatureComponent = (props: UpdateTenantFeatureComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTenantFeatureMutation, UpdateTenantFeatureMutationVariables> mutation={UpdateTenantFeatureDocument} {...props} />
    );
    
export type UpdateTenantFeatureProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateTenantFeatureMutation, UpdateTenantFeatureMutationVariables> & TChildProps;
export function withUpdateTenantFeature<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateTenantFeatureMutation,
  UpdateTenantFeatureMutationVariables,
  UpdateTenantFeatureProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateTenantFeatureMutation, UpdateTenantFeatureMutationVariables, UpdateTenantFeatureProps<TChildProps>>(UpdateTenantFeatureDocument, {
      alias: 'updateTenantFeature',
      ...operationOptions
    });
};

/**
 * __useUpdateTenantFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateTenantFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantFeatureMutation, { data, loading, error }] = useUpdateTenantFeatureMutation({
 *   variables: {
 *      key: // value for 'key'
 *      feature: // value for 'feature'
 *   },
 * });
 */
export function useUpdateTenantFeatureMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTenantFeatureMutation, UpdateTenantFeatureMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTenantFeatureMutation, UpdateTenantFeatureMutationVariables>(UpdateTenantFeatureDocument, baseOptions);
      }
export type UpdateTenantFeatureMutationHookResult = ReturnType<typeof useUpdateTenantFeatureMutation>;
export type UpdateTenantFeatureMutationResult = ApolloReactCommon.MutationResult<UpdateTenantFeatureMutation>;
export type UpdateTenantFeatureMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTenantFeatureMutation, UpdateTenantFeatureMutationVariables>;
export const CreateSlackReminderDocument = gql`
    mutation CreateSlackReminder($input: CreateSlackReminderInput!) {
  createSlackReminder(input: $input) {
    slackReminder {
      id
      channel
    }
  }
}
    `;
export type CreateSlackReminderMutationFn = ApolloReactCommon.MutationFunction<CreateSlackReminderMutation, CreateSlackReminderMutationVariables>;
export type CreateSlackReminderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSlackReminderMutation, CreateSlackReminderMutationVariables>, 'mutation'>;

    export const CreateSlackReminderComponent = (props: CreateSlackReminderComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSlackReminderMutation, CreateSlackReminderMutationVariables> mutation={CreateSlackReminderDocument} {...props} />
    );
    
export type CreateSlackReminderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateSlackReminderMutation, CreateSlackReminderMutationVariables> & TChildProps;
export function withCreateSlackReminder<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSlackReminderMutation,
  CreateSlackReminderMutationVariables,
  CreateSlackReminderProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSlackReminderMutation, CreateSlackReminderMutationVariables, CreateSlackReminderProps<TChildProps>>(CreateSlackReminderDocument, {
      alias: 'createSlackReminder',
      ...operationOptions
    });
};

/**
 * __useCreateSlackReminderMutation__
 *
 * To run a mutation, you first call `useCreateSlackReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSlackReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSlackReminderMutation, { data, loading, error }] = useCreateSlackReminderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSlackReminderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSlackReminderMutation, CreateSlackReminderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSlackReminderMutation, CreateSlackReminderMutationVariables>(CreateSlackReminderDocument, baseOptions);
      }
export type CreateSlackReminderMutationHookResult = ReturnType<typeof useCreateSlackReminderMutation>;
export type CreateSlackReminderMutationResult = ApolloReactCommon.MutationResult<CreateSlackReminderMutation>;
export type CreateSlackReminderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSlackReminderMutation, CreateSlackReminderMutationVariables>;
export const DeleteSlackReminderDocument = gql`
    mutation DeleteSlackReminder($id: ID!) {
  deleteSlackReminder(id: $id) {
    slackReminder {
      id
    }
  }
}
    `;
export type DeleteSlackReminderMutationFn = ApolloReactCommon.MutationFunction<DeleteSlackReminderMutation, DeleteSlackReminderMutationVariables>;
export type DeleteSlackReminderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSlackReminderMutation, DeleteSlackReminderMutationVariables>, 'mutation'>;

    export const DeleteSlackReminderComponent = (props: DeleteSlackReminderComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSlackReminderMutation, DeleteSlackReminderMutationVariables> mutation={DeleteSlackReminderDocument} {...props} />
    );
    
export type DeleteSlackReminderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteSlackReminderMutation, DeleteSlackReminderMutationVariables> & TChildProps;
export function withDeleteSlackReminder<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteSlackReminderMutation,
  DeleteSlackReminderMutationVariables,
  DeleteSlackReminderProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteSlackReminderMutation, DeleteSlackReminderMutationVariables, DeleteSlackReminderProps<TChildProps>>(DeleteSlackReminderDocument, {
      alias: 'deleteSlackReminder',
      ...operationOptions
    });
};

/**
 * __useDeleteSlackReminderMutation__
 *
 * To run a mutation, you first call `useDeleteSlackReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSlackReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSlackReminderMutation, { data, loading, error }] = useDeleteSlackReminderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSlackReminderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSlackReminderMutation, DeleteSlackReminderMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSlackReminderMutation, DeleteSlackReminderMutationVariables>(DeleteSlackReminderDocument, baseOptions);
      }
export type DeleteSlackReminderMutationHookResult = ReturnType<typeof useDeleteSlackReminderMutation>;
export type DeleteSlackReminderMutationResult = ApolloReactCommon.MutationResult<DeleteSlackReminderMutation>;
export type DeleteSlackReminderMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSlackReminderMutation, DeleteSlackReminderMutationVariables>;
export const GetProjectGroupsDocument = gql`
    query GetProjectGroups {
  projectGroups {
    entries {
      id
      name
      parentProjectGroup {
        id
      }
    }
  }
}
    `;
export type GetProjectGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>, 'query'>;

    export const GetProjectGroupsComponent = (props: GetProjectGroupsComponentProps) => (
      <ApolloReactComponents.Query<GetProjectGroupsQuery, GetProjectGroupsQueryVariables> query={GetProjectGroupsDocument} {...props} />
    );
    
export type GetProjectGroupsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetProjectGroupsQuery, GetProjectGroupsQueryVariables> & TChildProps;
export function withGetProjectGroups<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetProjectGroupsQuery,
  GetProjectGroupsQueryVariables,
  GetProjectGroupsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetProjectGroupsQuery, GetProjectGroupsQueryVariables, GetProjectGroupsProps<TChildProps>>(GetProjectGroupsDocument, {
      alias: 'getProjectGroups',
      ...operationOptions
    });
};

/**
 * __useGetProjectGroupsQuery__
 *
 * To run a query within a React component, call `useGetProjectGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>(GetProjectGroupsDocument, baseOptions);
      }
export function useGetProjectGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>(GetProjectGroupsDocument, baseOptions);
        }
export type GetProjectGroupsQueryHookResult = ReturnType<typeof useGetProjectGroupsQuery>;
export type GetProjectGroupsLazyQueryHookResult = ReturnType<typeof useGetProjectGroupsLazyQuery>;
export type GetProjectGroupsQueryResult = ApolloReactCommon.QueryResult<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>;
export const GetProjectGroupDocument = gql`
    query GetProjectGroup($id: ID!) {
  projectGroup(id: $id) {
    id
    name
    description
    projectGroups {
      id
      name
      projects {
        id
      }
    }
    parentProjectGroup {
      id
      name
    }
    projects {
      id
      name
      repository
      badges {
        url
        imgSrc
      }
      environments {
        name
        id
      }
      pulse {
        value
      }
    }
  }
}
    `;
export type GetProjectGroupComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectGroupQuery, GetProjectGroupQueryVariables>, 'query'> & ({ variables: GetProjectGroupQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectGroupComponent = (props: GetProjectGroupComponentProps) => (
      <ApolloReactComponents.Query<GetProjectGroupQuery, GetProjectGroupQueryVariables> query={GetProjectGroupDocument} {...props} />
    );
    
export type GetProjectGroupProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetProjectGroupQuery, GetProjectGroupQueryVariables> & TChildProps;
export function withGetProjectGroup<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetProjectGroupQuery,
  GetProjectGroupQueryVariables,
  GetProjectGroupProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetProjectGroupQuery, GetProjectGroupQueryVariables, GetProjectGroupProps<TChildProps>>(GetProjectGroupDocument, {
      alias: 'getProjectGroup',
      ...operationOptions
    });
};

/**
 * __useGetProjectGroupQuery__
 *
 * To run a query within a React component, call `useGetProjectGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectGroupQuery, GetProjectGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectGroupQuery, GetProjectGroupQueryVariables>(GetProjectGroupDocument, baseOptions);
      }
export function useGetProjectGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectGroupQuery, GetProjectGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectGroupQuery, GetProjectGroupQueryVariables>(GetProjectGroupDocument, baseOptions);
        }
export type GetProjectGroupQueryHookResult = ReturnType<typeof useGetProjectGroupQuery>;
export type GetProjectGroupLazyQueryHookResult = ReturnType<typeof useGetProjectGroupLazyQuery>;
export type GetProjectGroupQueryResult = ApolloReactCommon.QueryResult<GetProjectGroupQuery, GetProjectGroupQueryVariables>;
export const UpdateProjectGroupDocument = gql`
    mutation UpdateProjectGroup($id: ID!, $input: UpdateProjectGroupInput!) {
  updateProjectGroup(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateProjectGroupMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectGroupMutation, UpdateProjectGroupMutationVariables>;
export type UpdateProjectGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectGroupMutation, UpdateProjectGroupMutationVariables>, 'mutation'>;

    export const UpdateProjectGroupComponent = (props: UpdateProjectGroupComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectGroupMutation, UpdateProjectGroupMutationVariables> mutation={UpdateProjectGroupDocument} {...props} />
    );
    
export type UpdateProjectGroupProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateProjectGroupMutation, UpdateProjectGroupMutationVariables> & TChildProps;
export function withUpdateProjectGroup<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateProjectGroupMutation,
  UpdateProjectGroupMutationVariables,
  UpdateProjectGroupProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateProjectGroupMutation, UpdateProjectGroupMutationVariables, UpdateProjectGroupProps<TChildProps>>(UpdateProjectGroupDocument, {
      alias: 'updateProjectGroup',
      ...operationOptions
    });
};

/**
 * __useUpdateProjectGroupMutation__
 *
 * To run a mutation, you first call `useUpdateProjectGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectGroupMutation, { data, loading, error }] = useUpdateProjectGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectGroupMutation, UpdateProjectGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectGroupMutation, UpdateProjectGroupMutationVariables>(UpdateProjectGroupDocument, baseOptions);
      }
export type UpdateProjectGroupMutationHookResult = ReturnType<typeof useUpdateProjectGroupMutation>;
export type UpdateProjectGroupMutationResult = ApolloReactCommon.MutationResult<UpdateProjectGroupMutation>;
export type UpdateProjectGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectGroupMutation, UpdateProjectGroupMutationVariables>;
export const DeleteProjectGroupDocument = gql`
    mutation DeleteProjectGroup($id: ID!) {
  deleteProjectGroup(id: $id)
}
    `;
export type DeleteProjectGroupMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectGroupMutation, DeleteProjectGroupMutationVariables>;
export type DeleteProjectGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProjectGroupMutation, DeleteProjectGroupMutationVariables>, 'mutation'>;

    export const DeleteProjectGroupComponent = (props: DeleteProjectGroupComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProjectGroupMutation, DeleteProjectGroupMutationVariables> mutation={DeleteProjectGroupDocument} {...props} />
    );
    
export type DeleteProjectGroupProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteProjectGroupMutation, DeleteProjectGroupMutationVariables> & TChildProps;
export function withDeleteProjectGroup<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteProjectGroupMutation,
  DeleteProjectGroupMutationVariables,
  DeleteProjectGroupProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteProjectGroupMutation, DeleteProjectGroupMutationVariables, DeleteProjectGroupProps<TChildProps>>(DeleteProjectGroupDocument, {
      alias: 'deleteProjectGroup',
      ...operationOptions
    });
};

/**
 * __useDeleteProjectGroupMutation__
 *
 * To run a mutation, you first call `useDeleteProjectGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectGroupMutation, { data, loading, error }] = useDeleteProjectGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectGroupMutation, DeleteProjectGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectGroupMutation, DeleteProjectGroupMutationVariables>(DeleteProjectGroupDocument, baseOptions);
      }
export type DeleteProjectGroupMutationHookResult = ReturnType<typeof useDeleteProjectGroupMutation>;
export type DeleteProjectGroupMutationResult = ApolloReactCommon.MutationResult<DeleteProjectGroupMutation>;
export type DeleteProjectGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectGroupMutation, DeleteProjectGroupMutationVariables>;
export const GetTagsDocument = gql`
    query GetTags($id: ID!, $page: Int, $environmentId: ID) {
  project(id: $id) {
    id
    tags(page: $page, environmentId: $environmentId) {
      name
      sha
      url
    }
  }
}
    `;
export type GetTagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTagsQuery, GetTagsQueryVariables>, 'query'> & ({ variables: GetTagsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTagsComponent = (props: GetTagsComponentProps) => (
      <ApolloReactComponents.Query<GetTagsQuery, GetTagsQueryVariables> query={GetTagsDocument} {...props} />
    );
    
export type GetTagsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTagsQuery, GetTagsQueryVariables> & TChildProps;
export function withGetTags<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTagsQuery,
  GetTagsQueryVariables,
  GetTagsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTagsQuery, GetTagsQueryVariables, GetTagsProps<TChildProps>>(GetTagsDocument, {
      alias: 'getTags',
      ...operationOptions
    });
};

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      environmentId: // value for 'environmentId'
 *   },
 * });
 */
export function useGetTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, baseOptions);
      }
export function useGetTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, baseOptions);
        }
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = ApolloReactCommon.QueryResult<GetTagsQuery, GetTagsQueryVariables>;
export const GetProjectGroupsListDocument = gql`
    query GetProjectGroupsList {
  projectGroups(paginate: false) {
    entries {
      id
      name
    }
  }
}
    `;
export type GetProjectGroupsListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectGroupsListQuery, GetProjectGroupsListQueryVariables>, 'query'>;

    export const GetProjectGroupsListComponent = (props: GetProjectGroupsListComponentProps) => (
      <ApolloReactComponents.Query<GetProjectGroupsListQuery, GetProjectGroupsListQueryVariables> query={GetProjectGroupsListDocument} {...props} />
    );
    
export type GetProjectGroupsListProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetProjectGroupsListQuery, GetProjectGroupsListQueryVariables> & TChildProps;
export function withGetProjectGroupsList<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetProjectGroupsListQuery,
  GetProjectGroupsListQueryVariables,
  GetProjectGroupsListProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetProjectGroupsListQuery, GetProjectGroupsListQueryVariables, GetProjectGroupsListProps<TChildProps>>(GetProjectGroupsListDocument, {
      alias: 'getProjectGroupsList',
      ...operationOptions
    });
};

/**
 * __useGetProjectGroupsListQuery__
 *
 * To run a query within a React component, call `useGetProjectGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectGroupsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectGroupsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectGroupsListQuery, GetProjectGroupsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectGroupsListQuery, GetProjectGroupsListQueryVariables>(GetProjectGroupsListDocument, baseOptions);
      }
export function useGetProjectGroupsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectGroupsListQuery, GetProjectGroupsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectGroupsListQuery, GetProjectGroupsListQueryVariables>(GetProjectGroupsListDocument, baseOptions);
        }
export type GetProjectGroupsListQueryHookResult = ReturnType<typeof useGetProjectGroupsListQuery>;
export type GetProjectGroupsListLazyQueryHookResult = ReturnType<typeof useGetProjectGroupsListLazyQuery>;
export type GetProjectGroupsListQueryResult = ApolloReactCommon.QueryResult<GetProjectGroupsListQuery, GetProjectGroupsListQueryVariables>;
export const GetArchivedEnvironmentsDocument = gql`
    query GetArchivedEnvironments($projectId: ID!) {
  project(id: $projectId) {
    id
    environments(onlyArchived: true) {
      id
      name
    }
  }
}
    `;
export type GetArchivedEnvironmentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetArchivedEnvironmentsQuery, GetArchivedEnvironmentsQueryVariables>, 'query'> & ({ variables: GetArchivedEnvironmentsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetArchivedEnvironmentsComponent = (props: GetArchivedEnvironmentsComponentProps) => (
      <ApolloReactComponents.Query<GetArchivedEnvironmentsQuery, GetArchivedEnvironmentsQueryVariables> query={GetArchivedEnvironmentsDocument} {...props} />
    );
    
export type GetArchivedEnvironmentsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetArchivedEnvironmentsQuery, GetArchivedEnvironmentsQueryVariables> & TChildProps;
export function withGetArchivedEnvironments<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetArchivedEnvironmentsQuery,
  GetArchivedEnvironmentsQueryVariables,
  GetArchivedEnvironmentsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetArchivedEnvironmentsQuery, GetArchivedEnvironmentsQueryVariables, GetArchivedEnvironmentsProps<TChildProps>>(GetArchivedEnvironmentsDocument, {
      alias: 'getArchivedEnvironments',
      ...operationOptions
    });
};

/**
 * __useGetArchivedEnvironmentsQuery__
 *
 * To run a query within a React component, call `useGetArchivedEnvironmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedEnvironmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedEnvironmentsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetArchivedEnvironmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArchivedEnvironmentsQuery, GetArchivedEnvironmentsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArchivedEnvironmentsQuery, GetArchivedEnvironmentsQueryVariables>(GetArchivedEnvironmentsDocument, baseOptions);
      }
export function useGetArchivedEnvironmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArchivedEnvironmentsQuery, GetArchivedEnvironmentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArchivedEnvironmentsQuery, GetArchivedEnvironmentsQueryVariables>(GetArchivedEnvironmentsDocument, baseOptions);
        }
export type GetArchivedEnvironmentsQueryHookResult = ReturnType<typeof useGetArchivedEnvironmentsQuery>;
export type GetArchivedEnvironmentsLazyQueryHookResult = ReturnType<typeof useGetArchivedEnvironmentsLazyQuery>;
export type GetArchivedEnvironmentsQueryResult = ApolloReactCommon.QueryResult<GetArchivedEnvironmentsQuery, GetArchivedEnvironmentsQueryVariables>;
export const UnarchiveEnvironmentDocument = gql`
    mutation UnarchiveEnvironment($id: ID!) {
  unarchiveEnvironment(id: $id) {
    id
    name
    archived
  }
}
    `;
export type UnarchiveEnvironmentMutationFn = ApolloReactCommon.MutationFunction<UnarchiveEnvironmentMutation, UnarchiveEnvironmentMutationVariables>;
export type UnarchiveEnvironmentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnarchiveEnvironmentMutation, UnarchiveEnvironmentMutationVariables>, 'mutation'>;

    export const UnarchiveEnvironmentComponent = (props: UnarchiveEnvironmentComponentProps) => (
      <ApolloReactComponents.Mutation<UnarchiveEnvironmentMutation, UnarchiveEnvironmentMutationVariables> mutation={UnarchiveEnvironmentDocument} {...props} />
    );
    
export type UnarchiveEnvironmentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UnarchiveEnvironmentMutation, UnarchiveEnvironmentMutationVariables> & TChildProps;
export function withUnarchiveEnvironment<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnarchiveEnvironmentMutation,
  UnarchiveEnvironmentMutationVariables,
  UnarchiveEnvironmentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UnarchiveEnvironmentMutation, UnarchiveEnvironmentMutationVariables, UnarchiveEnvironmentProps<TChildProps>>(UnarchiveEnvironmentDocument, {
      alias: 'unarchiveEnvironment',
      ...operationOptions
    });
};

/**
 * __useUnarchiveEnvironmentMutation__
 *
 * To run a mutation, you first call `useUnarchiveEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveEnvironmentMutation, { data, loading, error }] = useUnarchiveEnvironmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveEnvironmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnarchiveEnvironmentMutation, UnarchiveEnvironmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UnarchiveEnvironmentMutation, UnarchiveEnvironmentMutationVariables>(UnarchiveEnvironmentDocument, baseOptions);
      }
export type UnarchiveEnvironmentMutationHookResult = ReturnType<typeof useUnarchiveEnvironmentMutation>;
export type UnarchiveEnvironmentMutationResult = ApolloReactCommon.MutationResult<UnarchiveEnvironmentMutation>;
export type UnarchiveEnvironmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UnarchiveEnvironmentMutation, UnarchiveEnvironmentMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($id: ID!, $project: UpdateProjectInput!) {
  updateProject(id: $id, input: $project) {
    name
    id
    description
    badges {
      url
      imgSrc
    }
  }
}
    `;
export type UpdateProjectMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;
export type UpdateProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectMutation, UpdateProjectMutationVariables>, 'mutation'>;

    export const UpdateProjectComponent = (props: UpdateProjectComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectMutation, UpdateProjectMutationVariables> mutation={UpdateProjectDocument} {...props} />
    );
    
export type UpdateProjectProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateProjectMutation, UpdateProjectMutationVariables> & TChildProps;
export function withUpdateProject<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
  UpdateProjectProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateProjectMutation, UpdateProjectMutationVariables, UpdateProjectProps<TChildProps>>(UpdateProjectDocument, {
      alias: 'updateProject',
      ...operationOptions
    });
};

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, baseOptions);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = ApolloReactCommon.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const GetReleaseNotesDocument = gql`
    query GetReleaseNotes {
  releaseNotes {
    id
    title
    markdownContent
    insertedAt
  }
}
    `;
export type GetReleaseNotesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>, 'query'>;

    export const GetReleaseNotesComponent = (props: GetReleaseNotesComponentProps) => (
      <ApolloReactComponents.Query<GetReleaseNotesQuery, GetReleaseNotesQueryVariables> query={GetReleaseNotesDocument} {...props} />
    );
    
export type GetReleaseNotesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetReleaseNotesQuery, GetReleaseNotesQueryVariables> & TChildProps;
export function withGetReleaseNotes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetReleaseNotesQuery,
  GetReleaseNotesQueryVariables,
  GetReleaseNotesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetReleaseNotesQuery, GetReleaseNotesQueryVariables, GetReleaseNotesProps<TChildProps>>(GetReleaseNotesDocument, {
      alias: 'getReleaseNotes',
      ...operationOptions
    });
};

/**
 * __useGetReleaseNotesQuery__
 *
 * To run a query within a React component, call `useGetReleaseNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleaseNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleaseNotesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReleaseNotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>(GetReleaseNotesDocument, baseOptions);
      }
export function useGetReleaseNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>(GetReleaseNotesDocument, baseOptions);
        }
export type GetReleaseNotesQueryHookResult = ReturnType<typeof useGetReleaseNotesQuery>;
export type GetReleaseNotesLazyQueryHookResult = ReturnType<typeof useGetReleaseNotesLazyQuery>;
export type GetReleaseNotesQueryResult = ApolloReactCommon.QueryResult<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>;
export const GetLatestReleaseNoteDocument = gql`
    query GetLatestReleaseNote {
  releaseNotes(limit: 1) {
    id
    insertedAt
  }
}
    `;
export type GetLatestReleaseNoteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLatestReleaseNoteQuery, GetLatestReleaseNoteQueryVariables>, 'query'>;

    export const GetLatestReleaseNoteComponent = (props: GetLatestReleaseNoteComponentProps) => (
      <ApolloReactComponents.Query<GetLatestReleaseNoteQuery, GetLatestReleaseNoteQueryVariables> query={GetLatestReleaseNoteDocument} {...props} />
    );
    
export type GetLatestReleaseNoteProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLatestReleaseNoteQuery, GetLatestReleaseNoteQueryVariables> & TChildProps;
export function withGetLatestReleaseNote<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLatestReleaseNoteQuery,
  GetLatestReleaseNoteQueryVariables,
  GetLatestReleaseNoteProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLatestReleaseNoteQuery, GetLatestReleaseNoteQueryVariables, GetLatestReleaseNoteProps<TChildProps>>(GetLatestReleaseNoteDocument, {
      alias: 'getLatestReleaseNote',
      ...operationOptions
    });
};

/**
 * __useGetLatestReleaseNoteQuery__
 *
 * To run a query within a React component, call `useGetLatestReleaseNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestReleaseNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestReleaseNoteQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestReleaseNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestReleaseNoteQuery, GetLatestReleaseNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestReleaseNoteQuery, GetLatestReleaseNoteQueryVariables>(GetLatestReleaseNoteDocument, baseOptions);
      }
export function useGetLatestReleaseNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestReleaseNoteQuery, GetLatestReleaseNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestReleaseNoteQuery, GetLatestReleaseNoteQueryVariables>(GetLatestReleaseNoteDocument, baseOptions);
        }
export type GetLatestReleaseNoteQueryHookResult = ReturnType<typeof useGetLatestReleaseNoteQuery>;
export type GetLatestReleaseNoteLazyQueryHookResult = ReturnType<typeof useGetLatestReleaseNoteLazyQuery>;
export type GetLatestReleaseNoteQueryResult = ApolloReactCommon.QueryResult<GetLatestReleaseNoteQuery, GetLatestReleaseNoteQueryVariables>;