"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames10 = _interopRequireDefault(require("classnames"));

var _flexboxgridModule = _interopRequireDefault(require("../../themes/flexboxgrid.module.css"));

var _constants = require("./constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var Row = function Row(props) {
  var children = props.children,
      start = props.start,
      center = props.center,
      end = props.end,
      top = props.top,
      bottom = props.bottom,
      middle = props.middle,
      around = props.around,
      between = props.between,
      reverse = props.reverse,
      className = props.className,
      rest = _objectWithoutProperties(props, ["children", "start", "center", "end", "top", "bottom", "middle", "around", "between", "reverse", "className"]); // Grossness Explained:
  // a dynamic key [xxx] to pull in the the css-modules styles object with a dynamic key [`yyy-${zzz}`]
  // result: [xxx[`yyy-${zzz}`]]


  var classes = (0, _classnames10["default"])(_flexboxgridModule["default"].row, className, _defineProperty({}, _flexboxgridModule["default"]["start-".concat(start)], !!start), _defineProperty({}, _flexboxgridModule["default"]["center-".concat(center)], !!center), _defineProperty({}, _flexboxgridModule["default"]["end-".concat(end)], !!end), _defineProperty({}, _flexboxgridModule["default"]["top-".concat(top)], !!top), _defineProperty({}, _flexboxgridModule["default"]["bottom-".concat(bottom)], !!bottom), _defineProperty({}, _flexboxgridModule["default"]["middle-".concat(middle)], !!middle), _defineProperty({}, _flexboxgridModule["default"]["around-".concat(around)], !!around), _defineProperty({}, _flexboxgridModule["default"]["between-".concat(between)], !!between), _defineProperty({}, _flexboxgridModule["default"].reverse, reverse));
  return _react["default"].createElement("div", _extends({
    className: classes
  }, rest), children);
};

Row.propTypes = {
  children: _propTypes["default"].node.isRequired,
  start: _propTypes["default"].oneOf(_constants.breakpoints),
  center: _propTypes["default"].oneOf(_constants.breakpoints),
  end: _propTypes["default"].oneOf(_constants.breakpoints),
  top: _propTypes["default"].oneOf(_constants.breakpoints),
  bottom: _propTypes["default"].oneOf(_constants.breakpoints),
  middle: _propTypes["default"].oneOf(_constants.breakpoints),
  around: _propTypes["default"].oneOf(_constants.breakpoints),
  between: _propTypes["default"].oneOf(_constants.breakpoints),
  reverse: _propTypes["default"].bool,
  className: _propTypes["default"].string
};
Row.defaultProps = {
  start: null,
  center: null,
  end: null,
  top: null,
  bottom: null,
  middle: null,
  around: null,
  between: null,
  reverse: false,
  className: null
};
var _default = Row;
exports["default"] = _default;