import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import { withAppContext } from "context";
import UserDropdown from "components/UserDropdown";
import withAuth from "components/Auth";
import PropTypes from "prop-types";
import get from "lodash/get";

import logo from "assets/logo.svg";
import Nav from "../../modules/ui-kit-react/lib/components/Internal/Nav";
import style from "./style.module.css";
import ReleaseNotesIndicator from "../ReleaseNotesIndicator";
import Tooltip from "../Tooltip";

class Header extends Component {
  static propTypes = {
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    auth: PropTypes.shape({
      hasRole: PropTypes.func,
      logout: PropTypes.func,
      role: PropTypes.array,
      tenant: PropTypes.string,
      profile: PropTypes.shape({
        username: PropTypes.string,
      }).isRequired,
      token: PropTypes.string,
    }).isRequired,
  };

  state = {
    renderError: false,
  };

  renderDevTools = () => {
    const { auth } = this.props;
    const { renderError } = this.state;

    const error = () => {
      throw new Error("Test Render Error");
    };

    return (
      <span className={style.devTools}>
        <i
          onClick={() => navigator.clipboard.writeText(auth.token)}
          className="material-icons"
          data-tip="Copy JWT"
        >
          file_copy
        </i>
        <i
          onClick={() => this.setState({ renderError: true })}
          className="material-icons"
          data-tip="Trigger Render Error"
        >
          error
        </i>
        {renderError ? error() : null}
      </span>
    );
  };

  renderRightSide = () => {
    const {
      auth: { profile },
      logout,
    } = this.props;

    return (
      <div className={style.rightSide}>
        {process.env.NODE_ENV === "development" ? this.renderDevTools() : null}
        <ReleaseNotesIndicator />
        <UserDropdown user={profile} logout={logout} />
      </div>
    );
  };

  render() {
    const {
      auth: { hasRole, profile },
      history,
      tenant,
    } = this.props;

    const routes = [];
    const superAdmin = get(profile, "superAdmin", false);

    if (tenant) {
      routes.push({ label: "Projects", path: `/${tenant}/projects` });

      if (hasRole("ADMIN")) {
        routes.push({ label: "Admin", path: `/${tenant}/admin` });
      }
    } else {
      routes.push({ label: "Select Tenant", path: `/select-tenant` });
    }

    if (superAdmin) {
      routes.push({ label: "Super Admin", path: `/super-admin` });
    }

    const rightRoutes = [
      {
        label: "Docs",
        path: "https://shield-docs.rvohealth.dev/docs/getting-started/intro.html",
        template: (route) => (
          <a target="_blank" rel="noopener noreferrer" href={route.path}>
            {route.label}
          </a>
        ),
      },
    ];

    if (process.env.NODE_ENV === "development") {
      rightRoutes.push({ label: "GraphiQL", path: "/gql" });
    }

    return (
      <React.Fragment>
        <Nav
          theme={{
            navWrap: style.navWrap,
          }}
          template={(route) => <Link to={route.path}>{route.label}</Link>}
          activePath={history.location.pathname}
          logo={
            <Link to="/" className={style.logoLink}>
              <img
                className={classnames(style.logoIcon)}
                src={logo}
                alt="logo"
                data-cy="logo"
              />
            </Link>
          }
          routes={routes}
          rightRoutes={rightRoutes}
          rightSide={this.renderRightSide()}
          underhang
          fluid={false}
        />
        <Tooltip />
      </React.Fragment>
    );
  }
}

export default withRouter(withAppContext(withAuth(Header)));
