import React, { Component } from 'react';
import { CREATE_REFERENCE, GET_PROJECT } from 'containers/Projects/queries';
import Alert from '../../modules/ui-kit-react/lib/components/Alert';
import PropTypes from 'prop-types';
import ReferenceForm from 'components/ReferenceForm';
import { MutationLoading } from 'components/LoadingPage';
import style from './style.module.css';

class ReferenceInlineAdd extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    project: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  };

  static defaultProps = {
    project: {}
  };

  render() {
    const { onClose, project } = this.props;
    return (
      <div className={style.container}>
        <MutationLoading
          mutation={CREATE_REFERENCE}
          onCompleted={onClose}
          refetchQueries={[
            { query: GET_PROJECT, variables: { id: project.id } }
          ]}
        >
          {(createReference, { error }) => (
            <div data-cy="reference-form">
              {error ? <Alert type="danger" message={error.message} /> : null}
              <div className={style.heading}>New Reference</div>
              <ReferenceForm
                gqlError={error}
                onCancel={onClose}
                onSubmit={form =>
                  createReference({
                    variables: {
                      reference: {
                        name: form.name,
                        projectId: project.id,
                        url: form.url
                      }
                    }
                  })
                }
              />
            </div>
          )}
        </MutationLoading>
      </div>
    );
  }
}

export default ReferenceInlineAdd;
