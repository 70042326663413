import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import TextInput from 'components/Form/TextInput';
import CheckBox from 'components/Form/Checkbox';
import SelectInput from 'components/Form/SelectInput';
import { QueryLoading } from 'components/LoadingPage';
import extractFieldError from 'utils/gqlErrors';
import HelpTip from 'components/HelpTip';
import classnames from 'classnames';
import {
  Button,
  Card,
  CardContent,
  CardHead,
  Row,
  Col
} from '../../modules/ui-kit-react/lib';
import { Form, Validators } from '../../modules/react-forms/lib';
import styles from './style.module.css';
import ProviderSelection from '../ProviderSelection';
import Protected from '../Protected';
import SlackAlerts from '../SlackAlerts';
import SlackReminders from '../SlackReminders';
import WebHooks from '../WebHooks';

class EnvForm extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    environment: PropTypes.shape({
      autoPromote: PropTypes.bool,
      image: PropTypes.string,
      name: PropTypes.string,
      tagPattern: PropTypes.string,
      privileged: PropTypes.bool,
      url: PropTypes.string
    }),
    edit: PropTypes.bool,
    projectId: PropTypes.string,
    gqlError: PropTypes.shape({
      graphQLErrors: PropTypes.array
    }),
    match: PropTypes.shape({
      params: PropTypes.object
    }).isRequired,
    providerConfigError: PropTypes.bool
  };

  static defaultProps = {
    environment: {},
    projectId: null,
    edit: false,
    loading: false,
    gqlError: null,
    providerConfigError: false
  };

  state = {
    providerConfig: false
  };

  componentDidMount() {
    const { environment } = this.props; 
  }

  cancel = () => {
    const {
      history,
      projectId,
      match: {
        params: { tenant }
      }
    } = this.props;

    history.push(`/${tenant}/projects/${projectId}`);
  };

  getError = field => {
    const { gqlError } = this.props;

    return extractFieldError(gqlError, field);
  };

  render() {
    const {
      onSubmit,
      loading,
      environment,
      edit,
      gqlError,
      providerConfigError
    } = this.props;
    const { providerConfig } = this.state;
    if (loading) return 'Loading...';

    return (
      <Form onValidSubmit={onSubmit}>
        <Row>
          <Col sm={5}>
            <Card
              className={classnames({ [styles.readonly]: providerConfigError })}
            >
              <CardHead>Environment Details</CardHead>

              <CardContent>
                <TextInput
                  name="name"
                  label="Name"
                  placeholder="e.g. Production"
                  defaultValue={environment.name}
                  error={this.getError('name')}
                  required
                  disabled={providerConfigError}
                  standAlone={providerConfigError}
                />
                <TextInput
                  name="url"
                  label="Url"
                  placeholder="e.g. https://shield.creditcards.com"
                  defaultValue={environment.url}
                  validation={{
                    'Must provide protocol: http or https': Validators.isURL({
                      require_protocol: true,
                      protocols: ['http', 'https']
                    })
                  }}
                  error={this.getError('url')}
                  disabled={providerConfigError}
                  standAlone={providerConfigError}
                />
                <CheckBox
                  name="privileged"
                  label={
                    <span>
                      Protected Environment&nbsp;
                      <HelpTip text="Only allows those with the deployer role to deploy to this environment." />
                    </span>
                  }
                  defaultValue={edit ? environment.privileged : true}
                  error={this.getError('privileged')}
                  disabled={providerConfigError}
                  standAlone={providerConfigError}
                />
                <TextInput
                  name="tagPattern"
                  label="Tag Filter Pattern"
                  placeholder="*"
                  defaultValue={environment.tagPattern}
                  error={this.getError('tagPattern')}
                  disabled={providerConfigError || environment.privileged}
                  standAlone={providerConfigError}
                  hint="If provided, will be used to filter the tags shown for deployment. Setting this is disabled in protected environments."
                />
              </CardContent>
            </Card>

            {!edit ? (
              <Protected hasRole="ADMIN">
                <Card>
                  <CardHead>
                    <CheckBox
                      className={styles.checkbox}
                      standAlone
                      label={
                        <span>
                          Setup Deployment Configuration&nbsp;
                          <HelpTip text="You can setup your deployment configuration now, or after the environment is created" />
                        </span>
                      }
                      onChange={v => this.setState({ providerConfig: v })}
                      value={providerConfig}
                    />
                  </CardHead>

                  {providerConfig ? (
                    <CardContent>
                      <ProviderSelection gqlError={gqlError} />
                    </CardContent>
                  ) : null}
                </Card>
              </Protected>
            ) : null}
          </Col>
          <Col sm={7}>
            <Card
              className={classnames({ [styles.readonly]: providerConfigError })}
            >
              <CardHead>Automation</CardHead>

              <CardContent>
                <h4>Continuous Delivery</h4>

                <CheckBox
                  name="autoPromote"
                  label={
                    <React.Fragment>
                      Automatically promote successful builds &nbsp;
                      <HelpTip text="This requires further integration with your CI provider. Please check out the auto promote docs for more information." />
                    </React.Fragment>
                  }
                  defaultValue={edit ? environment.autoPromote : false}
                  error={this.getError('autoPromote')}
                  disabled={providerConfigError}
                  standAlone={providerConfigError}
                />
                {edit ? <SlackAlerts environment={environment} /> : null}
                {edit ? <WebHooks environment={environment} /> : null}
                {edit ? <SlackReminders environment={environment} /> : null}
              </CardContent>
            </Card>
          </Col>
        </Row>

        <div className={styles.actions}>
          <Button
            name="save"
            disabled={loading}
            raised
            appearance="primary"
            type="submit"
          >
            {edit ? 'Update' : 'Create'}
          </Button>

          <Button
            name="cancel"
            disabled={loading}
            lowered
            appearance="danger"
            onClick={this.cancel}
          >
            Cancel
          </Button>
        </div>
      </Form>
    );
  }
}

export default withRouter(EnvForm);
