import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Protected from 'components/Protected';
import SlackAlertEdit from 'components/SlackAlertInlineEdit';
import SlackAlertAdd from 'components/SlackAlertInlineAdd';
import { makeButton } from 'components/DeleteButton';
import { GET_ENV, DELETE_SLACK_ALERT } from 'containers/Environments/queries';
import { capitalize } from 'lodash';
import { Chip } from '../../modules/ui-kit-react/lib';
import style from './style.module.css';

const DeleteSlackAlertButton = makeButton(DELETE_SLACK_ALERT);

class SlackAlerts extends Component {
  static propTypes = {
    environment: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slackAlerts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          channel: PropTypes.string,
          on: PropTypes.arrayOf(PropTypes.string)
        })
      )
    })
  };

  static defaultProps = {
    environment: {}
  };

  state = {
    editingAlertId: null,
    alertFormActive: false
  };

  render() {
    const { environment } = this.props;
    const { alertFormActive, editingAlertId } = this.state;

    return (
      <React.Fragment>
        <h4>Slack Notifications</h4>
        {environment.slackAlerts
          .filter(({ channel }) => channel && !channel.startsWith('@'))
          .map(slackAlert => (
            <React.Fragment key={slackAlert.id}>
              <div className={style.alert}>
                <div className={style.alertChannel}>
                  <div className={style.alertChannelName}>
                    <i className="material-icons">notifications</i>
                    <span>{slackAlert.channel}</span>
                  </div>
                  <Protected hasRole="USER">
                    <div className={style.actions}>
                      <i
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ editingAlertId: slackAlert.id });
                        }}
                        className={classnames('material-icons', style.edit)}
                        data-cy="edit-slack-notification-btn"
                      >
                        edit
                      </i>
                      <DeleteSlackAlertButton
                        buttonProps={{
                          'data-cy': 'delete-slack-notification-btn'
                        }}
                        variables={{ id: slackAlert.id }}
                        refetchQueries={[
                          { query: GET_ENV, variables: { id: environment.id } }
                        ]}
                      />
                    </div>
                  </Protected>
                  <div className={style.on}>
                    {slackAlert.on.map(status => (
                      <Chip>{capitalize(status)}</Chip>
                    ))}
                  </div>
                </div>
              </div>
              {editingAlertId === slackAlert.id ? (
                <SlackAlertEdit
                  onClose={() => this.setState({ editingAlertId: null })}
                  alert={slackAlert}
                />
              ) : null}
            </React.Fragment>
          ))}

        {alertFormActive ? (
          <SlackAlertAdd
            environmentId={environment.id}
            onClose={() => this.setState({ alertFormActive: false })}
          />
        ) : (
          <Protected hasRole="USER">
            <div
              onClick={() => this.setState({ alertFormActive: true })}
              className={style.add}
            >
              <i className="material-icons">add</i>
              <span data-cy="add-channel-btn">Add Channel</span>
            </div>
          </Protected>
        )}
      </React.Fragment>
    );
  }
}

export default SlackAlerts;
