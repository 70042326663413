import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import PropTypes from 'prop-types';
import withAuth from 'components/Auth';
import { Button } from '../../modules/ui-kit-react/lib';
import style from './style.module.css';
import {
  CREATE_SLACK_ALERT,
  DELETE_SLACK_ALERT,
  GET_ENV
} from '../../containers/Environments/queries';

class SlackAlertSubscribe extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      profile: PropTypes.shape({
        slackId: PropTypes.string
      })
    }).isRequired,
    environment: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slackAlerts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          channel: PropTypes.string,
          on: PropTypes.arrayOf(PropTypes.string)
        })
      )
    }).isRequired
  };

  state = {
    showSlackLoginMsg: false
  };

  togglePersonalSlackAlert = ({
    existingAlert,
    profile,
    mutation,
    environment
  }) => {
    if (!profile.slackId) return this.setState({ showSlackLoginMsg: true });

    if (existingAlert) {
      // mutation = DELETE_SLACK_ALERT
      return mutation({
        variables: {
          id: existingAlert.id
        }
      });
    }

    // mutation = CREATE_SLACK_ALERT
    return mutation({
      variables: {
        slackAlert: {
          channel: `@${profile.slackId}`,
          on: [
            'ERRORED',
            'FAILED',
            'COMPLETED',
            'STARTED',
            'PENDING',
            'CANCELED',
            'CANCELING'
          ],
          environmentId: environment.id
        }
      }
    });
  };

  renderError = message => (
    <div className={style.error}>
      <i className="material-icons">error_outline</i>
      <span>{message}</span>
    </div>
  );

  render() {
    const {
      auth: { profile },
      environment
    } = this.props;
    const { showSlackLoginMsg } = this.state;

    const existingAlert = environment.slackAlerts.find(
      alert => alert.channel === `@${profile.slackId}`
    );

    return (
      <div className={style.wrap}>
        {/* <label className={style.label}>Slack Notifications</label> */}
        {/* <label className={style.subLabel}>@you</label> */}
        {showSlackLoginMsg
          ? this.renderError(
              "Your Slack account has not been linked to Shield. Type '/shield login' in any slack channel."
            )
          : null}
        <Mutation
          mutation={existingAlert ? DELETE_SLACK_ALERT : CREATE_SLACK_ALERT}
          refetchQueries={[
            { query: GET_ENV, variables: { id: environment.id } }
          ]}
        >
          {(mutation, { loading, error }) => (
            <React.Fragment>
              {error ? this.renderError(error.message) : null}
              <Button
                data-cy="slack-notification-sub-btn"
                disabled={loading}
                raised
                appearance={existingAlert ? 'success' : 'primary'}
                onClick={() =>
                  this.togglePersonalSlackAlert({
                    existingAlert,
                    profile,
                    mutation,
                    environment
                  })
                }
                icon={existingAlert ? 'notifications' : 'notifications_off'}
              >
                {existingAlert
                  ? 'Unsubscribe to Slack Notifications'
                  : 'Subscribe via Slack'}
              </Button>
            </React.Fragment>
          )}
        </Mutation>
      </div>
    );
  }
}

export default withAuth(SlackAlertSubscribe);
