"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames2 = _interopRequireDefault(require("classnames"));

var _ThemeProvider = require("../../ThemeProvider");

var _ListItemContent = _interopRequireDefault(require("./ListItemContent.js"));

var _ListItemActions = _interopRequireDefault(require("./ListItemActions.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ListItemLayout = function ListItemLayout(props) {
  var _classnames;

  var className = (0, _classnames2["default"])(props.theme.item, (_classnames = {}, _defineProperty(_classnames, props.theme.disabled, props.disabled), _defineProperty(_classnames, props.theme.selectable, props.selectable), _defineProperty(_classnames, props.theme.selected, props.selected), _classnames), props.className);

  var leftActions = _toConsumableArray(props.leftActions);

  var rightActions = _toConsumableArray(props.rightActions);

  var content = props.itemContent || _react["default"].createElement(_ListItemContent["default"], {
    theme: props.theme,
    caption: props.caption,
    legend: props.legend
  });

  var emptyActions = function emptyActions(item) {
    return !item[0] && !item[1] && !item[2];
  };

  return _react["default"].createElement("span", {
    className: className
  }, !emptyActions(leftActions) > 0 && _react["default"].createElement(_ListItemActions["default"], {
    type: "left",
    theme: props.theme
  }, leftActions), content, !emptyActions(rightActions) > 0 && _react["default"].createElement(_ListItemActions["default"], {
    type: "right",
    theme: props.theme
  }, rightActions));
};

ListItemLayout.propTypes = {
  avatar: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element]),
  caption: _propTypes["default"].string,
  children: _propTypes["default"].any,
  className: _propTypes["default"].string,
  disabled: _propTypes["default"].bool,
  itemContent: _propTypes["default"].element,
  leftActions: _propTypes["default"].array,
  leftIcon: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element]),
  legend: _propTypes["default"].string,
  rightActions: _propTypes["default"].array,
  rightIcon: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element]),
  selectable: _propTypes["default"].bool,
  selected: _propTypes["default"].bool,
  theme: _propTypes["default"].shape({
    disabled: _propTypes["default"].string,
    item: _propTypes["default"].string,
    selectable: _propTypes["default"].string
  }),
  to: _propTypes["default"].string
};
ListItemLayout.defaultProps = {
  disabled: false,
  selectable: false,
  selected: false
};

var _default = (0, _ThemeProvider.applyTheme)('List', ListItemLayout);

exports["default"] = _default;