import React, { Component } from 'react';
import InfraDiagram from 'components/InfraDiagram';
import LoadingPage from 'components/LoadingPage';
import { GET_ENV_SERVICE_DETAILS } from 'containers/Environments/queries';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';
import { Alert, Table } from '../../modules/ui-kit-react/lib';
import style from './style.module.css';

class TaskDefinitionDetails extends Component {
  static propTypes = {
    data: PropTypes.shape({
      environment: PropTypes.shape({
        providerConfiguration: PropTypes.shape({
          serviceDetails: PropTypes.object,
          taskDefinitionDetails: PropTypes.shape({
            environment: PropTypes.arrayOf(PropTypes.object)
          })
        })
      }),
      error: PropTypes.shape({
        message: PropTypes.string
      }),
      loading: PropTypes.bool,
      serviceDetails: PropTypes.object,
      taskDefinitionDetails: PropTypes.object
    }).isRequired
  };

  render() {
    const { data } = this.props;

    if (data.loading) {
      return <LoadingPage />;
    }

    if (data.error) return <Alert type="danger" message={data.error.message} />;

    const {
      environment: {
        providerConfiguration: {
          serviceDetails,
          taskDefinitionDetails: { environment }
        }
      }
    } = data;

    const valueTemplate = ({ value, sensitive }) => {
      if (!sensitive) {
        return value;
      }

      if (!value) {
        return <i className="material-icons">lock</i>;
      }

      return (
        <span className={style.sensitiveWrap}>
          <i className={`material-icons ${style.sensitiveIcon}`}>lock</i>
          &nbsp;
          {value}
        </span>
      );
    };

    return (
      <div>
        <div>
          <h2>Infrastructure Diagram</h2>
          <InfraDiagram serviceDetails={serviceDetails} />
        </div>
        <div className={style.envVars}>
          <h2>Environment Variables</h2>
          <Table
            paginationSize={20}
            data={environment}
            columns={[
              {
                heading: 'Key',
                path: 'key'
              },
              {
                heading: 'Value',
                path: 'value',
                template: valueTemplate
              }
            ]}
          />
        </div>
      </div>
    );
  }
}

export default graphql(GET_ENV_SERVICE_DETAILS, {
  options: ({ environmentId }) => ({
    variables: {
      id: environmentId
    },
    fetchPolicy: 'network-only'
  })
})(TaskDefinitionDetails);
