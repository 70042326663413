import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/react-components';
import { CANCEL_DEPLOYMENT } from 'containers/Environments/queries';
import { Button } from '../../modules/ui-kit-react/lib';
import Protected from '../Protected';
import style from './style.module.css';

export default class CancelButton extends Component {
  static propTypes = {
    deployment: PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string
    }).isRequired,
    environment: PropTypes.shape({
      privileged: PropTypes.bool,
      providerConfiguration: PropTypes.object
    }).isRequired
  };

  supportsCancelation = () => {
    const { environment } = this.props;
    return environment.providerConfiguration?.__typename === 'EcsConfiguration';
  };

  render() {
    const { deployment, privileged } = this.props;

    if (deployment.status !== 'STARTED' || !this.supportsCancelation()) {
      return null;
    }

    const buttonClass = classnames(
      style.status,
      style.danger,
      style.cancelButton
    );

    return (
      <Protected hasRole={privileged ? 'DEPLOYER' : 'USER'}>
        <Mutation
          mutation={CANCEL_DEPLOYMENT}
          variables={{ id: deployment.id }}
        >
          {(mutate, { loading }) => (
            <Button
              className={buttonClass}
              appearance="danger"
              disabled={loading}
              onClick={() => mutate()}
              small
              lowered
              icon="close"
            >
              Cancel
            </Button>
          )}
        </Mutation>
      </Protected>
    );
  }
}
