"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ThemeProvider = require("../../ThemeProvider");

var _ListItemAction = _interopRequireDefault(require("./ListItemAction.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ListItemActions = function ListItemActions(_ref) {
  var type = _ref.type,
      children = _ref.children,
      theme = _ref.theme;

  var validChildren = _react["default"].Children.toArray(children).filter(function (c) {
    return _react["default"].isValidElement(c);
  });

  return _react["default"].createElement("span", {
    className: theme[type]
  }, validChildren.map(function (action, i) {
    return _react["default"].createElement(_ListItemAction["default"], {
      key: i,
      theme: theme,
      action: action
    });
  }));
};

ListItemActions.propTypes = {
  children: _propTypes["default"].any,
  theme: _propTypes["default"].shape({
    left: _propTypes["default"].string,
    right: _propTypes["default"].string
  }),
  type: _propTypes["default"].oneOf(['left', 'right'])
};

var _default = (0, _ThemeProvider.applyTheme)('List', ListItemActions);

exports["default"] = _default;