import React, { useEffect } from 'react';
import { useAppContext } from 'context';
import { QueryLoading } from '../LoadingPage';
import { GET_PROFILE } from '../../containers/App/queries';
import { useAuth } from './AuthContext';

const ContextUpdateWrapper = ({ profileData }) => {
  const { updateProfile } = useAuth();
  const { setTenant } = useAppContext();

  useEffect(() => {
    if (profileData) {
      updateProfile(profileData);
      setTenant(profileData.currentTenant);
    }
  }, [profileData, updateProfile, setTenant]);

  return null;
};


const ContextComposer = ({ children }) => (
    <QueryLoading query={GET_PROFILE}>
      {({ data }) => {
        const profileData = data ? data.profile : null;

        return (
          <>
            {profileData && <ContextUpdateWrapper profileData={profileData} />}
            {children}
          </>
        );
      }}
    </QueryLoading>
  );

export default ContextComposer;
