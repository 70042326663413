"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getViewport = void 0;

// eslint-disable-next-line
var getViewport = function getViewport() {
  return {
    height: window.innerHeight || document.documentElement.offsetHeight,
    width: window.innerWidth || document.documentElement.offsetWidth
  };
};

exports.getViewport = getViewport;