import React, { FC } from 'react';
import Button from '../../../modules/ui-kit-react/lib/components/Button/index';

import TagSelector from '../../TagSelector';
import style from '../style.module.css';

type Props = {
  environment: {
    id: string;
    lastDeployment?: {
      artifactName?: string;
    }
  };
  project: {
    id: string;
    repository: string;
  };
  changePane: (s: string) => void
};

const CompareTool: FC<Props> = ({ environment, project, changePane }) => (
  <>
    <TagSelector
      currentTag={environment?.lastDeployment?.artifactName || ''}
      projectId={project.id}
      environmentId={environment.id}
      repoUrl={project.repository}
    />

    <div className={style.foot}>
      <Button
        raised
        appearance="primary"
        onClick={() => changePane('latest-deployment')}
      >
        Back
      </Button>
    </div>
  </>
);

export default CompareTool;
