import gql from 'graphql-tag';

export const GET_AWS_PROFILES = gql`
  query {
    awsProfiles {
      id
      name
      roleArn
      ecsClusters {
        id
        name
      }
    }
  }
`;

export const GET_AWS_PROFILE = gql`
  query($id: ID!) {
    awsProfile(id: $id) {
      id
      name
      roleArn
      ecsClusters {
        id
        name
        region
      }
    }
  }
`;

export const CREATE_AWS_PROFILE = gql`
  mutation($profile: CreateAwsProfileInput!) {
    createAwsProfile(input: $profile) {
      id
      name
      roleArn
    }
  }
`;

export const UPDATE_AWS_PROFILE = gql`
  mutation($id: ID!, $profile: UpdateAwsProfileInput!) {
    updateAwsProfile(id: $id, input: $profile) {
      id
      name
      roleArn
    }
  }
`;

export const DELETE_AWS_PROFILE = gql`
  mutation($id: ID!) {
    deleteAwsProfile(id: $id)
  }
`;

export const IMPORT_ECS_CLUSTERS = gql`
  mutation($id: ID!) {
    importEcsClusters(profileId: $id) {
      id
      name
      region

      awsProfile {
        id
        name
        roleArn
      }
    }
  }
`;

export const DELETE_ECS_CLUSTER = gql`
  mutation($id: ID!) {
    deleteEcsCluster(id: $id)
  }
`;

export const ARCHIVE_USER = gql`
  mutation($id: ID!) {
    archiveUser(id: $id) {
      id
    }
  }
`;

export const GET_FEATURES = gql`
  query {
    tenantFeatures {
      key
      value
    }
  }
`;
