import React, { useState, useEffect, useCallback } from 'react';
import config from 'config';
import { withAppContext } from 'context';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { usePKCE } from './usePKCE';
import { AuthContext } from './AuthContext';

const AuthProvider = ({ children, setToken, token, history }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profile, setProfileData] = useState({});
  const url = usePKCE();

  const updateProfile = (data) => setProfileData(data);

  const hasRole = (role) => get(profile, 'roles', []).includes(role)

  const checkAuthentication = useCallback(() => {
    const tokenInLs = localStorage.getItem('access_token');
    if (tokenInLs) {
      setIsAuthenticated(true);
      setToken(tokenInLs);
    }
    
    setLoading(false);
  }, []);

  const handleAuthentication = useCallback(async (code) => {
    const codeVerifier = localStorage.getItem('pkce_code_verifier');
    if (!code || !codeVerifier) {
      console.error('Code or code verifier is missing.');
      setLoading(false);
      return;
    }

    const tokenUrl = config.AUTH.TOKEN_URL;
    const body = new URLSearchParams({
      client_id: config.AUTH.CLIENT_ID,
      code,
      redirect_uri: config.AUTH.REDIRECT_URI,
      grant_type: 'authorization_code',
      code_verifier: codeVerifier,
    });

    try {
      const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body.toString(),
      });
      const data = await response.json();
      const { access_token: accessToken } = data;

      if (accessToken) {
        setIsAuthenticated(true);
        setToken(accessToken);
        localStorage.setItem('access_token', accessToken);
        localStorage.removeItem('pkce_code_verifier');
        // TODO: Implement selecting previous tenant as a follow-up
        history.push('/select-tenant');
      } else {
        console.error('Failed to retrieve access token');
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');

    if (currentPath.startsWith('/oauth/callback') && code) {
      handleAuthentication(code);
    } else {
      checkAuthentication();
    }
  }, [handleAuthentication, checkAuthentication]);

  const login = useCallback(() => {
    window.location.href = url;
  }, [url]);

  useEffect(() => {
    if (!isAuthenticated && !loading) {
      login();
    }
  }, [isAuthenticated, loading, login]);

  // Extract the current tenant from profile.currentTenant and hoist it to the context
  const tenant = get(profile, 'currentTenant', null);

  const contextValue = {
    token,
    profile,
    hasRole,
    updateProfile,
    tenant,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default withRouter(withAppContext(AuthProvider));
