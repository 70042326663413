// Defaults (production)
const config = {
  API: {
    BASE_URL: '',
    WS_URL: ''
  },
  AUTH: {
    CLIENT_ID: 'b276faf6-e3c1-41f6-b258-80001af4cf83',
    AUTHORIZATION_URL: 'https://login.microsoftonline.com/00e1df3d-9626-410c-898c-16aaa8c2afc9/oauth2/v2.0/authorize',
    TOKEN_URL: 'https://login.microsoftonline.com/00e1df3d-9626-410c-898c-16aaa8c2afc9/oauth2/v2.0/token ',
    SCOPE: 'b276faf6-e3c1-41f6-b258-80001af4cf83/.default',
    REDIRECT_URI: `${window.location.origin}/oauth/callback`
  },
  LAUNCH_DARKLY: {
    CLIENT_ID: '5cb10240978a2f0822feb99e'
  },
  TENANT: 'rv_atx' // NOTE: I'm a hack too!!
};

if (process.env.NODE_ENV === 'development') {
  // Dev-specific values

  config.AUTH.REDIRECT_URI = 'http://localhost:3000/oauth/callback';
  config.API.BASE_URL = 'https://shield-nonprod.rvohealth.com';
  config.API.WS_URL = 'ws://localhost:4000';

  config.LAUNCH_DARKLY.CLIENT_ID = '5cb10240978a2f0822feb99d';

  // NOTE: Im a hack!!
  config.TENANT = 'dev';
}

if (process.env.REACT_APP_CYPRESS != null) {
  config.API.BASE_URL = 'https://shield-qa.ccards.io';
  config.API.WS_URL = 'ws://shield-qa.ccards.io';
  config.TENANT = 'dev';
}

export default config;
