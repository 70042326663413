import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import PropTypes from "prop-types";
import Pagination from "components/Pagination";
import style from "./style.module.css";
import {
  DEPLOYMENT_UPDATE_SUBSCRIPTION,
  NEW_DEPLOYMENT_SUBSCRIPTION,
} from "../../containers/Environments/queries";
import Deployment from "./Deployment";

const newDeployment = ({ id }) => ({
  document: NEW_DEPLOYMENT_SUBSCRIPTION,
  variables: { environmentId: id },
  updateQuery: (prev, { subscriptionData }) => {
    if (!subscriptionData.data) return prev;

    prev.environment.deployments.entries.unshift(
      subscriptionData.data.newDeployments
    );

    return prev;
  },
});

const deploymentUpdate = ({ id }) => ({
  document: DEPLOYMENT_UPDATE_SUBSCRIPTION,
  variables: { id },
  updateQuery: (prev, { subscriptionData }) => {
    if (!subscriptionData.data) return prev;

    const updatedDeployment = subscriptionData.data.updateDeployment;

    const deployment = prev.environment.deployments.entries.find(
      (e) => e.id === updatedDeployment.id
    );

    deployment.status = updatedDeployment.status;

    return prev;
  },
});

class DeploymentList extends Component {
  subbedDeploymentId = null;

  static propTypes = {
    subscribeToMore: PropTypes.func,
    environment: PropTypes.shape({
      project: PropTypes.object,
      privileged: PropTypes.bool,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,
    }).isRequired,
    updatePage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    subscribeToMore: () => {
      // do nothing
    },
  };

  componentDidMount = () => {
    const { subscribeToMore, environment } = this.props;

    this.newDeploymentUnsub = subscribeToMore(newDeployment(environment));
  };

  componentWillUnmount = () => {
    this.newDeploymentUnsub();
  };

  // eslint-disable-next-line no-confusing-arrow
  renderDeployments = ({ environment, deployments }) =>
    deployments.map((deployment) => (
      <Deployment
        key={deployment.id}
        deployment={deployment}
        environment={environment}
      />
    ));

  render() {
    const { subscribeToMore, environment, updatePage } = this.props;
    const deployments = get(environment, "deployments.entries", []);

    if (deployments.length) {
      // should we unsub to the prev sub
      if (
        this.subbedDeploymentId &&
        this.subbedDeploymentId !== deployments[0].id
      ) {
        this.statusUnsubscribe();
      }

      this.subbedDeploymentId = deployments[0].id;
      this.statusUnsubscribe = subscribeToMore(
        deploymentUpdate(deployments[0])
      );
    }

    const renderPagination = () => (
      <Pagination
        settings={{
          total: get(environment, "deployments.pagination.totalEntries", 0),
          pageSize: get(environment, "deployments.pagination.pageSize", 0),
          currentPage: get(environment, "deployments.pagination.pageNumber", 0),
        }}
        nextPage={updatePage}
      />
    );

    return (
      <React.Fragment>
        <div className={style.heading}>
          <div>
            <h2>Deployment History</h2>
          </div>
          <div>{renderPagination()}</div>
        </div>
        {deployments.length ? (
          <ul className={style.log}>
            {this.renderDeployments({ deployments, environment })}
          </ul>
        ) : (
          <div className={style.noDeployments}>No Deployments</div>
        )}
        {renderPagination()}
      </React.Fragment>
    );
  }
}

export default withRouter(DeploymentList);
