/* eslint-disable react/jsx-one-expression-per-line */

import React, { FC, useState } from 'react';
import classnames from 'classnames';
import { Button } from '../../../modules/ui-kit-react/lib/index';
import ProviderWarning, {
  ProviderTypeShortname
} from 'components/ProviderWarning';
import useDisplayedProviderWarning, {
  ProviderType
} from 'components/ProviderSelection/useDisplayedProviderWarning';
import boxStyles from '../style.module.css';
import styles from '../ProviderForm/style.module.css';

interface ComponentProps {
  onSelect: (type: ProviderType) => void;
  onCancel: () => void;
}

interface ConfirmationProps {
  type: ProviderType;
  onConfirm: () => void;
  onCancel: () => void;
}

const providerTypeToShortname = (type: ProviderType): ProviderTypeShortname => {
  switch (type) {
    case 'S3Configuration':
      return 's3';
    default:
      return 'lambda';
  }
};

const ProviderWarningConfirmation: FC<ConfirmationProps> = ({
  type,
  onConfirm,
  onCancel
}: ConfirmationProps) => {
  const shortname = providerTypeToShortname(type);
  const [displayed, setDisplayed] = useDisplayedProviderWarning(type);

  if (displayed) {
    onConfirm();

    return null;
  }

  return (
    <>
      <div className={boxStyles.content}>
        <ProviderWarning type={shortname} />
      </div>

      <div className={boxStyles.foot}>
        <Button appearance="primary" raised onClick={() => setDisplayed()}>
          Continue
        </Button>

        <Button appearance="danger" raised onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};

const Component: FC<ComponentProps> = ({
  onSelect,
  onCancel
}: ComponentProps) => {
  const [
    showConfirmationFor,
    setShowConfirmationFor
  ] = useState<ProviderType | null>();

  if (showConfirmationFor) {
    return (
      <ProviderWarningConfirmation
        type={showConfirmationFor}
        onConfirm={() => onSelect(showConfirmationFor)}
        onCancel={onCancel}
      />
    );
  }

  return (
    <>
      <div>
        <div className={classnames(boxStyles.content, styles.selectText)}>
          Select Deployment Type
        </div>

        <ul className={styles.configurationTypes} data-cy="provider-selection">
          <li>
            <div>ECS</div>

            <Button
              appearance="primary"
              onClick={() => onSelect('EcsConfiguration')}
              small
              raised
              data-cy="ecs"
            >
              Select
            </Button>
          </li>

          <li>
            <div>ECS Scheduled Task</div>

            <Button
              onClick={() => onSelect('EcsScheduledTaskConfiguration')}
              appearance="primary"
              raised
              small
              data-cy="ecs-scheduled"
            >
              Select
            </Button>
          </li>

          <li>
            <div>Lambda</div>

            <Button
              appearance="primary"
              onClick={() => setShowConfirmationFor('BasicLambdaConfiguration')}
              small
              raised
              data-cy="basic-lambda"
            >
              Select
            </Button>
          </li>

          <li>
            <div>S3</div>

            <Button
              onClick={() => setShowConfirmationFor('S3Configuration')}
              appearance="primary"
              raised
              small
              data-cy="s3"
            >
              Select
            </Button>
          </li>
          <li>
            <div>CodeBuild</div>

            <Button
              onClick={() => onSelect('CodebuildConfiguration')}
              appearance="primary"
              raised
              small
              data-cy="codebuild"
            >
              Select
            </Button>
          </li>
        </ul>
      </div>

      <div className={boxStyles.foot}>
        <Button appearance="danger" raised onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};

export default Component;
