import gql from 'graphql-tag';

export const GET_ENV = gql`
  query($id: ID!, $page: Int) {
    environment(id: $id) {
      id
      name
      privileged
      tagPattern

      project {
        id
        repository
        name
        slug
        description
      }

      slackAlerts {
        channel
        on
        id
      }

      slackReminders {
        id
        channel
      }

      webHooks {
        url
        on
        id

        awsProfile {
          id
        }
      }

      deployments(page: $page, pageSize: 10) {
        pagination {
          pageSize
          pageNumber
          totalEntries
        }

        entries {
          id
          ref
          insertedAt
          status
          artifactName
          autoPromoted

          deployer {
            id
            displayName
            username
          }
        }
      }

      providerConfiguration {
        __typename

        ... on BasicLambdaConfiguration {
          id
        }

        ... on EcsConfiguration {
          id
        }

        ... on S3Configuration {
          id
        }

        ... on EcsScheduledTaskConfiguration {
          id
        }

        ... on CodebuildConfiguration {
          id
        }
      }
    }
  }
`;

export const GET_ENV_PROVIDER_CONFIG = gql`
  query($id: ID!) {
    environment(id: $id) {
      id
      providerConfiguration {
        __typename

        ... on BasicLambdaConfiguration {
          id
          artifactBucket
          functionName
          image
          region
          type
          awsProfile {
            id
          }
        }

        ... on EcsConfiguration {
          id
          image
          service
          timeout
          cluster {
            id
            name
            awsProfile {
              id
              name
            }
          }
        }

        ... on S3Configuration {
          artifactBucket
          awsProfile {
            name
            id
          }
          deployBucket
          id
          region
          deleteOldFiles
          distributionId
        }

        ... on EcsScheduledTaskConfiguration {
          awsProfile {
            name
            id
          }
          id
          image
          region
          taskFamily
        }

        ... on CodebuildConfiguration {
          awsProfile {
            name
            id
          }
          id
          projectName
          region
          timeout
          specifySourceVersion
        }
      }
    }
  }
`;

export const GET_DEPLOYMENT = gql`
  query($id: ID!) {
    deployment(id: $id) {
      environment {
        id
        name
        project {
          id
          name
        }
      }
      id
      artifactName
      ref
      status
      insertedAt
      updatedAt
      logEntries {
        timestamp
        level
        message
      }
    }
  }
`;

export const CANCEL_DEPLOYMENT = gql`
  mutation($id: ID!) {
    cancelDeployment(id: $id)
  }
`;

export const CREATE_BASIC_LAMBDA_CONFIGURATION = gql`
  mutation(
    $environmentId: ID!
    $configuration: CreateBasicLambdaConfigurationInput!
  ) {
    createBasicLambdaConfiguration(
      environmentId: $environmentId
      input: $configuration
    ) {
      id
    }
  }
`;

export const UPDATE_BASIC_LAMBDA_CONFIGURATION = gql`
  mutation(
    $environmentId: ID!
    $configuration: UpdateBasicLambdaConfigurationInput!
  ) {
    updateBasicLambdaConfiguration(
      environmentId: $environmentId
      input: $configuration
    ) {
      id
    }
  }
`;

export const CREATE_ECS_CONFIGURATION = gql`
  mutation($environmentId: ID!, $configuration: CreateEcsConfigurationInput!) {
    createEcsConfiguration(
      environmentId: $environmentId
      input: $configuration
    ) {
      id
    }
  }
`;

export const CREATE_ECS_SCHEDULED_TASK_CONFIGURATION = gql`
  mutation(
    $environmentId: ID!
    $configuration: CreateEcsScheduledTaskConfigurationInput!
  ) {
    createEcsScheduledTaskConfiguration(
      environmentId: $environmentId
      input: $configuration
    ) {
      id
    }
  }
`;

export const CREATE_S3_CONFIGURATION = gql`
  mutation($environmentId: ID!, $configuration: CreateS3ConfigurationInput!) {
    createS3Configuration(
      environmentId: $environmentId
      input: $configuration
    ) {
      id
    }
  }
`;

export const UPDATE_S3_CONFIGURATION = gql`
  mutation($environmentId: ID!, $configuration: UpdateS3ConfigurationInput!) {
    updateS3Configuration(
      environmentId: $environmentId
      input: $configuration
    ) {
      id
    }
  }
`;

export const CREATE_CODEBUILD_CONFIGURATION = gql`
  mutation(
    $environmentId: ID!
    $configuration: CreateCodebuildConfigurationInput!
  ) {
    createCodebuildConfiguration(
      environmentId: $environmentId
      input: $configuration
    ) {
      id
    }
  }
`;

export const UPDATE_CODEBUILD_CONFIGURATION = gql`
  mutation(
    $environmentId: ID!
    $configuration: UpdateCodebuildConfigurationInput!
  ) {
    updateCodebuildConfiguration(
      environmentId: $environmentId
      input: $configuration
    ) {
      id
    }
  }
`;

export const UPDATE_ECS_CONFIGURATION = gql`
  mutation($environmentId: ID!, $configuration: UpdateEcsConfigurationInput!) {
    updateEcsConfiguration(
      environmentId: $environmentId
      input: $configuration
    ) {
      id
    }
  }
`;

export const UPDATE_ECS_SCHEDULED_TASK_CONFIGURATION = gql`
  mutation(
    $environmentId: ID!
    $configuration: UpdateEcsScheduledTaskConfigurationInput!
  ) {
    updateEcsScheduledTaskConfiguration(
      environmentId: $environmentId
      input: $configuration
    ) {
      id
    }
  }
`;

export const GET_ENV_ECS_CONFIG = gql`
  query($id: ID!) {
    ecsConfiguration(id: $id, idType: ENVIRONMENT) {
      id
    }
  }
`;

export const GET_ECS_CONFIGURATION = gql`
  query($id: ID!, $idType: EcsConfigurationIdType!) {
    ecsConfiguration(id: $id, idType: $idType) {
      id
      image
      service

      environment {
        id
        name

        project {
          id
          name
        }
      }

      cluster {
        id
        name
        region

        profile {
          id
          name
        }
      }
    }
  }
`;

export const GET_ENV_FOR_EDIT = gql`
  query($id: ID!) {
    environment(id: $id) {
      id
      name
      url
      privileged
      autoPromote
      tagPattern
      project {
        id
        name
      }
      providerConfiguration {
        __typename

        ... on BasicLambdaConfiguration {
          id
          artifactBucket
          functionName
          region
          awsProfile {
            id
          }
        }

        ... on EcsConfiguration {
          id
          image
          service
          cluster {
            id
            name
            awsProfile {
              id
              name
            }
          }
        }

        ... on S3Configuration {
          artifactBucket
          awsProfile {
            name
            id
          }
          deployBucket
          id
          region
          deleteOldFiles
          distributionId
        }

        ... on EcsScheduledTaskConfiguration {
          awsProfile {
            name
            id
          }
          id
          image
          region
          taskFamily
        }

        ... on CodebuildConfiguration {
          awsProfile {
            name
            id
          }
          id
          projectName
          region
          timeout
          specifySourceVersion
        }
      }

      slackAlerts {
        channel
        on
        id
      }

      webHooks {
        url
        on
        id
        
        awsProfile {
          id
        }
      }

      slackReminders {
        id
        channel
      }
    }
  }
`;

export const GET_ENV_LIST = gql`
  query {
    environments {
      id
      name
      project {
        id
        name
      }
    }
  }
`;

export const CREATE_ENV = gql`
  mutation($environment: CreateEnvironmentInput!) {
    createEnvironment(input: $environment) {
      id
    }
  }
`;

export const CREATE_DEPLOYMENT = gql`
  mutation($deployment: CreateDeploymentInput!) {
    createDeployment(input: $deployment) {
      id
    }
  }
`;

export const UPDATE_ENV = gql`
  mutation($id: ID!, $environment: UpdateEnvironmentInput!) {
    updateEnvironment(id: $id, input: $environment) {
      id
    }
  }
`;

export const UPDATE_ENV_POSITION = gql`
  mutation($id: ID!, $input: UpdateEnvironmentPositionInput!) {
    updateEnvironmentPosition(id: $id, input: $input) {
      id
    }
  }
`;

export const CREATE_SLACK_ALERT = gql`
  mutation($slackAlert: CreateSlackAlertInput!) {
    createSlackAlert(input: $slackAlert) {
      id
      on
      channel
    }
  }
`;

export const UPDATE_SLACK_ALERT = gql`
  mutation($id: ID!, $slackAlert: UpdateSlackAlertInput!) {
    updateSlackAlert(id: $id, input: $slackAlert) {
      id
      on
      channel
    }
  }
`;

export const DELETE_SLACK_ALERT = gql`
  mutation($id: ID!) {
    deleteSlackAlert(id: $id)
  }
`;

export const CREATE_WEB_HOOK = gql`
  mutation($webHook: CreateWebHookInput!) {
    createWebHook(input: $webHook) {
      signingKey
      webHook {
        url
        on
        id
      }
    }
  }
`;

export const UPDATE_WEB_HOOK = gql`
  mutation($id: ID!, $webHook: UpdateWebHookInput!) {
    updateWebHook(id: $id, input: $webHook) {
      webHook {
        url
        on
        id
      }
    }
  }
`;

export const DELETE_WEB_HOOK = gql`
  mutation($id: ID!) {
    deleteWebHook(id: $id) {
      webHook {
        id
      }
    }
  }
`;

export const LATEST_DEPLOYMENT = gql`
  query($environmentId: ID!) {
    environment(id: $environmentId) {
      id
      name
      lastDeployment {
        id
        deployer {
          id
          displayName
          username
        }
        status
        ref
        insertedAt
      }
    }
  }
`;

export const NEW_DEPLOYMENT_SUBSCRIPTION = gql`
  subscription($environmentId: ID!) {
    newDeployments(environmentId: $environmentId) {
      id
      environment {
        id
        name
      }
      deployer {
        id
        displayName
        username
      }
      artifactName
      autoPromoted
      status
      ref
      insertedAt
    }
  }
`;

export const DEPLOYMENT_UPDATE_SUBSCRIPTION = gql`
  subscription($id: ID!) {
    updateDeployment(id: $id) {
      status
      id
      environment {
        id
        name
      }
    }
  }
`;

export const NEW_DEPLOYMENT_LOG_SUBSCRIPTION = gql`
  subscription($deploymentId: ID!) {
    newDeploymentLogEntry(deploymentId: $deploymentId) {
      timestamp
      level
      message
    }
  }
`;

export const ARCHIVE_ENVIRONMENT = gql`
  mutation($id: ID!) {
    archiveEnvironment(id: $id) {
      id
    }
  }
`;

export const DELETE_ENVIRONMENT = gql`
  mutation($id: ID!) {
    deleteEnvironment(id: $id)
  }
`;

export const DELETE_PROVIDER_CONFIG = gql`
  mutation($environmentId: ID!) {
    deleteProviderConfiguration(environmentId: $environmentId)
  }
`;

export const GET_ENV_SERVICE_DETAILS = gql`
  query($id: ID!) {
    environment(id: $id) {
      id
      providerConfiguration {
        __typename

        ... on EcsConfiguration {
          id
          serviceDetails {
            running
          }
          taskDefinitionDetails {
            environment {
              key
              value
              sensitive
            }
          }
        }
      }
    }
  }
`;
