import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '../../modules/ui-kit-react/lib';
import style from './style.module.css';
import CreateProjectGroup from '../ProjectGroups/CreateGroup';

class CreateProjectButton extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired
  };

  state = {
    menuActive: false,
    showForm: false
  };

  render() {
    const { history } = this.props;
    const { menuActive, showForm } = this.state;

    return (
      <div className={style.wrap}>
        <Button
          appearance="primary"
          raised
          icon="add"
          onClick={() => this.setState({ menuActive: !menuActive })}
        >
          Create
        </Button>
        <Menu
          active={menuActive}
          position="topRight"
          className={style.menu}
          onHide={() => this.setState({ menuActive: false })}
          theme={{
            outline: style.outline
          }}
        >
          <MenuItem
            className={style.menuItem}
            icon="insert_drive_file"
            caption="Project"
            onClick={() => history.push('./projects/new')}
          />
          <MenuItem
            className={style.menuItem}
            icon="folder"
            caption="Group"
            onClick={() => this.setState({ showForm: true })}
          />
        </Menu>
        <CreateProjectGroup
          active={showForm}
          onClose={() => this.setState({ showForm: false })}
        />
      </div>
    );
  }
}

export default withRouter(CreateProjectButton);
