import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import EnvironmentsView from 'pages/Environments/View';
import EnvironmentEdit from 'pages/Environments/Edit';
import withAuth from 'components/Auth';
import PropTypes from 'prop-types';

class Environments extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string
    }).isRequired
  };

  render() {
    const {
      match: { path }
    } = this.props;

    return (
      <Switch>
        <Route exact path={`${path}/:id`} component={EnvironmentsView} />
        <Route path={`${path}/:id/edit`} component={EnvironmentEdit} />
      </Switch>
    );
  }
}

export default withAuth(Environments);
