"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = require("lodash");

var transitionMap = {
  transition: 'transitionend',
  OTransition: 'oTransitionEnd',
  MozTransition: 'transitionend',
  WebkitTransition: 'webkitTransitionEnd'
};

function transitionEventNamesFor(element) {
  return (0, _lodash.keys)(transitionMap).reduce(function (result, transition) {
    return !result && element && (0, _lodash.has)(element.style, 'transition') ? transitionMap[transition] : result;
  }, '');
}

function getMousePosition(event) {
  return {
    x: event.pageX - (window.scrollX || window.pageXOffset),
    y: event.pageY - (window.scrollY || window.pageYOffset)
  };
}

function getTouchPosition(event) {
  return {
    x: event.touches[0].pageX - (window.scrollX || window.pageXOffset),
    y: event.touches[0].pageY - (window.scrollY || window.pageYOffset)
  };
}

function pauseEvent(event) {
  event.stopPropagation();
  event.preventDefault();
}

function addEventsToDocument(eventMap) {
  var capture = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  Object.keys(eventMap).forEach(function (key) {
    document.addEventListener(key, eventMap[key], capture);
  });
}

function removeEventsFromDocument(eventMap) {
  var capture = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  Object.keys(eventMap).forEach(function (key) {
    document.removeEventListener(key, eventMap[key], capture);
  });
}

function targetIsDescendant(event, parent) {
  var node = event.target;

  while (node !== null) {
    if (node === parent) return true;
    node = node.parentNode;
  }

  return false;
}

function addEventListenerOnTransitionEnded(element, cb) {
  var eventName = transitionEventNamesFor(element);
  if (!eventName) return false;
  element.addEventListener(eventName, cb);
  return true;
}

function removeEventListenerOnTransitionEnded(element, cb) {
  var eventName = transitionEventNamesFor(element);
  if (!eventName) return false;
  element.removeEventListener(eventName, cb);
  return true;
}

var _default = {
  removeEventListenerOnTransitionEnded: removeEventListenerOnTransitionEnded,
  addEventListenerOnTransitionEnded: addEventListenerOnTransitionEnded,
  targetIsDescendant: targetIsDescendant,
  removeEventsFromDocument: removeEventsFromDocument,
  addEventsToDocument: addEventsToDocument,
  pauseEvent: pauseEvent,
  getTouchPosition: getTouchPosition,
  getMousePosition: getMousePosition,
  transitionEventNamesFor: transitionEventNamesFor
};
exports["default"] = _default;