import React, { FC } from 'react';
import { RouteComponentProps , Link } from 'react-router-dom';
import { get } from 'lodash';
import classnames from 'classnames';
import {
  CardContent,
  CardHead,
  Col,
  Button,
  Row
} from '../../../modules/ui-kit-react/lib';
import Container from 'components/Container';
import Title from 'components/Title';
import ProjectBox from 'components/ProjectBox';
import projectListStyles from 'pages/Projects/List/style.module.css';
import AddProjectToGroupButton from 'components/ProjectGroups/AddProjectToGroupButton';
import AddProjectGroupToGroupButton from 'components/ProjectGroups/AddProjectGroupToGroupButton';
import { makeButton } from 'components/DeleteButton';
import Protected from 'components/Protected';
import {
  DeleteProjectGroupDocument,
  useGetProjectGroupQuery,
  ProjectGroup
} from 'generated/graphql';
import ProjectGroupBox from 'components/ProjectGroups/List/ProjectGroupBox';

import style from './style.module.css';
import { GraphQLError, NotFoundError } from '../../../errors';

const DeleteProjectGroupButton: any = makeButton(DeleteProjectGroupDocument);

interface ParentLinkProps {
  parentGroup: Pick<ProjectGroup, 'id' | 'name'> | null;
}

const ParentLink: FC<ParentLinkProps> = ({ parentGroup }) => {
  if (!parentGroup) {
    return null;
  }

  return (
    <small className={style.parentGroupName}>
      <i className="material-icons">subdirectory_arrow_right</i>
      <Link to={`../project-groups/${parentGroup.id}`}>{parentGroup.name}</Link>
    </small>
  );
};

interface RouteParams {
  id: string;
}

const Component: FC<RouteComponentProps<RouteParams>> = ({
  match,
  history: { push }
}) => {
  const { data, loading, error } = useGetProjectGroupQuery({
    variables: {
      id: match.params.id
    }
  });

  const projectGroup = data && data.projectGroup;
  const parentProjectGroup = get(projectGroup, 'parentProjectGroup') || null;
  const groupProjects = get(projectGroup, 'projects') || [];
  const groupGroups = get(projectGroup, 'projectGroups') || [];

  if (loading) {
    return null;
  }

  if (error) throw new GraphQLError(error);
  if (!projectGroup) throw new NotFoundError();

  return (
    <Container>
      <Title title={`Project Group "${projectGroup.name}"`} />

      <CardHead>
        <div className={projectListStyles.head}>
          <div className={classnames(projectListStyles.title, style.title)}>
            {projectGroup.name}
            <br />
            <ParentLink parentGroup={parentProjectGroup} />
          </div>

          <div className={style.actions}>
            <Button
              appearance="primary"
              icon="edit"
              flat
              onClick={() => push(`./${projectGroup.id}/edit`)}
            >
              Edit Group
            </Button>

            <AddProjectToGroupButton projectGroup={projectGroup} />
            <AddProjectGroupToGroupButton projectGroup={projectGroup} />

            <Protected hasRole="ADMIN">
              <DeleteProjectGroupButton
                variables={{ id: projectGroup.id }}
                onSuccess={() => push('../')}
              />
            </Protected>
          </div>
        </div>
      </CardHead>

      <CardContent>
        <p>{projectGroup.description}</p>

        <Row>
          {groupGroups.map(group => (
            <ProjectGroupBox projectGroup={group} />
          ))}
        </Row>

        <Row>
          {groupProjects.map(project => {
            const key = project && project.id;

            return (
              <Col
                sm={4}
                className={projectListStyles.col}
                key={key || undefined}
              >
                <ProjectBox project={project} />
              </Col>
            );
          })}
        </Row>

        {groupProjects.length === 0 && (
          <div className={projectListStyles.noProjects}>No projects</div>
        )}
      </CardContent>
    </Container>
  );
};

export default Component;
