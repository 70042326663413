import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withAuth from 'components/Auth';
import { LaunchDarkly } from 'react-launch-darkly';
import config from 'config';

class FeatureFlagContext extends PureComponent {
  static propTypes = {
    auth: PropTypes.shape({
      hasRole: PropTypes.func
    }).isRequired,
    children: PropTypes.node.isRequired
  };

  deriveLDData = ctx => {
    if (Object.keys(ctx.profile).length === 0) {
      return {};
    }

    const fullName = ctx.profile.displayName.split(' ');
    const firstName = fullName[0] || '';
    const lastName = fullName[fullName.length - 1] || '';

    return {
      key: ctx.profile.username,
      email: ctx.profile.email,
      firstName,
      lastName,
      custom: {
        username: ctx.profile.username,
        tenant: ctx.tenant,
        roles: ctx.profile.roles,
        slackId: ctx.profile.slackId
      }
    };
  };

  render() {
    const { auth, children } = this.props;

    // TODO: Test to see if we can move this to onMount (does tenant switching work?)
    const user = this.deriveLDData(auth);

    return (
      <LaunchDarkly clientId={config.LAUNCH_DARKLY.CLIENT_ID} user={user}>
        {children}
      </LaunchDarkly>
    );
  }
}

export default withAuth(FeatureFlagContext);
