import React, { FC } from 'react';
import { Col, Paper } from '../../../modules/ui-kit-react/lib';
import { ProjectGroup } from 'generated/graphql';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import style from './style.module.css';

interface RouteParams {
  tenant: string;
}

interface ComponentProps extends RouteComponentProps<RouteParams> {
  projectGroup: Pick<ProjectGroup, 'id' | 'name' | 'projects'>;
}

const Component: FC<ComponentProps> = ({ projectGroup, match }) => (
  <Col key={projectGroup.id} sm={3}>
    <Paper depth={1} className={style.group}>
      <div className={style.name}>
        <i className="material-icons">folder</i>
        <Link to={`/${match.params.tenant}/project-groups/${projectGroup.id}`}>
          {projectGroup.name}
        </Link>
      </div>

      <div className={style.chip}>
        {projectGroup.projects ? projectGroup.projects.length : 0}
      </div>
    </Paper>
  </Col>
);

export default withRouter(Component);
