import React, { FC, useState } from 'react';
import { capitalize } from 'lodash';
import { Button } from '../../modules/ui-kit-react/lib';
import BasicLambdaFields from 'components/Providers/BasicLambdaFields';
import EcsFields from 'components/Providers/EcsFields';
import EcsScheduledTaskFields from 'components/Providers/EcsScheduledTaskFields';
import S3Fields from 'components/Providers/S3Fields';
import { RadioButton } from 'components/Form/RadioGroup';
import ProviderWarning, {
  ProviderTypeShortname
} from 'components/ProviderWarning';
import useProviderWarnings, {
  ProviderType
} from './useDisplayedProviderWarning';
import style from './style.module.css';
import CodeBuildFields from '../Providers/CodeBuildFields';

interface ProviderSelectionProps {
  gqlError: any; // NOTE: We don't really care - just passing it through
}

interface WarningConfirmationProps {
  type: ProviderTypeShortname;
  onClick: () => void;
}

const WarningConfirmation: FC<WarningConfirmationProps> = ({
  type,
  onClick
}: WarningConfirmationProps) => (
  <div>
    <ProviderWarning type={type} />

    <Button appearance="primary" lowered onClick={onClick}>
      Continue with {capitalize(type)}
    </Button>
  </div>
);

interface ProviderChoicesProps extends ProviderSelectionProps {
  provider?: ProviderType;
}

const ProviderChoices: FC<ProviderChoicesProps> = ({
  gqlError,
  provider
}: ProviderChoicesProps) => {
  const [s3WarningDisplayed, setS3WarningDisplayed] = useProviderWarnings(
    'S3Configuration'
  );

  const [
    lambdaWarningDisplayed,
    setLambdaWarningDisplayed
  ] = useProviderWarnings('BasicLambdaConfiguration');

  switch (provider) {
    case 'EcsConfiguration':
      return <EcsFields gqlError={gqlError} />;
    case 'EcsScheduledTaskConfiguration':
      return <EcsScheduledTaskFields gqlError={gqlError} />;
    case 'CodebuildConfiguration':
      return <CodeBuildFields gqlError={gqlError} />;
    case 'S3Configuration':
      return s3WarningDisplayed ? (
        <S3Fields gqlError={gqlError} />
      ) : (
        <WarningConfirmation
          type="s3"
          onClick={() => setS3WarningDisplayed()}
        />
      );
    case 'BasicLambdaConfiguration':
      return lambdaWarningDisplayed ? (
        <BasicLambdaFields gqlError={gqlError} />
      ) : (
        <WarningConfirmation
          type="lambda"
          onClick={() => setLambdaWarningDisplayed()}
        />
      );
    default:
      return <div className={style.prompt}>Select Deployment Type</div>;
  }
};

const Component: FC<ProviderSelectionProps> = (
  props: ProviderSelectionProps
) => {
  const [provider, setProvider] = useState<ProviderType | undefined>();

  return (
    <div className={style.selection}>
      <div className={style.radios}>
        <RadioButton
          label="ECS"
          checked={provider === 'EcsConfiguration'}
          onChange={() => setProvider('EcsConfiguration')}
          value=""
        />
        <RadioButton
          checked={provider === 'EcsScheduledTaskConfiguration'}
          onChange={() => setProvider('EcsScheduledTaskConfiguration')}
          label={<span>ECS Scheduled Task</span>}
          value=""
        />
        <RadioButton
          checked={provider === 'S3Configuration'}
          onChange={() => setProvider('S3Configuration')}
          label={<span>S3</span>}
          value=""
        />
        <RadioButton
          label={<span>Lambda</span>}
          checked={provider === 'BasicLambdaConfiguration'}
          onChange={() => setProvider('BasicLambdaConfiguration')}
          value=""
        />
        <RadioButton
          label={<span>CodeBuild</span>}
          checked={provider === 'CodebuildConfiguration'}
          onChange={() => setProvider('CodebuildConfiguration')}
          value=""
        />
      </div>

      <ProviderChoices provider={provider} {...props} />
    </div>
  );
};

export default Component;
