import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EnvForm from 'components/EnvForm';
import Container from 'components/Container';
import Title from 'components/Title';
import { GET_PROJECT } from 'containers/Projects/queries';
import { GET_ENV_FOR_EDIT, UPDATE_ENV } from 'containers/Environments/queries';
import { Link } from 'react-router-dom';
import { QueryLoading, MutationLoading } from 'components/LoadingPage';
import {
  Breadcrumbs,
  Button,
  Crumb,
  CardHead,
  CardContent,
  Alert
} from '../../../modules/ui-kit-react/lib';
import styles from './style.module.css';
import { NotFoundError } from '../../../errors';

class EnvironmentEdit extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    }).isRequired
  };

  render() {
    const { match, history } = this.props;

    return (
      <QueryLoading
        query={GET_ENV_FOR_EDIT}
        variables={{ id: match.params.id }}
      >
        {({ data }) => {
          const { environment } = data;

          if (!environment) throw new NotFoundError();

          return (
            <Container>
              <Title title={`${environment.project.name} - Edit Environment "${environment.name}"`} />

              <CardHead className={styles.header}>
                <Breadcrumbs>
                  <Crumb>
                    <Link to="../../projects">Projects</Link>
                  </Crumb>

                  <Crumb>
                    <Link to={`../../projects/${environment.project.id}`}>
                      {environment.project.name}
                    </Link>
                  </Crumb>

                  <Crumb>
                    <Link to={`../${environment.id}`}>{environment.name}</Link>
                  </Crumb>

                  <Crumb active>Edit</Crumb>
                </Breadcrumbs>

                <div>
                  <Button
                    appearance="primary"
                    flat
                    icon="arrow_back"
                    onClick={() =>
                      history.push(`../../projects/${environment.project.id}`)
                    }
                  >
                    Back to Project
                  </Button>
                </div>
              </CardHead>

              <CardContent>
                <h1>Edit Environment</h1>

                <MutationLoading
                  mutation={UPDATE_ENV}
                  refetchQueries={[
                    {
                      query: GET_PROJECT,
                      variables: { id: environment.project.id }
                    },
                    {
                      query: GET_ENV_FOR_EDIT,
                      variables: { id: match.params.id }
                    }
                  ]}
                  onCompleted={() =>
                    history.push(`../../projects/${environment.project.id}`)
                  }
                >
                  {(
                    updateEnvironment,
                    { loading: mutationLoading, error: mutationError }
                  ) => (
                    <React.Fragment>
                      {mutationError ? (
                        <Alert type="danger" message={mutationError.message} />
                      ) : null}
                      <EnvForm
                        gqlError={mutationError}
                        edit
                        projectId={environment.project.id}
                        environment={environment}
                        loading={mutationLoading}
                        onSubmit={form =>
                          updateEnvironment({
                            variables: {
                              id: match.params.id,
                              environment: {
                                name: form.name,
                                url: form.url,
                                tagPattern: form.privileged === 'true' || form.privileged === true ? "^v(0|[1-9]+[0-9]*)\.(0|[1-9]+[0-9]*)\.(0|[1-9]+[0-9]*)$" : form.tagPattern || null,
                                privileged: form.privileged === 'true' || form.privileged === true,
                                autoPromote:
                                  form.autoPromote === 'true' ||
                                  form.autoPromote === true
                              }
                            }
                          })
                        }
                      />
                    </React.Fragment>
                  )}
                </MutationLoading>
              </CardContent>
            </Container>
          );
        }}
      </QueryLoading>
    );
  }
}

export default EnvironmentEdit;
