import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from '../../../modules/react-forms/lib/index';
import Button from '../../../modules/ui-kit-react/lib/components/Button/index';
import S3Fields from '../../Providers/S3Fields';
import style from '../style.module.css';

class S3Form extends Component {
  form = React.createRef();

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    s3Configuration: PropTypes.shape({
      id: PropTypes.string
    }),
    gqlError: PropTypes.shape({
      graphQLErrors: PropTypes.array
    })
  };

  static defaultProps = {
    gqlError: null,
    s3Configuration: null
  };

  render() {
    const { s3Configuration, onCancel, onSubmit, gqlError } = this.props;

    return (
      <React.Fragment>
        <div className={style.content}>
          <Form ref={this.form} onSubmit={onSubmit}>
            <S3Fields s3Configuration={s3Configuration} gqlError={gqlError} />
          </Form>
        </div>
        <div className={style.foot}>
          <Button
            raised
            appearance="primary"
            onClick={() => this.form.current.submit()}
          >
            Update
          </Button>
          <Button appearance="danger" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default S3Form;
