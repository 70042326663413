"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.overrideComponentTypeChecker = overrideComponentTypeChecker;
exports["default"] = exports.defaultChecker = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 *  Sets customChecker which will be used for all components.
 *
 * @param providedChecker {Function} - Checker function
 */
function overrideComponentTypeChecker(providedChecker) {
  checkerFunction = providedChecker;
}
/**
 * Returns true if the provided element is a component of the provided type.
 *
 * @param classType {ReactElement class} - the class of a React Element
 * @param reactElement {ReactElement} - any React Element (not a real DOM node)
 */


var defaultChecker = function defaultChecker(classType, reactElement) {
  if (process.env.NODE_ENV !== 'production') {
    // https://github.com/gaearon/react-hot-loader/blob/v3.0.0-beta.7/docs/Known%20Limitations.md#checking-element-types
    classType = _react["default"].createElement(classType).type; // eslint-disable-line no-param-reassign
  }

  return reactElement && reactElement.type === classType;
};

exports.defaultChecker = defaultChecker;
var checkerFunction = defaultChecker;
/**
 * Executes customChecker if it's set or defaultChecker.
 *
 * @param classType {ReactElement class} - the class of a React Element
 * @param reactElement {ReactElement} - any React Element (not a real DOM node)
 */

var isComponentOfType = function isComponentOfType(classType, reactElement) {
  return checkerFunction(classType, reactElement);
}; // export default function isComponentOfType(classType: Component, reactElement: ReactElement): boolean {
//   return customChecker
//     ? customChecker(classType, reactElement)
//     : defaultChecker(classType, reactElement);
// }


var _default = isComponentOfType;
exports["default"] = _default;