import React from 'react';
import { MultiSelect } from '../../modules/ui-kit-react/lib';
import { Props as MenuProps } from 'react-select';
import { useGetProjectGroupsListQuery } from '../../generated/graphql';

type Props = MenuProps;

export default ({ ...rest }: Props) => {
  const { data, loading, error } = useGetProjectGroupsListQuery();

  if (loading) return <span>...</span>;
  if (error || !data || !data.projectGroups)
    return <span>Unable to load project groups.</span>;

  const projectGroups = data.projectGroups.entries;

  return (
    <MultiSelect
      // @ts-ignore
      options={
        projectGroups
          ? projectGroups.map(p => ({
              label: p.name,
              value: p.id
            }))
          : [{ label: 'None', value: 'none' }]
      }
      {...rest}
    />
  );
};
