import React, { FC } from 'react';
import { Button } from '../../modules/ui-kit-react/lib';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Title from 'components/Title';
import styles from './style.module.css';

interface Props extends RouteComponentProps {
  title: String;
  message: String;
  icon: String;
  onBack?: () => void;
}

const ErrorPage: FC<Props> = ({ title, message, icon, onBack, history }) => (
  <div className={styles.wrap}>
    <Title title="Error" />

    <div className={styles.icon}>
      <i className="material-icons">{icon}</i>
    </div>
    <div className={styles.text}>
      <div className={styles.title}>{title}</div>
      <div>
        <pre>{message}</pre>
      </div>
      <div>
        <Button
          appearance="primary"
          onClick={() => {
            history.goBack();
            if (onBack) onBack();
          }}
        >
          Go Back
        </Button>
      </div>
    </div>
  </div>
);

export default withRouter(ErrorPage);
