import React, { Component } from 'react';
import { UPDATE_REFERENCE } from 'containers/Projects/queries';
import Alert from '../../modules/ui-kit-react/lib/components/Alert';
import PropTypes from 'prop-types';
import ReferenceForm from 'components/ReferenceForm';
import { MutationLoading } from 'components/LoadingPage';
import style from './style.module.css';

class ReferenceInlineEdit extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    project: PropTypes.shape({
      name: PropTypes.string
    }),
    reference: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }).isRequired
  };

  static defaultProps = {
    project: {}
  };

  render() {
    const { onClose, reference } = this.props;
    return (
      <div className={style.container}>
        <MutationLoading mutation={UPDATE_REFERENCE} onCompleted={onClose}>
          {(updateReference, { error }) => (
            <React.Fragment>
              {error ? <Alert type="danger" message={error.message} /> : null}
              <div className={style.heading}>Edit Reference</div>
              <ReferenceForm
                edit
                gqlError={error}
                reference={reference}
                onCancel={onClose}
                onSubmit={form =>
                  updateReference({
                    variables: {
                      id: reference.id,
                      reference: {
                        name: form.name,
                        url: form.url
                      }
                    }
                  })
                }
              />
            </React.Fragment>
          )}
        </MutationLoading>
      </div>
    );
  }
}

export default ReferenceInlineEdit;
