"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ThemeProvider = require("../../ThemeProvider");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ListDivider = function ListDivider(_ref) {
  var inset = _ref.inset,
      theme = _ref.theme;
  return _react["default"].createElement("hr", {
    className: inset ? "".concat(theme.divider, " ").concat(theme.inset) : theme.divider
  });
};

ListDivider.propTypes = {
  inset: _propTypes["default"].bool,
  theme: _propTypes["default"].shape({
    divider: _propTypes["default"].string,
    inset: _propTypes["default"].string
  })
};
ListDivider.defaultProps = {
  inset: false
};

var _default = (0, _ThemeProvider.applyTheme)('List', ListDivider);

exports["default"] = _default;