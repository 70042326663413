import React, { Component } from 'react';
import Container from 'components/Container';
import ProjectForm from 'components/ProjectForm';
import Title from 'components/Title';
import { MutationLoading } from 'components/LoadingPage';
import { CREATE_PROJECT } from 'containers/Projects/queries';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Alert,
  Card,
  CardHead,
  CardContent,
  Breadcrumbs,
  Crumb
} from '../../../modules/ui-kit-react/lib';

class ProjectsNew extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired
  };

  render() {
    const { history } = this.props;

    return (
      <Container>
        <Title title="New Project" />

        <CardHead>
          <Breadcrumbs>
            <Crumb>
              <Link to="../projects">Projects</Link>
            </Crumb>

            <Crumb active>New</Crumb>
          </Breadcrumbs>
        </CardHead>

        <CardContent>
          <h1>New Project</h1>
          <Row>
            <Col md={6} xs={12}>
              <Card>
                <CardHead>Project Details</CardHead>

                <CardContent>
                  <MutationLoading
                    mutation={CREATE_PROJECT}
                    onCompleted={data =>
                      history.push(`./${data.createProject.id}`)
                    }
                  >
                    {(createProject, { loading, error }) => (
                      <React.Fragment>
                        {error ? (
                          <Alert type="danger" message={error.message} />
                        ) : null}
                        <ProjectForm
                          gqlError={error}
                          loading={loading}
                          onSubmit={form =>
                            createProject({
                              variables: {
                                project: {
                                  name: form.name,
                                  description: form.description,
                                  repository: form.repository
                                }
                              }
                            })
                          }
                        />
                      </React.Fragment>
                    )}
                  </MutationLoading>
                </CardContent>
              </Card>
            </Col>
          </Row>
        </CardContent>
      </Container>
    );
  }
}

export default ProjectsNew;
