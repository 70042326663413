import React, { Component } from 'react';
import { Form, Validators } from '../../modules/react-forms/lib';
import Button from '../../modules/ui-kit-react/lib/components/Button';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextInput from 'components/Form/TextInput';
import extractFieldError from 'utils/gqlErrors';
import styles from './style.module.css';

class ProjectForm extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    project: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      repository: PropTypes.string
    }),
    edit: PropTypes.bool,
    gqlError: PropTypes.shape({
      graphQLErrors: PropTypes.array
    })
  };

  static defaultProps = {
    project: {},
    edit: false,
    loading: false,
    gqlError: null
  };

  cancel = () => {
    const { edit, history, project } = this.props;

    if (edit) {
      history.push(`../../projects/${project.id}`);
    } else {
      history.push('../projects');
    }
  };

  getError = field => {
    const { gqlError } = this.props;
    return extractFieldError(gqlError, field);
  };

  render() {
    const { onSubmit, loading, project, edit } = this.props;

    return (
      <Form onValidSubmit={onSubmit}>
        <TextInput
          name="name"
          label="Name"
          placeholder="Shield"
          required
          defaultValue={project.name}
          validation={{
            'Name must be less than 64 characters': Validators.isLength({
              min: 1,
              max: 64
            })
          }}
          error={this.getError('name')}
        />
        <TextInput
          name="repository"
          label="Repository"
          placeholder="https://github.com/CreditCardsCom/shield"
          required
          defaultValue={project.repository}
          validation={{
            'Must provide protocol: http or https': Validators.isURL({
              require_protocol: true,
              protocols: ['http', 'https']
            })
          }}
          error={this.getError('repository')}
        />
        <TextInput
          name="description"
          label="Description"
          placeholder="The API that runs https://shield.creditcards.com"
          multiline
          rows={5}
          required
          defaultValue={project.description}
          error={this.getError('description')}
        />

        <div className={styles.actions}>
          <Button disabled={loading} raised appearance="primary" type="submit">
            {edit ? 'Update' : 'Create'}
          </Button>
          {!edit ? (
            <Button
              disabled={loading}
              lowered
              appearance="danger"
              onClick={this.cancel}
            >
              Cancel
            </Button>
          ) : null}
        </div>
      </Form>
    );
  }
}

export default withRouter(ProjectForm);
