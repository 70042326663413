import React, { Component } from 'react';
import { Pagination as UIKitPagination } from '../../modules/ui-kit-react/lib';
import style from './style.module.css';

class Pagination extends Component {
  render() {
    return (
      <div className={style.pagination}>
        <UIKitPagination {...this.props} />
      </div>
    );
  }
}

export default Pagination;
