import React, { Component } from 'react';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';
import { NetworkStatus } from 'apollo-client';
import { GET_PROJECT_GROUPS } from 'containers/Projects/queries';
import { Row, Alert, Button } from '../../../modules/ui-kit-react/lib';
import style from './style.module.css';
import ProjectGroupBox from './ProjectGroupBox';

class ProjectGroups extends Component {
  static propTypes = {
    data: PropTypes.shape({
      error: PropTypes.shape({
        message: PropTypes.string
      }),
      fetchMore: PropTypes.func,
      loading: PropTypes.bool,
      networkStatus: PropTypes.number,
      projectGroups: PropTypes.shape({
        entries: PropTypes.arrayOf(PropTypes.shape({})),
        pagination: PropTypes.shape({
          pageNumber: PropTypes.number,
          totalPages: PropTypes.number
        })
      })
    }).isRequired
  };

  updateQuery = (prev, { fetchMoreResult }) => {
    if (!fetchMoreResult) return prev;

    return {
      projectGroups: {
        ...fetchMoreResult.projectGroups,
        entries: [
          ...prev.projectGroups.entries,
          ...fetchMoreResult.projectGroups.entries
        ]
      }
    };
  };

  render() {
    const { data } = this.props;

    if (data.loading && data.networkStatus !== NetworkStatus.fetchMore) {
      return null;
    }
    if (data.error) return <Alert type="danger" message={data.error.message} />;

    const {
      projectGroups: { entries, pagination },
      fetchMore
    } = data;

    if (entries.length === 0) return null;

    return (
      <React.Fragment>
        <div className={style.heading}>Project Groups</div>
        <Row>
          {entries.map(g => (
            <ProjectGroupBox projectGroup={g} />
          ))}
        </Row>
        {pagination.pageNumber < pagination.totalPages && (
          <div className={style.loadMore}>
            <Button
              lowered
              appearance="primary"
              onClick={() =>
                fetchMore({
                  variables: {
                    page: pagination.pageNumber + 1,
                    pageSize: 8
                  },
                  updateQuery: this.updateQuery
                })
              }
            >
              Load More Groups
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default graphql(GET_PROJECT_GROUPS, {
  options: { variables: { pageSize: 8 } }
})(ProjectGroups);
