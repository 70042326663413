"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Returns proper color for given credit score
 * @param score
 * @returns {String} HTML Color
 */
var _default = function _default(score) {
  switch (true) {
    case score > 780:
      return '#17AC92';

    case score > 719:
      return '#6FC66D';

    case score > 657:
      return '#ccca31';

    case score > 600:
      return '#E5CC22';

    case score > 557:
      return '#F8AF17';

    case score > 499:
      return '#F4812F';

    default:
      return '#EC5745';
  }
};

exports["default"] = _default;