/* eslint-disable */
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ThemeProvider = require("../../ThemeProvider");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var MenuDivider = function MenuDivider(_ref) {
  var theme = _ref.theme;
  return _react["default"].createElement("li", {
    "data-react-toolbox": "menu-divider-wrapper",
    style: { listStyleType: 'none', padding: 0 },
    className: theme.menuDivider
  },
    _react["default"].createElement("hr", {
      "data-react-toolbox": "menu-divider",
      className: theme.menuDivider
    })
  );
};

MenuDivider.propTypes = {
  theme: _propTypes["default"].shape({
    menuDivider: _propTypes["default"].string
  })
};

var _default = (0, _ThemeProvider.applyTheme)('Menu', MenuDivider);

exports["default"] = _default;