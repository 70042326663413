import { createContext, useContext } from 'react';

export const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  login: () => {},
  logout: () => {},
  getToken: () => null,
  token: null
});

export const useAuth = () => useContext(AuthContext);
