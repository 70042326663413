/*  eslint-disable react/prop-types, class-methods-use-this, max-classes-per-file */
import {
  DefaultLinkFactory,
  DefaultLinkModel,
  DefaultPortModel,
  PortModelAlignment
} from '@projectstorm/react-diagrams';
import * as React from 'react';

export class AdvancedLinkModel extends DefaultLinkModel {
  constructor(options) {
    super({
      type: 'advanced',
      width: 8,
      ...options
    });
  }
}

export class AdvancedLinkSegment extends React.Component {
  constructor(props) {
    super(props);
    this.percent = 0;
  }

  componentDidMount() {
    const { model } = this.props;
    const { active } = model.targetPort.getOptions();

    if (!active) return;

    this.mounted = true;
    this.callback = () => {
      if (!this.circle || !this.path) {
        return;
      }

      this.percent += 2;
      if (this.percent > 100) {
        this.percent = 0;
      }

      const point = this.path.getPointAtLength(
        this.path.getTotalLength() * (this.percent / 100.0)
      );

      this.circle.setAttribute('cx', `${point.x}`);
      this.circle.setAttribute('cy', `${point.y}`);

      if (this.mounted) {
        requestAnimationFrame(this.callback);
      }
    };
    requestAnimationFrame(this.callback);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { model, path } = this.props;
    const { width } = model.getOptions();
    const { active } = model.targetPort.getOptions();

    return (
      <>
        <path
          fill="none"
          ref={ref => {
            this.path = ref;
          }}
          strokeWidth={active ? width : width / 3}
          stroke={active ? 'rgba(18,111,204, 0.5)' : 'rgba(226,226,226,0.5)'}
          d={path}
        />
        {active && (
          <circle
            ref={ref => {
              this.circle = ref;
            }}
            r={width / 2}
            fill="#007AB8"
          />
        )}
      </>
    );
  }
}

export class AdvancedPortModel extends DefaultPortModel {
  constructor(options) {
    super({
      alignment: PortModelAlignment.TOP,
      ...options
    });
  }

  createLinkModel = () => new AdvancedLinkModel();
}

export class AdvancedLinkFactory extends DefaultLinkFactory {
  constructor() {
    super('advanced');
  }

  generateModel() {
    return new AdvancedLinkModel();
  }

  generateLinkSegment(model, selected, path) {
    return (
      <g>
        <AdvancedLinkSegment model={model} path={path} />
      </g>
    );
  }
}
