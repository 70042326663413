import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Header from "components/Header";
import AuthProvider from "components/Auth/AuthProvider";
import ErrorBoundry from "components/ErrorBoundry";
import ApolloClient from "components/ApolloClient";
import FeatureFlagContext from "components/FeatureFlagContext";
import ContextComposer from "components/Auth/ContextComposer";
import Tenant, { Redirector as TenantRedirector } from "../Tenant";
import Footer from "../../components/Footer";
import Shortcuts from "../../components/Shortcuts";
import { NotFoundError } from "../../errors";
import { ContextProvider } from "../../context";

const GraphiQL = React.lazy(() => import("components/GraphiQL"));
const ReleaseNotes = React.lazy(() => import("../ReleaseNotes"));
const SlackAuth = React.lazy(() => import("components/SlackAuth"));
const SuperAdmin = React.lazy(() => import("containers/SuperAdmin"));
const TenantSelector = React.lazy(() => import("components/TenantSelector"));

export default function App() {
  return (
    <ContextProvider>
      <AuthProvider>
        <ApolloClient>
          <ContextComposer>
          <FeatureFlagContext>
            <ErrorBoundry>
              <Shortcuts />
              <Header />
              <Suspense fallback={<div />}>
                <Switch>
                  {/* This enforces no trailing slashes on all paths. We do this so relative <Links /> and pushes work */}
                  <Route
                    path="/*/"
                    exact
                    strict
                    render={(props) => (
                      <Redirect
                        to={`${props.location.pathname.replace(/\/$/, "")}`}
                      />
                    )}
                  />
                  <Route path="/" exact component={TenantRedirector} />
                  <Route path="/gql" component={GraphiQL} />
                  <Route path="/authorize-slack/:token" component={SlackAuth} />
                  <Route path="/release-notes" component={ReleaseNotes} />
                  <Route path="/select-tenant" component={TenantSelector} />
                  <Redirect exact from="/superadmin" to="/super-admin" />
                  <Route path="/super-admin" component={SuperAdmin} />
                  <Route path="/:tenant">
                    <Tenant />
                  </Route>
                  <Route
                    render={() => {
                      throw new NotFoundError();
                    }}
                  />
                </Switch>
              </Suspense>
              <Footer />
            </ErrorBoundry>
          </FeatureFlagContext>
          </ContextComposer>
        </ApolloClient>
      </AuthProvider>
    </ContextProvider>
  );
}
