import React, { Component } from 'react';
import ProjectBadges from 'components/ProjectBadges';
import PropTypes from 'prop-types';
import withAuth from 'components/Auth';
import { CardHead, CardContent } from '../../modules/ui-kit-react/lib';
import styles from './style.module.css';
import ProjectActions from '../ProjectActions';
import ProjectLinks from '../ProjectLinks';
import AccessNotice from '../AccessNotice';
import getUserRole from '../../utils/getUserRole';

class ProjectDetails extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      profile: PropTypes.shape({
        roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
      }).isRequired
    }).isRequired,
    project: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string.isRequired
    })
  };

  static defaultProps = {
    project: {}
  };

  render() {
    const { auth, project } = this.props;
    const { name, description, slug } = project;

    const canEditBadges = getUserRole(auth.profile.roles) !== 'read-only';

    return (
      <React.Fragment>
        <CardHead className={styles.bar}>
          <div className={styles.name}>
            <h2>{name}</h2>
            <small data-cy="project-slug">{slug}</small>
          </div>

          <ProjectLinks project={project} />
          <ProjectActions project={project} />
        </CardHead>
        <AccessNotice />

        <CardContent>
          <ProjectBadges project={project} showEdit={canEditBadges} />
          <div className={styles.description}>{description}</div>
        </CardContent>
      </React.Fragment>
    );
  }
}

export default withAuth(ProjectDetails);
